import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../database/firebase";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./client-step-5meeting.css";
import { useAppState } from "../../utils/stateContext";
import { createUserWithEmailAndPassword } from "firebase/auth";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import ClientProtectedSteps from "../../utils/ClientProtectedSteps";

const ClientStep5meeting = (props) => {
  const navigate = useNavigate();
  const { appState, setAppState } = useAppState();

  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: (text) => {
      console.log("onDateAndTimeSelected =====>", text);
    },
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) => {
      navigate("/matched-talents", { state: e.data.payload.invitee.uri });
      // createUserWithEmailAndPassword(
      // auth,
      // appState.client.email,
      // appState.client.password
      // )
      //   .then(async (userCredential) => {
      // Signed in
      // const uid = userCredential.user.uid;
      // ...
      // const clientData = appState.client;
      // delete clientData.password;
      // delete clientData.confirmPassword;
      // await setDoc(doc(db, "users", uid), {
      //   ...clientData,
      //   userUID: uid,
      //   eventURI: e.data.payload.event.uri,
      //   inviteeURI: e.data.payload.invitee.uri,
      //   createdAt: Date.now(),
      // });

      // })
      // .catch((error) => {
      //   const errorCode = error.code;
      //   const errorMessage = error.message;
      //   // ..
      // });

      // console.log('response event', e)
    },
  });
  return (
    <div className="client-step5meeting-container">
      <Helmet>
        <title>Book a Meeting Decentraloco</title>
        <meta property="og:title" content="Book a Meeting Decentraloco" />
      </Helmet>
      <NavbarLogIn rootClassName="navbar-log-in-root-class-name11"></NavbarLogIn>
      <div className="client-step5meeting-container1">
        <div className="client-step5meeting-container2">
          <span className="client-step5meeting-text">Step 2 of 2</span>
          <div className="client-step5meeting-container3">
            <div className="client-step5meeting-container4"></div>
          </div>
        </div>
        <div className="client-step5meeting-container5">
          <span className="client-step5meeting-text1">Book a meeting</span>
          <span className="client-step5meeting-text2">
            Choose a date and time to meet with the DecentraLoco team.
          </span>
        </div>
      </div>
      <div style={{ height: "650px", width: "100%" }}>
        <InlineWidget url="https://calendly.com/decentraloco/30min" />
      </div>
      <div className="client-step5meeting-container6">
        <Link
          to="/client-step-4"
          disabled
          className="client-step5meeting-navlink button"
        >
          Back
        </Link>
      </div>
      <FooterV2 rootClassName="footer-v2-root-class-name10"></FooterV2>
    </div>
  );
};

export default ClientStep5meeting;
