import { collection, getDocs, doc, setDoc, getFirestore, getDoc } from "firebase/firestore";
import React, { Fragment, useRef, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { db } from "../../../database/firebase";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// import loadable from "@loadable/component";
// import pMinDelay from "p-min-delay";
import { useUserState } from "../../../utils/stateContext";
import { Row, Card, Col, Button, Modal, Container } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";

import "../../css/add-new-job.css";

const DashboardJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [companyLogo, setCompanyLogo] = useState('');
  const [addNewJobPopup, setAddNewJobPopup] = useState(false);
  const [newJob, setNewJob] = useState({});
  const { user, setUser } = useUserState();
  const storage = getStorage();
  const [keyForm, setKeyForm] = useState(0);
  const [inEdit, setInEdit] = useState(null);
  const [isLoading, setIsLoading] = useState();
  const editorFullDescription = useRef();

  const [currentJob, setCurrentJob] = useState(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({ defaultValues: {} });

  useEffect(() => {
    const controller = new AbortController();
    async function fetchJobs() {
      const querySnapshot = await getDocs(collection(db, "jobs"));
      const jobsData = querySnapshot.docs.map((doc) => { return {...doc.data(), id: doc.id }});
      setJobs(jobsData);
    }
    fetchJobs();
    return () => {
      controller.abort()
    }
  }, [addNewJobPopup]);

  const uploadSaveJob = async (file, jobRef, data) => {
    const companyLogoRef = ref(
      storage, `${jobRef?.id}/${'company_logo'}.${file.type.split("/")[1]}`
    );
    await uploadBytes(companyLogoRef, file)
      .then((snapshot) => {
        return getDownloadURL(snapshot.ref);
      })
      .then(async (url) => {
        // console.log("url updated", url);
        await setDoc(jobRef, {
          ...data,
          companyLogo: url
        });
        setIsLoading(false);
        setCompanyLogo('');
      });
  };

  const createNewJobOrUpdate = async (data) => {
    let refJob;
    try {
      setIsLoading(true);
      if (inEdit) {
        refJob = doc(db, "jobs", data?.id);
        if (! 'createdAt' in data) {
          data = {...data, createdAt: Date.now()}
        }
        data = { ...data, fullDescription: currentJob.fullDescription, updatedAt: Date.now() }
      } else {
        const jobsCollec = collection(db, "jobs");
        refJob = doc(jobsCollec);
        data = { ...data, fullDescription: newJob.fullDescription, createdAt: Date.now() }
      }
      if (companyLogo && companyLogo?.type) {
        await uploadSaveJob(companyLogo, refJob, data);
      } else {
        await setDoc(refJob, {...data}); 
        setIsLoading(false);
      }
      reset({});
    } catch (error) {
      console.error("Error signing up:", error);
    }
    setAddNewJobPopup(false);
    setKeyForm(keyForm+1);
  };

  const editJob = (job) => {
    // console.log('editing job', job);
    setInEdit(true);
    setCurrentJob(job);
    reset(job);
    setAddNewJobPopup(true);
  }

  const openNewJobPopup = () => {
    setInEdit(false);
    setCurrentJob({});
    setNewJob({});
    setCompanyLogo('');
    reset({});
    setAddNewJobPopup(true);
  }

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="d-sm-flex align-items-center mb-sm-3 mt-sm-2 mt-2  mb-2">
          <h4 className="fs-20 text-white mr-auto mb-sm-0 mb-2">
            Jobs
          </h4>
          <button
            onClick={ () => openNewJobPopup() }
            className="btn btn-outline-primary mb-sm-0 mb-1 text-white"
            style={{ backgroundColor: "#6741FF", borderColor: "#6741FF",borderRadius: 8+'px', minWidth: 152+'px', lineHeight: 18+'px', height: 48+'px' }}
          >
            Add New Job
          </button>
          <Modal className="blur fade" show={addNewJobPopup} size={'lg'} key={keyForm} >
            <Modal.Header>
              <Modal.Title className="text-white">Add New Job</Modal.Title>
              <Button
                  variant=""
                  className="close"
                  onClick={() => setAddNewJobPopup(false)}
              >
                  <span>&times;</span>
              </Button>
            </Modal.Header>
            <Modal.Body>
              <div className="">

                <div className="col-12 col-xs-12 mb-4">
                  <div className="label">Company Logo</div>
                  <input type="hidden" value={companyLogo} { ...register("companyLogo") } ></input>
                  <input value={newJob.companyLogo} className="form-control" type={'file'}
                    onChange={(ev) => setCompanyLogo(ev.target.files?.[0])}
                  />
                </div>

                <div className="d-flex">
                  <div className="col-6 col-xs-12 mb-4">
                    <div className="label">Company Name</div>
                    <input className="form-control" type={'text'} 
                      defaultValue={newJob.companyName}
                      {...register("companyName", {
                        required: true,
                        maxLength: 50,
                        minLength: 3,
                      })}
                    />

                    { errors.companyName  && (<p className="error-text">*Required, must have 3-50 chars</p>) }

                  </div>

                  <div className="col-6 col-xs-12 mb-4">
                    <div className="label">Job Category</div>
                    <select defaultValue={newJob.category} className="form-control" type={'text'}
                      {...register("category", { required: true })} 
                    >
                      <option value="blockchain">Blockchain</option>
                      <option value="gaming">Gaming</option>
                      <option value="ai-ml">AI/ML</option>
                      <option value="design">Design</option>
                      <option value="c-level">C-level</option>
                      <option value="other">Other Jobs</option>
                    </select>
                    
                    { errors.category  && (<p className="error-text">*Required</p>) }

                  </div>
                </div>
                <div className="col-12 col-xs-12 mb-4">
                  <div className="label">Job Title</div>
                  <input className="form-control" type={'text'} defaultValue={newJob.jobTitle} 
                    {...register("jobTitle", {
                      required: true,
                      maxLength: 25,
                      minLength: 3,
                    })}
                  />
                  { errors.jobTitle  && (<p className="error-text">*Required, must have 3-25 chars</p>) }

                </div>
                <div className="d-flex mb-4">
                  <div className="col-6 col-xs-12">
                    <div className="label">Workplace Type</div>
                    <input className="form-control" type={'text'} defaultValue={ newJob.workplaceType } 
                      {...register("workplaceType", { required: true })}
                    />

                    { errors.workspaceType  && (<p className="error-text">*Required</p>) }
                  </div>

                  <div className="col-6 col-xs-12">
                    <div className="label">Job Type</div>
                    <input className="form-control" type={'text'} defaultValue={ newJob.jobType } 
                      {...register("jobType", {
                        required: true,
                        maxLength: 25,
                        minLength: 3,
                      })}
                    />
                    { errors.jobType  && (<p className="error-text">*Required, must have 3-25 chars</p>) }
                  </div>
                </div>

                <div className="col-12 col-xs-12 mb-4">
                  <div className="label">Employee Location</div>
                  <input className="form-control" type={'text'} defaultValue={ newJob.employeLocation } 
                    {...register("employeLocation", { required: true })}
                  />
                  { errors.employeLocation  && (<p className="error-text">*Required</p>) }
                </div>

                <div className="col-12 col-xs-12 mb-4">
                  <div className="label">Salary Range</div>
                  <input className="form-control" type={'text'} defaultValue={ newJob.salaryRange } 
                    {...register("salaryRange", { required: true })}
                  />
                  { errors.salaryRange  && (<p className="error-text">*Required</p>) }
                </div>
                <div className="col-12 col-xs-12 mb-4">
                  <div className="label">Short Job Description</div>
                  <textarea rows={4} className="form-control" type={'text'} defaultValue={ newJob.shortDescription } 
                    {...register("shortDescription", {
                      required: true,
                      maxLength: 350,
                      minLength: 10,
                    })}
                  />
                  { errors.shortDescription  && (<p className="error-text">*Required, must have 10-250 chars</p>) }
                </div>
                <div className="col-12 col-xs-12 mb-4">
                  <div className="label">Full Job Description</div>
                  {/* <textarea rows={6} className="form-control" type={'text'} defaultValue={ newJob.fullDescription } 
                    {...register("fullDescription", { required: true})}
                  /> */}
                  {
                    <ReactQuill 
                      ref={editorFullDescription} theme="snow" 
                      value={ currentJob?.fullDescription || newJob.fullDescription || ''} 
                      onChange={ (content) => {
                        if (inEdit) {
                          setCurrentJob({...currentJob, fullDescription: content });
                        } else {
                          setNewJob({...newJob, fullDescription: content});
                        }
                      }}
                    />
                  }
                  { errors.fullDescription  && (<p className="error-text">*Required</p>) }
                </div>
              </div>


            </Modal.Body>
            <Modal.Footer>
              <Button
                  variant="danger light"
                  onClick={() => setAddNewJobPopup(false)}
              >
                  Close
              </Button>
              <Button variant="primary" disabled={isLoading}
                onClick={handleSubmit(createNewJobOrUpdate)}
              >{ isLoading ? 'Loading ...' : inEdit ? `Update Job` : `Save Job`}</Button>
            </Modal.Footer>
        </Modal>
        </div>
        { jobs && jobs.map((job, i) => (
            <div
              className="d-flex flex-wrap search-row py-3 mb-3 rounded justify-content-between align-items-center hover-cursor-pointer"
              style={{ backgroundColor: "#1F1D26" }} key={i}
              onClick={ () => editJob(job) }
            >
              <div className="d-flex col-xl-4 col-xxl-3 col-lg-4 col-sm-6 align-items-center">
                <img src={ job?.companyLogo } style={{width: '75px', marginRight: '10px', borderRadius: '8px', overflow: 'hidden'}} />

                <div>
                  <h4 className="title text-white">
                    {`${job.companyName}`}
                  </h4>
                  <span className="text-white">
                    {job?.jobTitle}
                  </span>
                </div>
              </div>
              <div className="d-flex col-xl-3 col-lg-4 col-sm-6 align-items-center">
                <svg
                  className="mr-3 ml-lg-0 ml-sm-auto ml-0 mt-sm-0 mt-3"
                  width={54}
                  height={54}
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width={54} height={54} rx={15} fill="#2BC155" />
                  <g clipPath="url(#clip6)">
                    <path
                      d="M27.0001 19.84C23.5987 19.84 20.6536 22.2024 19.9157 25.5229L17.4992 36.397C17.4815 36.4768 17.4725 36.5583 17.4725 36.64C17.4725 37.2585 17.974 37.76 18.5925 37.76H35.4077C35.4894 37.76 35.5709 37.751 35.6507 37.7333C36.2545 37.5991 36.6352 37.0008 36.501 36.397L34.0846 25.5229C33.3467 22.2024 30.4016 19.84 27.0001 19.84ZM27.0001 17.6C31.4515 17.6 35.3056 20.6916 36.2712 25.037L38.6877 35.9111C39.0902 37.7226 37.9481 39.5174 36.1366 39.92C35.8973 39.9731 35.6529 40 35.4077 40H18.5925C16.7369 40 15.2325 38.4956 15.2325 36.64C15.2325 36.3948 15.2594 36.1504 15.3126 35.9111L17.729 25.037C18.6947 20.6916 22.5488 17.6 27.0001 17.6Z"
                      fill="white"
                    />
                    <path
                      d="M29.2402 24.32C29.8588 24.32 30.3602 24.8214 30.3602 25.44C30.3602 26.0585 29.8588 26.56 29.2402 26.56H26.4402C26.1309 26.56 25.8802 26.8107 25.8802 27.12C25.8802 27.4292 26.1309 27.68 26.4402 27.68H27.5602C29.1066 27.68 30.3602 28.9336 30.3602 30.48C30.3602 32.0264 29.1066 33.28 27.5602 33.28H24.7602C24.1416 33.28 23.6402 32.7785 23.6402 32.16C23.6402 31.5414 24.1416 31.04 24.7602 31.04H27.5602C27.8695 31.04 28.1202 30.7892 28.1202 30.48C28.1202 30.1707 27.8695 29.92 27.5602 29.92H26.4402C24.8938 29.92 23.6402 28.6664 23.6402 27.12C23.6402 25.5736 24.8938 24.32 26.4402 24.32H29.2402Z"
                      fill="white"
                    />
                    <path
                      d="M25.8802 23.2C25.8802 22.5814 26.3817 22.08 27.0002 22.08C27.6188 22.08 28.1203 22.5814 28.1203 23.2V25.44C28.1203 26.0586 27.6188 26.56 27.0002 26.56C26.3817 26.56 25.8802 26.0586 25.8802 25.44V23.2Z"
                      fill="white"
                    />
                    <path
                      d="M28.1203 34.4C28.1203 35.0186 27.6188 35.52 27.0002 35.52C26.3817 35.52 25.8802 35.0186 25.8802 34.4V32.16C25.8802 31.5414 26.3817 31.04 27.0002 31.04C27.6188 31.04 28.1203 31.5414 28.1203 32.16V34.4Z"
                      fill="white"
                    />
                    <path
                      d="M25.8001 18.304C26.0298 18.8784 25.7504 19.5302 25.1761 19.7599C24.6018 19.9896 23.95 19.7103 23.7203 19.136L21.4803 13.536C21.1163 12.626 22.0141 11.7204 22.9272 12.0766C23.7659 12.4037 24.391 12.56 24.7602 12.56C24.8521 12.56 24.9283 12.5404 25.0946 12.4697C25.1387 12.4509 25.1906 12.428 25.3122 12.3742C25.8915 12.1203 26.3491 12 27.0002 12C27.6497 12 28.1146 12.1206 28.6957 12.3721C28.8432 12.4366 28.9021 12.4623 28.9542 12.4838C29.0978 12.5429 29.1669 12.56 29.2402 12.56C29.5878 12.56 30.2185 12.4023 31.0812 12.0735C31.9932 11.7258 32.8825 12.6298 32.5201 13.536L30.2801 19.136C30.0503 19.7103 29.3985 19.9896 28.8242 19.7599C28.2499 19.5302 27.9705 18.8784 28.2003 18.304L29.6096 14.7807C29.4808 14.7936 29.3578 14.8 29.2402 14.8C28.8314 14.8 28.4927 14.7162 28.1013 14.5551C28.0241 14.5232 27.9394 14.4863 27.8064 14.4279C27.4822 14.2877 27.2985 14.24 27.0002 14.24C26.7048 14.24 26.5313 14.2856 26.2114 14.4258C26.1015 14.4745 26.0319 14.5052 25.9706 14.5313C25.5512 14.7095 25.2002 14.8 24.7602 14.8C24.6419 14.8 24.5189 14.7939 24.3911 14.7816L25.8001 18.304Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip6">
                      <rect
                        width={28}
                        height={28}
                        fill="white"
                        transform="translate(13 12)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div>
                  <h4 className="sub-title text-white">
                    { job?.salaryRange }
                  </h4>
                  <span className="text-white">Yearly Salary</span>
                </div>
              </div>
              <div className="d-flex col-xl-3 col-lg-4 col-sm-6 mt-lg-0 mt-3 align-items-center">
                <svg
                  className="mr-3"
                  width={54}
                  height={54}
                  viewBox="0 0 54 54"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width={54} height={54} rx={15} fill="#FBA556" />
                  <path
                    d="M27 15C21.934 15 17.8125 19.1215 17.8125 24.1875C17.8125 25.8091 18.2409 27.4034 19.0515 28.7979C19.2404 29.123 19.4516 29.4398 19.6793 29.7396L26.6008 39H27.3991L34.3207 29.7397C34.5483 29.4398 34.7595 29.1231 34.9485 28.7979C35.7591 27.4034 36.1875 25.8091 36.1875 24.1875C36.1875 19.1215 32.066 15 27 15ZM27 27.2344C25.32 27.2344 23.9531 25.8675 23.9531 24.1875C23.9531 22.5075 25.32 21.1406 27 21.1406C28.68 21.1406 30.0469 22.5075 30.0469 24.1875C30.0469 25.8675 28.68 27.2344 27 27.2344Z"
                    fill="white"
                  />
                </svg>
                <div>
                  <h4 className="sub-title text-white">
                    {job?.employeLocation}
                  </h4>
                  <span className="text-white">Location</span>
                </div>
              </div>
            </div>
          ))
        }
      </div>
      {/* <div className="col-xl-12">
        <div className="d-sm-flex align-items-center mb-sm-3 mt-sm-2 mt-2  mb-2">
          <h4 className="fs-20 text-white mr-auto mb-sm-0 mb-2">Clients</h4>
          <Link
            to="/companies"
            className="btn btn-outline-primary  mb-sm-0 mb-1 text-white"
            style={{ backgroundColor: "#6741FF", borderColor: "#6741FF",borderRadius: 8+'px', minWidth: 152+'px', lineHeight: 18+'px', height: 48+'px' }}
          >
            All clients
          </Link>
        </div>
      </div> */}
      {/* <div className="col-xl-12">
        <div
          className="d-flex flex-wrap search-row py-3 mb-3 rounded justify-content-between align-items-center"
          style={{ backgroundColor: "#1F1D26" }}
        >
          <div className="d-flex col-xl-4 col-xxl-3 col-lg-4 col-sm-6 align-items-center">
            <svg
              className="mr-3"
              width={80}
              height={80}
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
                fill="#D3D3D3"
              />
              <path
                d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
                fill="#F35F31"
              />
              <path
                d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.6549 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
                fill="white"
              />
              <path
                d="M59.0073 59.0077C53.917 64.098 47.0131 66.9576 39.8144 66.9576C32.6157 66.9576 25.7118 64.098 20.6215 59.0077C15.5312 53.9174 12.6715 47.0135 12.6715 39.8148C12.6715 32.6161 15.5312 25.7122 20.6215 20.6219L30.2179 30.2183C27.6728 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4142 27.6728 46.8661 30.2179 49.4112C32.7631 51.9564 36.215 53.3862 39.8144 53.3862C43.4138 53.3862 46.8657 51.9564 49.4108 49.4112L59.0073 59.0077Z"
                fill="#FFCF23"
              />
            </svg>
            <div>
              <h2 className="title">
                <Link to="/profile" className="text-white">
                  Senior UX Designer
                </Link>
              </h2>
              <span className="text-white">Highspeed Studios</span>
            </div>
          </div>
          <div className="d-flex col-xl-3 col-lg-4 col-sm-6 align-items-center">
            <svg
              className="mr-3 ml-lg-0 ml-sm-auto ml-0 mt-sm-0 mt-3"
              width={54}
              height={54}
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width={54} height={54} rx={15} fill="#2BC155" />
              <g clipPath="url(#clip6)">
                <path
                  d="M27.0001 19.84C23.5987 19.84 20.6536 22.2024 19.9157 25.5229L17.4992 36.397C17.4815 36.4768 17.4725 36.5583 17.4725 36.64C17.4725 37.2585 17.974 37.76 18.5925 37.76H35.4077C35.4894 37.76 35.5709 37.751 35.6507 37.7333C36.2545 37.5991 36.6352 37.0008 36.501 36.397L34.0846 25.5229C33.3467 22.2024 30.4016 19.84 27.0001 19.84ZM27.0001 17.6C31.4515 17.6 35.3056 20.6916 36.2712 25.037L38.6877 35.9111C39.0902 37.7226 37.9481 39.5174 36.1366 39.92C35.8973 39.9731 35.6529 40 35.4077 40H18.5925C16.7369 40 15.2325 38.4956 15.2325 36.64C15.2325 36.3948 15.2594 36.1504 15.3126 35.9111L17.729 25.037C18.6947 20.6916 22.5488 17.6 27.0001 17.6Z"
                  fill="white"
                />
                <path
                  d="M29.2402 24.32C29.8588 24.32 30.3602 24.8214 30.3602 25.44C30.3602 26.0585 29.8588 26.56 29.2402 26.56H26.4402C26.1309 26.56 25.8802 26.8107 25.8802 27.12C25.8802 27.4292 26.1309 27.68 26.4402 27.68H27.5602C29.1066 27.68 30.3602 28.9336 30.3602 30.48C30.3602 32.0264 29.1066 33.28 27.5602 33.28H24.7602C24.1416 33.28 23.6402 32.7785 23.6402 32.16C23.6402 31.5414 24.1416 31.04 24.7602 31.04H27.5602C27.8695 31.04 28.1202 30.7892 28.1202 30.48C28.1202 30.1707 27.8695 29.92 27.5602 29.92H26.4402C24.8938 29.92 23.6402 28.6664 23.6402 27.12C23.6402 25.5736 24.8938 24.32 26.4402 24.32H29.2402Z"
                  fill="white"
                />
                <path
                  d="M25.8802 23.2C25.8802 22.5814 26.3817 22.08 27.0002 22.08C27.6188 22.08 28.1203 22.5814 28.1203 23.2V25.44C28.1203 26.0586 27.6188 26.56 27.0002 26.56C26.3817 26.56 25.8802 26.0586 25.8802 25.44V23.2Z"
                  fill="white"
                />
                <path
                  d="M28.1203 34.4C28.1203 35.0186 27.6188 35.52 27.0002 35.52C26.3817 35.52 25.8802 35.0186 25.8802 34.4V32.16C25.8802 31.5414 26.3817 31.04 27.0002 31.04C27.6188 31.04 28.1203 31.5414 28.1203 32.16V34.4Z"
                  fill="white"
                />
                <path
                  d="M25.8001 18.304C26.0298 18.8784 25.7504 19.5302 25.1761 19.7599C24.6018 19.9896 23.95 19.7103 23.7203 19.136L21.4803 13.536C21.1163 12.626 22.0141 11.7204 22.9272 12.0766C23.7659 12.4037 24.391 12.56 24.7602 12.56C24.8521 12.56 24.9283 12.5404 25.0946 12.4697C25.1387 12.4509 25.1906 12.428 25.3122 12.3742C25.8915 12.1203 26.3491 12 27.0002 12C27.6497 12 28.1146 12.1206 28.6957 12.3721C28.8432 12.4366 28.9021 12.4623 28.9542 12.4838C29.0978 12.5429 29.1669 12.56 29.2402 12.56C29.5878 12.56 30.2185 12.4023 31.0812 12.0735C31.9932 11.7258 32.8825 12.6298 32.5201 13.536L30.2801 19.136C30.0503 19.7103 29.3985 19.9896 28.8242 19.7599C28.2499 19.5302 27.9705 18.8784 28.2003 18.304L29.6096 14.7807C29.4808 14.7936 29.3578 14.8 29.2402 14.8C28.8314 14.8 28.4927 14.7162 28.1013 14.5551C28.0241 14.5232 27.9394 14.4863 27.8064 14.4279C27.4822 14.2877 27.2985 14.24 27.0002 14.24C26.7048 14.24 26.5313 14.2856 26.2114 14.4258C26.1015 14.4745 26.0319 14.5052 25.9706 14.5313C25.5512 14.7095 25.2002 14.8 24.7602 14.8C24.6419 14.8 24.5189 14.7939 24.3911 14.7816L25.8001 18.304Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip6">
                  <rect
                    width={28}
                    height={28}
                    fill="white"
                    transform="translate(13 12)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div>
              <h4 className="sub-title text-white">$14,000 - $25,000</h4>
              <span className="text-white">Monthly Salary</span>
            </div>
          </div>
          <div className="d-flex col-xl-3 col-lg-4 col-sm-6 mt-lg-0 mt-3 align-items-center">
            <svg
              className="mr-3"
              width={54}
              height={54}
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width={54} height={54} rx={15} fill="#FBA556" />
              <path
                d="M27 15C21.934 15 17.8125 19.1215 17.8125 24.1875C17.8125 25.8091 18.2409 27.4034 19.0515 28.7979C19.2404 29.123 19.4516 29.4398 19.6793 29.7396L26.6008 39H27.3991L34.3207 29.7397C34.5483 29.4398 34.7595 29.1231 34.9485 28.7979C35.7591 27.4034 36.1875 25.8091 36.1875 24.1875C36.1875 19.1215 32.066 15 27 15ZM27 27.2344C25.32 27.2344 23.9531 25.8675 23.9531 24.1875C23.9531 22.5075 25.32 21.1406 27 21.1406C28.68 21.1406 30.0469 22.5075 30.0469 24.1875C30.0469 25.8675 28.68 27.2344 27 27.2344Z"
                fill="white"
              />
            </svg>
            <div>
              <h4 className="sub-title text-white">London, England</h4>
              <span className="text-white">Location</span>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-wrap search-row py-3 mb-3 rounded justify-content-between align-items-center"
          style={{ backgroundColor: "#1F1D26" }}
        >
          <div className="d-flex col-xl-4 col-xxl-3 col-lg-4 col-sm-6 align-items-center">
            <svg
              className="mr-3"
              width={80}
              height={80}
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
                fill="#D3D3D3"
              />
              <path
                d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
                fill="#F35F31"
              />
              <path
                d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.6549 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
                fill="white"
              />
              <path
                d="M59.0073 59.0077C53.917 64.098 47.0131 66.9576 39.8144 66.9576C32.6157 66.9576 25.7118 64.098 20.6215 59.0077C15.5312 53.9174 12.6715 47.0135 12.6715 39.8148C12.6715 32.6161 15.5312 25.7122 20.6215 20.6219L30.2179 30.2183C27.6728 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4142 27.6728 46.8661 30.2179 49.4112C32.7631 51.9564 36.215 53.3862 39.8144 53.3862C43.4138 53.3862 46.8657 51.9564 49.4108 49.4112L59.0073 59.0077Z"
                fill="#FFCF23"
              />
            </svg>
            <div>
              <h2 className="title">
                <Link to="/profile" className="text-white">
                  Senior UX Designer
                </Link>
              </h2>
              <span className="text-white">Highspeed Studios</span>
            </div>
          </div>
          <div className="d-flex col-xl-3 col-lg-4 col-sm-6 align-items-center">
            <svg
              className="mr-3 ml-lg-0 ml-sm-auto ml-0 mt-sm-0 mt-3"
              width={54}
              height={54}
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width={54} height={54} rx={15} fill="#2BC155" />
              <g clipPath="url(#clip6)">
                <path
                  d="M27.0001 19.84C23.5987 19.84 20.6536 22.2024 19.9157 25.5229L17.4992 36.397C17.4815 36.4768 17.4725 36.5583 17.4725 36.64C17.4725 37.2585 17.974 37.76 18.5925 37.76H35.4077C35.4894 37.76 35.5709 37.751 35.6507 37.7333C36.2545 37.5991 36.6352 37.0008 36.501 36.397L34.0846 25.5229C33.3467 22.2024 30.4016 19.84 27.0001 19.84ZM27.0001 17.6C31.4515 17.6 35.3056 20.6916 36.2712 25.037L38.6877 35.9111C39.0902 37.7226 37.9481 39.5174 36.1366 39.92C35.8973 39.9731 35.6529 40 35.4077 40H18.5925C16.7369 40 15.2325 38.4956 15.2325 36.64C15.2325 36.3948 15.2594 36.1504 15.3126 35.9111L17.729 25.037C18.6947 20.6916 22.5488 17.6 27.0001 17.6Z"
                  fill="white"
                />
                <path
                  d="M29.2402 24.32C29.8588 24.32 30.3602 24.8214 30.3602 25.44C30.3602 26.0585 29.8588 26.56 29.2402 26.56H26.4402C26.1309 26.56 25.8802 26.8107 25.8802 27.12C25.8802 27.4292 26.1309 27.68 26.4402 27.68H27.5602C29.1066 27.68 30.3602 28.9336 30.3602 30.48C30.3602 32.0264 29.1066 33.28 27.5602 33.28H24.7602C24.1416 33.28 23.6402 32.7785 23.6402 32.16C23.6402 31.5414 24.1416 31.04 24.7602 31.04H27.5602C27.8695 31.04 28.1202 30.7892 28.1202 30.48C28.1202 30.1707 27.8695 29.92 27.5602 29.92H26.4402C24.8938 29.92 23.6402 28.6664 23.6402 27.12C23.6402 25.5736 24.8938 24.32 26.4402 24.32H29.2402Z"
                  fill="white"
                />
                <path
                  d="M25.8802 23.2C25.8802 22.5814 26.3817 22.08 27.0002 22.08C27.6188 22.08 28.1203 22.5814 28.1203 23.2V25.44C28.1203 26.0586 27.6188 26.56 27.0002 26.56C26.3817 26.56 25.8802 26.0586 25.8802 25.44V23.2Z"
                  fill="white"
                />
                <path
                  d="M28.1203 34.4C28.1203 35.0186 27.6188 35.52 27.0002 35.52C26.3817 35.52 25.8802 35.0186 25.8802 34.4V32.16C25.8802 31.5414 26.3817 31.04 27.0002 31.04C27.6188 31.04 28.1203 31.5414 28.1203 32.16V34.4Z"
                  fill="white"
                />
                <path
                  d="M25.8001 18.304C26.0298 18.8784 25.7504 19.5302 25.1761 19.7599C24.6018 19.9896 23.95 19.7103 23.7203 19.136L21.4803 13.536C21.1163 12.626 22.0141 11.7204 22.9272 12.0766C23.7659 12.4037 24.391 12.56 24.7602 12.56C24.8521 12.56 24.9283 12.5404 25.0946 12.4697C25.1387 12.4509 25.1906 12.428 25.3122 12.3742C25.8915 12.1203 26.3491 12 27.0002 12C27.6497 12 28.1146 12.1206 28.6957 12.3721C28.8432 12.4366 28.9021 12.4623 28.9542 12.4838C29.0978 12.5429 29.1669 12.56 29.2402 12.56C29.5878 12.56 30.2185 12.4023 31.0812 12.0735C31.9932 11.7258 32.8825 12.6298 32.5201 13.536L30.2801 19.136C30.0503 19.7103 29.3985 19.9896 28.8242 19.7599C28.2499 19.5302 27.9705 18.8784 28.2003 18.304L29.6096 14.7807C29.4808 14.7936 29.3578 14.8 29.2402 14.8C28.8314 14.8 28.4927 14.7162 28.1013 14.5551C28.0241 14.5232 27.9394 14.4863 27.8064 14.4279C27.4822 14.2877 27.2985 14.24 27.0002 14.24C26.7048 14.24 26.5313 14.2856 26.2114 14.4258C26.1015 14.4745 26.0319 14.5052 25.9706 14.5313C25.5512 14.7095 25.2002 14.8 24.7602 14.8C24.6419 14.8 24.5189 14.7939 24.3911 14.7816L25.8001 18.304Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip6">
                  <rect
                    width={28}
                    height={28}
                    fill="white"
                    transform="translate(13 12)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div>
              <h4 className="sub-title text-white">$14,000 - $25,000</h4>
              <span className="text-white">Monthly Salary</span>
            </div>
          </div>
          <div className="d-flex col-xl-3 col-lg-4 col-sm-6 mt-lg-0 mt-3 align-items-center">
            <svg
              className="mr-3"
              width={54}
              height={54}
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width={54} height={54} rx={15} fill="#FBA556" />
              <path
                d="M27 15C21.934 15 17.8125 19.1215 17.8125 24.1875C17.8125 25.8091 18.2409 27.4034 19.0515 28.7979C19.2404 29.123 19.4516 29.4398 19.6793 29.7396L26.6008 39H27.3991L34.3207 29.7397C34.5483 29.4398 34.7595 29.1231 34.9485 28.7979C35.7591 27.4034 36.1875 25.8091 36.1875 24.1875C36.1875 19.1215 32.066 15 27 15ZM27 27.2344C25.32 27.2344 23.9531 25.8675 23.9531 24.1875C23.9531 22.5075 25.32 21.1406 27 21.1406C28.68 21.1406 30.0469 22.5075 30.0469 24.1875C30.0469 25.8675 28.68 27.2344 27 27.2344Z"
                fill="white"
              />
            </svg>
            <div>
              <h4 className="sub-title text-white">London, England</h4>
              <span className="text-white">Location</span>
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-wrap search-row py-3 mb-3 rounded justify-content-between align-items-center"
          style={{ backgroundColor: "#1F1D26" }}
        >
          <div className="d-flex col-xl-4 col-xxl-3 col-lg-4 col-sm-6 align-items-center">
            <svg
              className="mr-3"
              width={80}
              height={80}
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
                fill="#D3D3D3"
              />
              <path
                d="M0 11.6364C0 5.20978 5.20978 0 11.6364 0H68.3636C74.7902 0 80 5.20978 80 11.6364V68.3636C80 74.7902 74.7902 80 68.3636 80H11.6364C5.20978 80 0 74.7902 0 68.3636V11.6364Z"
                fill="#F35F31"
              />
              <path
                d="M20.6216 20.6219C23.142 18.1015 26.1342 16.1022 29.4273 14.7381C32.7205 13.374 36.25 12.672 39.8145 12.672C43.3789 12.672 46.9085 13.374 50.2016 14.7381C53.4947 16.1022 56.4869 18.1015 59.0074 20.6219C61.5278 23.1424 63.5271 26.1346 64.8912 29.4277C66.2552 32.7208 66.9573 36.2504 66.9573 39.8148C66.9573 43.3793 66.2552 46.9088 64.8912 50.202C63.5271 53.4951 61.5278 56.4873 59.0074 59.0077L49.4109 49.4113C50.6711 48.1511 51.6708 46.6549 52.3528 45.0084C53.0348 43.3618 53.3859 41.5971 53.3859 39.8148C53.3859 38.0326 53.0348 36.2678 52.3528 34.6213C51.6708 32.9747 50.6711 31.4786 49.4109 30.2184C48.1507 28.9582 46.6546 27.9585 45.008 27.2765C43.3615 26.5944 41.5967 26.2434 39.8145 26.2434C38.0322 26.2434 36.2675 26.5944 34.6209 27.2765C32.9743 27.9585 31.4782 28.9582 30.218 30.2184L20.6216 20.6219Z"
                fill="white"
              />
              <path
                d="M59.0073 59.0077C53.917 64.098 47.0131 66.9576 39.8144 66.9576C32.6157 66.9576 25.7118 64.098 20.6215 59.0077C15.5312 53.9174 12.6715 47.0135 12.6715 39.8148C12.6715 32.6161 15.5312 25.7122 20.6215 20.6219L30.2179 30.2183C27.6728 32.7635 26.243 36.2154 26.243 39.8148C26.243 43.4142 27.6728 46.8661 30.2179 49.4112C32.7631 51.9564 36.215 53.3862 39.8144 53.3862C43.4138 53.3862 46.8657 51.9564 49.4108 49.4112L59.0073 59.0077Z"
                fill="#FFCF23"
              />
            </svg>
            <div>
              <h2 className="title">
                <Link to="/profile" className="text-white">
                  Senior UX Designer
                </Link>
              </h2>
              <span className="text-white">Highspeed Studios</span>
            </div>
          </div>
          <div className="d-flex col-xl-3 col-lg-4 col-sm-6 align-items-center">
            <svg
              className="mr-3 ml-lg-0 ml-sm-auto ml-0 mt-sm-0 mt-3"
              width={54}
              height={54}
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width={54} height={54} rx={15} fill="#2BC155" />
              <g clipPath="url(#clip6)">
                <path
                  d="M27.0001 19.84C23.5987 19.84 20.6536 22.2024 19.9157 25.5229L17.4992 36.397C17.4815 36.4768 17.4725 36.5583 17.4725 36.64C17.4725 37.2585 17.974 37.76 18.5925 37.76H35.4077C35.4894 37.76 35.5709 37.751 35.6507 37.7333C36.2545 37.5991 36.6352 37.0008 36.501 36.397L34.0846 25.5229C33.3467 22.2024 30.4016 19.84 27.0001 19.84ZM27.0001 17.6C31.4515 17.6 35.3056 20.6916 36.2712 25.037L38.6877 35.9111C39.0902 37.7226 37.9481 39.5174 36.1366 39.92C35.8973 39.9731 35.6529 40 35.4077 40H18.5925C16.7369 40 15.2325 38.4956 15.2325 36.64C15.2325 36.3948 15.2594 36.1504 15.3126 35.9111L17.729 25.037C18.6947 20.6916 22.5488 17.6 27.0001 17.6Z"
                  fill="white"
                />
                <path
                  d="M29.2402 24.32C29.8588 24.32 30.3602 24.8214 30.3602 25.44C30.3602 26.0585 29.8588 26.56 29.2402 26.56H26.4402C26.1309 26.56 25.8802 26.8107 25.8802 27.12C25.8802 27.4292 26.1309 27.68 26.4402 27.68H27.5602C29.1066 27.68 30.3602 28.9336 30.3602 30.48C30.3602 32.0264 29.1066 33.28 27.5602 33.28H24.7602C24.1416 33.28 23.6402 32.7785 23.6402 32.16C23.6402 31.5414 24.1416 31.04 24.7602 31.04H27.5602C27.8695 31.04 28.1202 30.7892 28.1202 30.48C28.1202 30.1707 27.8695 29.92 27.5602 29.92H26.4402C24.8938 29.92 23.6402 28.6664 23.6402 27.12C23.6402 25.5736 24.8938 24.32 26.4402 24.32H29.2402Z"
                  fill="white"
                />
                <path
                  d="M25.8802 23.2C25.8802 22.5814 26.3817 22.08 27.0002 22.08C27.6188 22.08 28.1203 22.5814 28.1203 23.2V25.44C28.1203 26.0586 27.6188 26.56 27.0002 26.56C26.3817 26.56 25.8802 26.0586 25.8802 25.44V23.2Z"
                  fill="white"
                />
                <path
                  d="M28.1203 34.4C28.1203 35.0186 27.6188 35.52 27.0002 35.52C26.3817 35.52 25.8802 35.0186 25.8802 34.4V32.16C25.8802 31.5414 26.3817 31.04 27.0002 31.04C27.6188 31.04 28.1203 31.5414 28.1203 32.16V34.4Z"
                  fill="white"
                />
                <path
                  d="M25.8001 18.304C26.0298 18.8784 25.7504 19.5302 25.1761 19.7599C24.6018 19.9896 23.95 19.7103 23.7203 19.136L21.4803 13.536C21.1163 12.626 22.0141 11.7204 22.9272 12.0766C23.7659 12.4037 24.391 12.56 24.7602 12.56C24.8521 12.56 24.9283 12.5404 25.0946 12.4697C25.1387 12.4509 25.1906 12.428 25.3122 12.3742C25.8915 12.1203 26.3491 12 27.0002 12C27.6497 12 28.1146 12.1206 28.6957 12.3721C28.8432 12.4366 28.9021 12.4623 28.9542 12.4838C29.0978 12.5429 29.1669 12.56 29.2402 12.56C29.5878 12.56 30.2185 12.4023 31.0812 12.0735C31.9932 11.7258 32.8825 12.6298 32.5201 13.536L30.2801 19.136C30.0503 19.7103 29.3985 19.9896 28.8242 19.7599C28.2499 19.5302 27.9705 18.8784 28.2003 18.304L29.6096 14.7807C29.4808 14.7936 29.3578 14.8 29.2402 14.8C28.8314 14.8 28.4927 14.7162 28.1013 14.5551C28.0241 14.5232 27.9394 14.4863 27.8064 14.4279C27.4822 14.2877 27.2985 14.24 27.0002 14.24C26.7048 14.24 26.5313 14.2856 26.2114 14.4258C26.1015 14.4745 26.0319 14.5052 25.9706 14.5313C25.5512 14.7095 25.2002 14.8 24.7602 14.8C24.6419 14.8 24.5189 14.7939 24.3911 14.7816L25.8001 18.304Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip6">
                  <rect
                    width={28}
                    height={28}
                    fill="white"
                    transform="translate(13 12)"
                  />
                </clipPath>
              </defs>
            </svg>
            <div>
              <h4 className="sub-title text-white">$14,000 - $25,000</h4>
              <span className="text-white">Monthly Salary</span>
            </div>
          </div>
          <div className="d-flex col-xl-3 col-lg-4 col-sm-6 mt-lg-0 mt-3 align-items-center">
            <svg
              className="mr-3"
              width={54}
              height={54}
              viewBox="0 0 54 54"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width={54} height={54} rx={15} fill="#FBA556" />
              <path
                d="M27 15C21.934 15 17.8125 19.1215 17.8125 24.1875C17.8125 25.8091 18.2409 27.4034 19.0515 28.7979C19.2404 29.123 19.4516 29.4398 19.6793 29.7396L26.6008 39H27.3991L34.3207 29.7397C34.5483 29.4398 34.7595 29.1231 34.9485 28.7979C35.7591 27.4034 36.1875 25.8091 36.1875 24.1875C36.1875 19.1215 32.066 15 27 15ZM27 27.2344C25.32 27.2344 23.9531 25.8675 23.9531 24.1875C23.9531 22.5075 25.32 21.1406 27 21.1406C28.68 21.1406 30.0469 22.5075 30.0469 24.1875C30.0469 25.8675 28.68 27.2344 27 27.2344Z"
                fill="white"
              />
            </svg>
            <div>
              <h4 className="sub-title text-white">London, England</h4>
              <span className="text-white">Location</span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DashboardJobs;
