import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import "./jobs.css";

import { useUserState } from "../../utils/stateContext";
import { auth, db } from "../../database/firebase";
import { collection, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import Modal from "react-modal";

import JobDescriptionComponent from "./job-description-component";
import JobCardComponent from "../../components/JobCardComponent";

import LoginUserComponent from "../../components/LoginUserComponent";

import { useNavigate } from "react-router-dom";

const customStyles = {
  overlay: {
    background: "rgba(0,0,0,0.1)",
  },
  content: {
    background: "rgba(255, 255, 255, 0)",
    border: 0,
    minHeight: "102vh",
    height: "100%",
    left: "calc((100vw - 70%) + 25px)",
    overflowX: "hidden",
    overflowY: "scroll",
    top: "-25px",
    // transform: "translate(50%, 50%)",
    width: "100%",
  },
};

const Jobs = (props) => {
  const [show, setShow] = useState(false);
  const { user, setUser, logout } = useUserState();
  const [jobs, setJobs] = useState([]);
  const navigate = useNavigate();
  const [currentJob, setCurrentJob] = useState(null);
  const [toggleReadJob, setToggleReadJob] = useState(false);
  const [refreshJobs, setRefreshJobs] = useState(null);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        const userRef = doc(db, "users", firebaseUser.uid);
        const queryAdmins = await getDocs(collection(db, "admins"));
        const admins = queryAdmins.docs
          .map((doc) => doc.data())
          .map((u) => u.email);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUser(docSnap.data());
          setUser((prevState) => ({
            ...prevState,
            userUID: firebaseUser.uid,
            isAdmin: admins?.includes(firebaseUser?.email),
          }));
        }
        setLoading(false);
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    async function fetchJobs() {
      const querySnapshot = await getDocs(collection(db, "jobs"));
      const jobsData = querySnapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setJobs(jobsData);
    }
    fetchJobs();
    return () => {
      controller.abort();
    };
  }, [refreshJobs]);

  const handleLogout = () => {
    logout()
      .then(() => {
        setUser(null);
        setShow(false);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {});

  // // Mobile menu
  // const mobileMenu = document.querySelector("#mobile-menu")

  // // Buttons
  // const closeButton = document.querySelector("#close-mobile-menu")
  // const openButton = document.querySelector("#open-mobile-menu")

  // // On openButton click, set the mobileMenu position left to -100vw
  // openButton?.addEventListener("click", function() {
  //     mobileMenu.style.transform = "translateX(0%)"
  // })

  // // On closeButton click, set the mobileMenu position to 0vw
  // closeButton?.addEventListener("click", function() {
  //     mobileMenu.style.transform = "translateX(100%)"
  // })

  const openPreview = (job) => {
    setCurrentJob(job);
    setToggleReadJob(true);
    const mobileMenu = document.querySelector("#mobile-menu");
    mobileMenu.style.transform = `translateX(${
      window.innerWidth >= 984 ? "20" : "0"
    }%)`;
    const overlay = document.createElement("div");
    overlay.id = "overlayBlured";
    document.querySelector("#app").appendChild(overlay);
    document.querySelector("#overlayBlured").style.display = "block";
  };

  const closePreview = () => {
    setToggleReadJob(false);
    setCurrentJob(null);
    const mobileMenu = document.querySelector("#mobile-menu");
    mobileMenu.style.transform = "translateX(100%)";
    const overlay = document.querySelector("#overlayBlured");
    overlay.style.display = "none";
    overlay.remove();
  };

  const applyToJob = async (job) => {
    if (job?.id && user?.userUID && user?.userType === "candidate") {
      const jobApplyRef = doc(db, "jobs", job.id);
      const userDocRef = doc(db, "users", user?.userUID);
      const jobDoc = await getDoc(jobApplyRef);
      const userDoc = await getDoc(userDocRef);
      const jobData = jobDoc.data();
      const userData = userDoc.data();
      if (!jobData.applies) {
        jobData.applies = [];
      }
      if (!userData.applies) {
        userData.applies = [];
      }
      if (
        !userData?.applies?.map((apl) => apl.jobTitle).includes(job.jobTitle)
      ) {
        userData.applies.push({
          jobTitle: job.jobTitle,
          created_at: Date.now(),
        });
      }
      if (
        !jobData?.applies?.map((apl) => apl.user_uid).includes(user?.userUID)
      ) {
        jobData.applies.push({
          user_uid: user?.userUID,
          created_at: Date.now(),
        });
      }
      await setDoc(jobApplyRef, { ...jobData });
      await setDoc(userDocRef, { ...userData });
      setRefreshJobs((refreshJobs || 0) + 1);
      setCurrentJob(jobData);
    } else {
      navigate("/candidate-step-0");
    }
  };

  return (
    <div className="jobs-container">
      <Helmet>
        <title>Jobs DecentraLoco</title>
        <meta property="og:title" content="Jobs DecentraLoco" />
      </Helmet>
      <nav className="jobs-navbar">
        <header data-thq="thq-navbar" className="jobs-navbar-interactive">
          <Link to="/" className="jobs-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="jobs-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="jobs-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="jobs-nav"
            >
              <Link
                to="/apply-as-a-candidate"
                className="jobs-navlink01 button"
              >
                Candidate&apos;s Home
              </Link>
              <Link to="/jobs" className="jobs-navlink02 button">
                Jobs
              </Link>
              <Link to="/courses" className="jobs-navlink03 button">
                Resources
              </Link>
            </nav>
          </div>
          <div data-thq="thq-navbar-btn-group" className="jobs-btn-group">
            <Link to="/apply-as-a-candidate" className="jobs-login button">
              Apply as a Talent
            </Link>

            <LoginUserComponent
              user={user}
              handleLogout={handleLogout}
              show={show}
              setShow={setShow}
            ></LoginUserComponent>
          </div>
          <div data-thq="thq-burger-menu" className="jobs-burger-menu">
            <svg viewBox="0 0 1024 1024" className="jobs-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="jobs-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="jobs-nav1"
            >
              <div className="jobs-container01">
                <Link to="/" className="jobs-navlink04">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="jobs-finbest1"
                  />
                </Link>
                <div data-thq="thq-close-menu" className="jobs-menu-close">
                  <svg viewBox="0 0 1024 1024" className="jobs-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="jobs-nav2"
              >
                <Link to="/apply-as-a-candidate" className="jobs-navlink05">
                  Candidate&apos;s Home
                </Link>
                <Link to="/jobs" className="jobs-navlink06">
                  Jobs
                </Link>
                <Link to="/courses" className="jobs-navlink07">
                  Resources
                </Link>
              </nav>
              <div className="jobs-container02">
                <Link to="/apply-as-a-candidate" className="jobs-login1 button">
                  Apply as a Talent
                </Link>

                <LoginUserComponent
                  user={user}
                  handleLogout={handleLogout}
                  show={show}
                  setShow={setShow}
                ></LoginUserComponent>
              </div>
            </div>
          </div>
        </header>
        <div id="mobile-menu" className="jobs-mobile">
          <JobDescriptionComponent
            closePreview={closePreview}
            job={currentJob}
            user={user}
            applyToJob={applyToJob}
          ></JobDescriptionComponent>
        </div>
      </nav>
      <div className="jobs-banner">
        <h1 className="jobs-text06">
          <span>
            Let&apos;s find your
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="jobs-text08">Dream</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="jobs-text10">Job</span>
          <br></br>
          <span>with Decentraloco</span>
          <br></br>
        </h1>
        <span className="jobs-text14">
          <span>
            Explore top job opportunities on
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="jobs-text16">Blockchain</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="jobs-text18">Gaming</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="jobs-text20">AI/ML</span>
          <span> ...</span>
        </span>
      </div>
      <div className="jobs-jobs-sectors">
        {jobs?.filter((job) => job.category === "blockchain")?.length > 0 && (
          <div className="jobs-blockchain">
            <div className="jobs-container03">
              <span className="jobs-text22">Blockchain</span>
              <span className="jobs-text23">
                Open Jobs in our blockchain teams
              </span>
            </div>
            <div className="jobs-container04">
              {jobs
                .filter((job) => job.category === "blockchain")
                .map((job, key) => {
                  return (
                    <JobCardComponent
                      job={job}
                      openPreview={openPreview}
                      applied={job?.applies
                        ?.map((ap) => ap.user_uid)
                        .includes(user?.userUID)}
                      signedIn={user?.userUID}
                      applyToJob={applyToJob}
                      key={key}
                    ></JobCardComponent>
                  );
                })}
            </div>
          </div>
        )}

        {jobs?.filter((job) => job.category === "gaming")?.length > 0 && (
          <div className="jobs-gaming">
            <div className="jobs-container17">
              <span className="jobs-text36">
                Gaming
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="jobs-text37">Open Jobs in our Gaming teams</span>
            </div>
            <div className="jobs-container18">
              {jobs
                .filter((job) => job.category === "gaming")
                .map((job, key) => {
                  return (
                    <JobCardComponent
                      job={job}
                      openPreview={openPreview}
                      applied={job?.applies
                        ?.map((ap) => ap.user_uid)
                        .includes(user?.userUID)}
                      signedIn={user?.userUID}
                      applyToJob={applyToJob}
                      key={key}
                    ></JobCardComponent>
                  );
                })}
            </div>
          </div>
        )}

        {jobs?.filter((job) => job.category === "ai-ml")?.length > 0 && (
          <div className="jobs-aiml">
            <div className="jobs-container31">
              <span className="jobs-text50">AI/ML</span>
              <span className="jobs-text51">Open Jobs in our AI/ML teams</span>
            </div>
            <div className="jobs-container32">
              {jobs
                .filter((job) => job.category === "ai-ml")
                .map((job, key) => {
                  return (
                    <JobCardComponent
                      job={job}
                      openPreview={openPreview}
                      applied={job?.applies
                        ?.map((ap) => ap.user_uid)
                        .includes(user?.userUID)}
                      signedIn={user?.userUID}
                      applyToJob={applyToJob}
                      key={key}
                    ></JobCardComponent>
                  );
                })}
            </div>
          </div>
        )}

        {jobs?.filter((job) => job.category === "design")?.length > 0 && (
          <div className="jobs-design">
            <div className="jobs-container41">
              <span className="jobs-text60">Design</span>
              <span className="jobs-text61">Open Jobs in our Design teams</span>
            </div>
            <div className="jobs-container42">
              {jobs
                .filter((job) => job.category === "design")
                .map((job, key) => {
                  return (
                    <JobCardComponent
                      job={job}
                      openPreview={openPreview}
                      applied={job?.applies
                        ?.map((ap) => ap.user_uid)
                        .includes(user?.userUID)}
                      signedIn={user?.userUID}
                      applyToJob={applyToJob}
                      key={key}
                    ></JobCardComponent>
                  );
                })}
            </div>
          </div>
        )}

        {jobs?.filter((job) => job.category === "c-level")?.length > 0 && (
          <div className="jobs-c-level">
            <div className="jobs-container47">
              <span className="jobs-text66">C-level</span>
              <span className="jobs-text67">
                Open Jobs in our C-level teams
              </span>
            </div>
            <div className="jobs-container48">
              {jobs
                .filter((job) => job.category === "c-level")
                .map((job, key) => {
                  return (
                    <JobCardComponent
                      job={job}
                      openPreview={openPreview}
                      applied={job?.applies
                        ?.map((ap) => ap.user_uid)
                        .includes(user?.userUID)}
                      signedIn={user?.userUID}
                      applyToJob={applyToJob}
                      key={key}
                    ></JobCardComponent>
                  );
                })}
            </div>
          </div>
        )}

        {jobs?.filter((job) => job.category === "other")?.length > 0 && (
          <div className="jobs-other-jobs">
            <div className="jobs-container53">
              <span className="jobs-text72">Other jobs</span>
              <span className="jobs-text73">Open Jobs in our teams</span>
            </div>
            <div className="jobs-container54">
              {jobs
                .filter((job) => job.category === "other")
                .map((job, key) => {
                  return (
                    <JobCardComponent
                      job={job}
                      openPreview={openPreview}
                      applied={job?.applies
                        ?.map((ap) => ap.user_uid)
                        .includes(user?.userUID)}
                      signedIn={user?.userUID}
                      applyToJob={applyToJob}
                      key={key}
                    ></JobCardComponent>
                  );
                })}
            </div>
          </div>
        )}
      </div>
      <div className="jobs-separator"></div>
      <section className="jobs-section">
        <header className="jobs-header">
          <header className="jobs-left">
            <h2 className="jobs-heading section-heading">
              <span>How to get started with</span>
              <br></br>
              <span className="jobs-text88">DecentraLoco</span>
              <br></br>
            </h2>
          </header>
        </header>
        <main className="jobs-cards">
          <section className="jobs-card">
            <main className="jobs-content">
              <h1 className="jobs-header1">01</h1>
              <div className="jobs-container67"></div>
              <h1 className="jobs-header2">Register account</h1>
              <p className="jobs-description">
                Register to join DecentraLoco Talent Network
              </p>
            </main>
          </section>
          <img
            alt="image"
            src="/playground_assets/group%2033635-200h.png"
            className="jobs-image14"
          />
          <img
            alt="image"
            src="/playground_assets/group%2033634-200w.png"
            className="jobs-image15"
          />
          <section className="jobs-card1">
            <main className="jobs-content1">
              <h1 className="jobs-header3">02</h1>
              <div className="jobs-container68"></div>
              <h1 className="jobs-header4">Upload your resume</h1>
              <p className="jobs-description1">
                Before uploading your resume, please make sure that all details
                are up to date and correct
              </p>
            </main>
          </section>
          <img
            alt="image"
            src="/playground_assets/group%2033635-200h.png"
            className="jobs-image16"
          />
          <img
            alt="image"
            src="/playground_assets/group%2033634-200w.png"
            className="jobs-image17"
          />
          <section className="jobs-card2">
            <main className="jobs-content2">
              <h1 className="jobs-header5">03</h1>
              <div className="jobs-container69"></div>
              <h1 className="jobs-header6">Apply to jobs</h1>
              <p className="jobs-description2">
                Please read descriptions fully before applying to make sure it
                is a good fit
              </p>
            </main>
          </section>
        </main>
      </section>
      <Footer rootClassName="footer-root-class-name6"></Footer>

      {/* <Modal
        isOpen={toggleReadJob}
        // onAfterOpen={afterOpenModal}
        ariaHideApp={false}
        onRequestClose={() => closePreview() }
        style={customStyles}
        contentLabel="Job Preview"
        key={refreshJobs}
        className={'jobs-page'}
      >
        <JobDescriptionComponent 
          closePreview={closePreview} 
          job={currentJob} 
          user={user} 
          applyToJob={applyToJob}
        ></JobDescriptionComponent>
      </Modal> */}
    </div>
  );
};

export default Jobs;
