import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import "./about-us-com.css";
import { signOut } from "firebase/auth";
import { auth } from "../../database/firebase";
import { useUserState } from "../../utils/stateContext";
import LoginUserComponent from "../../components/LoginUserComponent";

const AboutUsCom = (props) => {
  const navigate = useNavigate();
  const { user, setUser } = useUserState();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser({});
        navigate("/");
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="blogs-container">
      <Helmet>
        <title>About Us Decentraloco</title>
        <meta property="og:title" content="About Us Decentraloco" />
      </Helmet>
      <nav className="blogs-navbar">
        <header data-thq="thq-navbar" className="blogs-navbar-interactive">
          <Link to="/" className="blogs-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="blogs-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="blogs-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="blogs-nav"
            >
              <Link to="/" className="blogs-navlink1 button">
                Home
              </Link>
              <Link to="/about-us" className="blogs-navlink3 button">
                About Us
              </Link>
              <Link to="/contact-us" className="blogs-navlink2 button">
                Values
              </Link>
              <Link to="/blogs" className="blogs-navlink2 button">
                Blog
              </Link>
            </nav>
          </div>
          <div data-thq="thq-navbar-btn-group" className="blogs-btn-group">
            <Link to="/apply-as-a-candidate" className="blogs-login button">
              Apply as a Talent
            </Link>
            <Link to="/build-your-team" className="blogs-login1 button">
              Hire Talent
            </Link>
            <LoginUserComponent
              user={user}
              handleLogout={handleLogout}
              show={show}
              setShow={setShow}
            ></LoginUserComponent>
          </div>
          <div data-thq="thq-burger-menu" className="blogs-burger-menu">
            <svg viewBox="0 0 1024 1024" className="blogs-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="blogs-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="blogs-nav1"
            >
              <div className="blogs-container01">
                <Link to="/" className="blogs-navlink4">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="blogs-finbest1"
                  />
                </Link>
                <div data-thq="thq-close-menu" className="blogs-menu-close">
                  <svg viewBox="0 0 1024 1024" className="blogs-icon2">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="blogs-nav2"
              >
                <Link to="/" className="blogs-navlink5">
                  Home
                </Link>
                <Link to="/contact-us" className="blogs-navlink6">
                  Values
                </Link>
                <Link to="/blogs" className="blogs-navlink7">
                  Blog
                </Link>
              </nav>
              <div className="blogs-container02">
                <Link
                  to="/apply-as-a-candidate"
                  className="blogs-login2 button"
                >
                  Apply as a Talent
                </Link>

                <LoginUserComponent
                  user={user}
                  handleLogout={handleLogout}
                  show={show}
                  setShow={setShow}
                ></LoginUserComponent>

                <Link to="/build-your-team" className="blogs-login3 button">
                  Hire Talent
                </Link>
              </div>
            </div>
          </div>
        </header>
      </nav>
      <div className="about-us-com-ast">
        <img
          src={props.image_src}
          loading="lazy"
          className="about-us-com-image"
        />
        <div className="about-us-com-container">
          <h2 className="about-us-com-text">
            <span>
              About
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="about-us-com-text02">Us</span>
          </h2>
          <span className="about-us-com-text03">
            <span>
              We are a straight-talking, no-nonsense, Talent Partner
              specialising in niche tech hires.
            </span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <span>
              We work with early-stage start-ups, hypergrowth tech projects,
              Gaming Studios, and beyond.
            </span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <span>
              Essentially, awesome projects that build cool 💩 in Blockchain,
              Gaming, and AI.
            </span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <span>You build awesome technology.</span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <span>We build awesome teams.</span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <span>
              Together we'll build the future.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>

            <br></br>
            <span>Welcome to our ecosystem 🫡</span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </span>
        </div>
        <img
          src={props.image_src1}
          loading="lazy"
          className="about-us-com-image1"
        />
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

AboutUsCom.defaultProps = {
  image_src: "/playground_assets/art-work07-1500w.png",
  image_src1: "/playground_assets/art-work07-1500w.png",
};

AboutUsCom.propTypes = {
  image_src: PropTypes.string,
  image_src1: PropTypes.string,
};

export default AboutUsCom;
