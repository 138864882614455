import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FormProvider, useForm, Controller } from "react-hook-form";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./candidate-step-0.css";
import { useAppState } from "../../utils/stateContext";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import countries from "../../data/countries.json";
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import CandidateProtectedSteps from "../../utils/CandidateProtectedSteps";
import CustomDropzone from "../../components/CustomDropzone";
import Dropzone from "react-dropzone";
import { auth, db, storage } from "../../database/firebase";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import Spinner from "../../components/Spinner";
import { displayName } from "react-quill";

const regexEmailPattern =
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

const CandidateStep0 = (props) => {
  const navigate = useNavigate();
  const { setUser } = useAppState();
  const { appState, setAppState } = useAppState();
  const [newUser, setNewUser] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue,
  } = useForm({
    defaultValues: appState.candidate,
  });

  const saveData = async (data) => {
    setIsLoading(true);
    const emailExists = await checkEmailExists(data.email);
    if (emailExists === true) {
      setEmailExists(true);
      setIsLoading(false);
      return;
    } else {
      try {
        // Create a new user with email and password
        const newUser = await createUserWithEmailAndPassword(
          auth,
          data.email,
          data.password
        );
        setNewUser(newUser);
        // Save user details in Firestore collection
        const userData = data;
        delete userData.password; // can be ignored !
        delete userData.confirmPassword; // can be ignored !
        const resumeRef = ref(storage, `${newUser.user.uid}/resume.pdf`);
        await uploadBytes(resumeRef, data.resume[0]);

        getDownloadURL(resumeRef).then(async (pdfUrl) => {
          delete userData.resume;
          await setDoc(doc(db, "users", newUser.user.uid), {
            ...userData,
            userUID: newUser.user.uid,
            createdAt: Date.now(),
            resumeURL: pdfUrl,
          });
          appState.candidate = {
            ...appState.candidate,
            createdAt: Date.now(),
            resumeURL: pdfUrl,
          };
          setAppState({ ...appState });
          setIsLoading(false);
        });

        // Redirect to profile page
        navigate("/jobs");
      } catch (error) {
        setIsLoading(false);
        console.error("Error signing up:", error);
      }
    }

    // navigate("/candidate-step-1");
  };

  function checkEmailExists(email) {
    return fetchSignInMethodsForEmail(auth, email)
      .then((signInMethods) => {
        if (signInMethods.length === 0) {
          return false; // email does not exist
        } else {
          return true; // email exists
        }
      })
      .catch((error) => {
        console.error("Error checking email existence:", error);
        return null;
      });
  }

  return (
    <CandidateProtectedRoute>
      <div className="candidate-step0-container">
        <Helmet>
          <title>Register as a candidate Decentraloco</title>
          <meta
            property="og:title"
            content="Register as a candidate Decentraloco"
          />
        </Helmet>
        <NavbarLogIn rootClassName="navbar-log-in-root-class-name15"></NavbarLogIn>
        <div className="candidate-step0-hero">
          <div className="candidate-step0-container01">
            <div className="candidate-step0-container02">
              <span className="candidate-step0-text">Join Talent Network</span>
            </div>
            <div className="candidate-step0-container03"></div>
            <form>
              <input
                {...register("userType")}
                type="hidden"
                value="candidate"
              />
              <div className="candidate-step0-container04">
                <div className="candidate-step0-container05">
                  <span className="candidate-step0-text06">
                    <span>First Name *</span>
                    <br></br>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                  <input
                    {...register("firstName", {
                      required: true,
                      maxLength: 25,
                      minLength: 3,
                    })}
                    type="text"
                    placeholder="First name"
                    value={appState.firstName}
                    className={
                      "candidate-step0-textinput input " +
                      (errors.firstName ? "error-border" : "")
                    }
                  />
                  {errors.firstName && (
                    <p className="error-text">
                      *Required, must have 3-25 chars
                    </p>
                  )}
                </div>
                <div className="candidate-step0-container06">
                  <span className="candidate-step0-text11">
                    <span>Last Name *</span>
                    <br></br>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                  <input
                    {...register("lastName", {
                      required: true,
                      maxLength: 25,
                      minLength: 3,
                    })}
                    type="text"
                    placeholder="Last name"
                    value={appState.lastName}
                    className={
                      "candidate-step0-textinput1 teleport-show input " +
                      (errors.lastName ? "error-border" : "")
                    }
                  />
                  {errors.lastName && (
                    <p className="error-text">
                      *Required, must have 3-16 chars
                    </p>
                  )}
                </div>
              </div>
              <div className="candidate-step0-container07">
                <span className="candidate-step0-text16">
                  <span>Email Address *</span>
                  <br></br>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                </span>
                <input
                  {...register("email", {
                    required: true,
                    pattern: regexEmailPattern,
                  })}
                  type="email"
                  placeholder="Email"
                  value={appState.email}
                  className={
                    "candidate-step0-textinput2 teleport-show input " +
                    (errors.email ? "error-border" : "")
                  }
                />
                {errors.email && (
                  <p className="error-text">*Required, must be a valid email</p>
                )}
                {emailExists && (
                  <p className="error-text">*This email already exists.</p>
                )}
              </div>
              <div className="candidate-step0-container08">
                <div className="candidate-step0-container09">
                  <span className="candidate-step0-text21">
                    <span>Set Password *</span>
                    <br></br>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                  <input
                    {...register("password", {
                      required: true,
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
                    })}
                    type="password"
                    placeholder="8 Characters or more"
                    value={appState.password}
                    className={
                      "candidate-step0-textinput3 input " +
                      (errors.password ? "error-border" : "")
                    }
                  />
                  {errors.password && (
                    <p className="error-text" style={{ width: "200px" }}>
                      *Required, must have: min 8 chars, 1 uppercase, 1
                      lowercase, and 1 number.
                    </p>
                  )}
                </div>
                <div className="candidate-step0-container10">
                  <span className="candidate-step0-text26">
                    <span>Confirm Password *</span>
                    <br></br>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                  <input
                    {...register("confirmPassword", {
                      required: true,
                      validate: (value) => {
                        const { password } = getValues();
                        return password === value;
                      },
                    })}
                    type="password"
                    placeholder="Confirm password"
                    value={appState.confirmPassword}
                    className={
                      "candidate-step0-textinput4 teleport-show input " +
                      (errors.confirmPassword ? "error-border" : "")
                    }
                  />
                  {errors.confirmPassword && (
                    <p className="error-text">
                      *Required, passwords should match
                    </p>
                  )}
                </div>
              </div>
              <Controller
                control={control}
                name="resume"
                rules={{
                  required: { value: true, message: "This field is required" },
                }}
                render={({ field: { onChange, onBlur }, fieldState }) => (
                  <Dropzone
                    noClick
                    onDrop={(acceptedFiles, fileRejections) => {
                      setValue("resume", acceptedFiles, {
                        shouldValidate: true,
                      });
                    }}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      open,
                      isDragActive,
                      acceptedFiles,
                    }) => (
                      <div onClick={open}>
                        <div
                          className="candidate-step5-container7"
                          {...getRootProps()}
                          type="file"
                          role="button"
                          aria-label="File Upload"
                        >
                          <input
                            {...getInputProps({
                              id: "spreadsheet",
                              onChange,
                              onBlur,
                            })}
                            accept=".pdf,.doc,.docx"
                          />
                          <svg
                            viewBox="0 0 1024 1024"
                            className="candidate-step5-icon"
                          >
                            <path d="M598 554h128l-214-212-214 212h128v172h172v-172zM826 428q82 6 140 67t58 145q0 88-63 151t-151 63h-554q-106 0-181-75t-75-181q0-94 67-169t161-85q42-78 118-126t166-48q108 0 201 76t113 182z"></path>
                          </svg>

                          <span className="candidate-step5-text04">
                            Drag and drop
                          </span>
                          <span className="candidate-step5-text05">
                            your file here, or{" "}
                            <span onClick={open} style={{ fontWeight: "bold" }}>
                              browse{" "}
                            </span>
                          </span>
                          <span
                            style={{
                              borderTop: "1px solid #fff",
                              borderBottom: "1px solid #fff",
                              color: "#ccc",
                              fontSize: "12px",
                              marginTop: "10px",
                            }}
                          >
                            Required formats: .doc, .docx, .pdf
                          </span>
                          {acceptedFiles.length
                            ? acceptedFiles[0].name
                            : "No file selected."}
                          <div>
                            {fieldState.error && (
                              <span role="alert">
                                {fieldState.error.message}
                              </span>
                            )}
                          </div>
                          {/* {!!files?.length && (
                            <div className=" ">
                              {files.map((file) => {
                                return (
                                  <div key={file.name}>
                                    <p
                                      style={{
                                        background: "#fff",
                                        fontSize: "13px",
                                        fontWeight: "bold",
                                        marginTop: "10px",
                                        padding: "5px",
                                      }}
                                    >
                                      Selected file: {file.name}
                                    </p>
                                  </div>
                                );
                              })}
                            </div>
                          )} */}
                          {/* {errorExtension && <p>{errorExtension.message}</p>} */}
                        </div>
                        {/* {errors.resume?.file && (
                          <p className="error-text">
                            *Uploading a file is required
                          </p>
                        )} */}
                      </div>
                    )}
                  </Dropzone>
                )}
              />

              {/* <div className="">
                <CustomDropzone name="file" label="File Upload" />
              </div> */}
              <div className="candidate-step0-container14">
                <div>
                  <input
                    type="checkbox"
                    className="candidate-step0-checkbox"
                    defaultChecked={appState.agreement}
                    value={appState.agreement}
                    {...register("agreement", { required: true })}
                  />
                  <span className="candidate-step0-text37">I agree to</span>
                  <Link
                    to="/privacy-policy"
                    className="candidate-step0-navlink"
                  >
                    DecentraLoco&apos;s Privacy Policy
                  </Link>
                </div>
                {errors.agreement && (
                  <p className="error-text">
                    Please check the box to agree to our privacy policy before
                    continuing.
                  </p>
                )}
              </div>
              <Link
                className="candidate-step0-navlink1 button"
                onClick={handleSubmit(saveData)}
              >
                <span className="log-in-text09">
                  {isLoading ? <Spinner /> : "Sign Up"}
                </span>
              </Link>
            </form>
            <div className="candidate-step0-container15">
              <span className="candidate-step0-text39">Or </span>
              <Link to="/log-in" className="candidate-step0-navlink2">
                Log In
              </Link>
            </div>
          </div>
          <img
            src="/playground_assets/art-work%2001-1500h.png"
            loading="lazy"
            className="candidate-step0-image"
          />
        </div>
        <FooterV2></FooterV2>
      </div>
    </CandidateProtectedRoute>
  );
};

export default CandidateStep0;
