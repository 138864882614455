import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./candidate-step-3.css";
import { useAppState } from "../../utils/stateContext";
import Step3Item from "../../components/candidate/Step3Item";
import { useForm } from "react-hook-form";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import CandidateProtectedSteps from "../../utils/CandidateProtectedSteps";

const data = [
  {
    id: 1,
    title: "Basic",
    text: "I can only communicate in written form over email or Slack effectively. Verbal communication in English with a U.S.engineering manager would be slow and impractical.",
  },
  {
    id: 2,
    title: "Average",
    text: "I can communicate with a U.S. engineering manager if we both speak slowly and occasionally repeat sentences that the other person didn’t understand",
  },
  {
    id: 3,
    title: "Fluent",
    text: "I can fully speak and understand the English language at all levels.",
  },
];

const CandidateStep3 = (props) => {
  const { appState, setAppState } = useAppState();
  const navigate = useNavigate();
  const [level, setLevel] = useState(appState.candidate.EnglishLevel);
  const [formError, setFormError] = useState(false);
  const { handleSubmit, register, setValue } = useForm({
    defaultValues: appState.candidate,
  });

  const saveData = (data) => {
    if (!level) {
      setFormError(true);
      return;
    }
    setAppState({ ...appState, candidate: { ...data } });
    navigate("/candidate-step-4");
  };

  return (
    <CandidateProtectedRoute>
      <CandidateProtectedSteps>
        <div className="candidate-step3-container">
          <Helmet>
            <title>Candidate-step-3 - Decentraloco</title>
            <meta
              property="og:title"
              content="Candidate-step-3 - Decentraloco"
            />
          </Helmet>
          <NavbarLogIn rootClassName="navbar-log-in-root-class-name6"></NavbarLogIn>
          <div className="candidate-step3-container01">
            <div className="candidate-step3-container02">
              <span className="candidate-step3-text">
                <span className="candidate-step3-text01">Step 3 of 6</span>
                <br></br>
              </span>
              <div className="candidate-step3-container03">
                <div className="candidate-step3-container04"></div>
              </div>
            </div>
            <div className="candidate-step3-container05">
              <span className="candidate-step3-text03">
                <span className="candidate-step3-text04">
                  How’s your English?
                </span>
                <br className="candidate-step3-text05"></br>
              </span>
              <span className="candidate-step3-text06">
                <span className="candidate-step3-text07">
                  Most of our clients are English in regards to language,
                </span>
                <br></br>
                <span>tell us where you are with your ability</span>
                <br></br>
              </span>
            </div>
            <form id="step-3-form">
              {data.map(({ id, title, text }, index) => (
                <Step3Item
                  key={id}
                  title={title}
                  text={text}
                  index={index}
                  name="EnglishLevel"
                  level={level}
                  setLevel={setLevel}
                  setValue={setValue}
                  register={register}
                />
              ))}
            </form>
            {formError && (
              <p className="error-text">*Required, please choose an option</p>
            )}
          </div>
          <div className="candidate-step3-container09">
            <Link
              to="/candidate-step-2"
              disabled
              className="candidate-step3-navlink button"
            >
              <span className="candidate-step3-text17">
                <span>Back</span>
                <br></br>
              </span>
            </Link>
            <Link
              className="candidate-step3-navlink1 button"
              onClick={handleSubmit(saveData)}
            >
              <span className="candidate-step3-text20">Next</span>
            </Link>
          </div>
          <FooterV2 rootClassName="footer-v2-root-class-name5"></FooterV2>
        </div>
      </CandidateProtectedSteps>
    </CandidateProtectedRoute>
  );
};

export default CandidateStep3;
