import React from "react";
import { Link } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import "./f-a-qs.css";

const FAQs = (props) => {
  return (
    <div className="f-a-qs-container">
      <Helmet>
        <title>FAQs Decentraloco</title>
        <meta property="og:title" content="FAQs Decentraloco" />
      </Helmet>
      <nav className="f-a-qs-navbar">
        <header data-thq="thq-navbar" className="f-a-qs-navbar-interactive">
          <Link to="/" className="f-a-qs-navlink">
            <img
              alt="pastedImage"
              src="6725391b-a719-4c48-8583-5135e866cdfe"
              className="f-a-qs-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="f-a-qs-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="f-a-qs-nav"
            >
              <Link to="/" className="f-a-qs-navlink01 button">
                Home
              </Link>
              <Link to="/contact-us" className="f-a-qs-navlink02 button">
                Values
              </Link>
              <Link to="/blogs" className="f-a-qs-navlink03 button">
                Blog
              </Link>
              <Link to="/f-a-qs" className="f-a-qs-navlink04 button">
                FAQ&apos;s
              </Link>
            </nav>
          </div>
          <div data-thq="thq-navbar-btn-group" className="f-a-qs-btn-group">
            <Link to="/apply-as-a-candidate" className="f-a-qs-login button">
              Apply as a Talent
            </Link>
            <Link to="/build-your-team" className="f-a-qs-login1 button">
              Hire Talent
            </Link>
            <Link to="/log-in" className="f-a-qs-register button">
              Log In
            </Link>
          </div>
          <div data-thq="thq-burger-menu" className="f-a-qs-burger-menu">
            <svg viewBox="0 0 1024 1024" className="f-a-qs-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="f-a-qs-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="f-a-qs-nav1"
            >
              <div className="f-a-qs-container1">
                <Link to="/" className="f-a-qs-navlink05">
                  <img
                    alt="pastedImage"
                    src="6725391b-a719-4c48-8583-5135e866cdfe"
                    className="f-a-qs-finbest1"
                  />
                </Link>
                <div data-thq="thq-close-menu" className="f-a-qs-menu-close">
                  <svg viewBox="0 0 1024 1024" className="f-a-qs-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="f-a-qs-nav2"
              >
                <Link to="/" className="f-a-qs-navlink06">
                  Home
                </Link>
                <Link to="/contact-us" className="f-a-qs-navlink07">
                  Values
                </Link>
                <Link to="/blogs" className="f-a-qs-navlink08">
                  Blog
                </Link>
                <Link to="/f-a-qs" className="f-a-qs-navlink09">
                  FAQ&apos;s
                </Link>
              </nav>
              <div className="f-a-qs-container2">
                <Link to="/log-in" className="f-a-qs-register1 button">
                  Log In
                </Link>
                <Link
                  to="/apply-as-a-candidate"
                  className="f-a-qs-login2 button"
                >
                  Apply as a Talent
                </Link>
                <Link to="/build-your-team" className="f-a-qs-login3 button">
                  Hire Talent
                </Link>
              </div>
            </div>
          </div>
        </header>
      </nav>
      <div className="f-a-qs-accordion">
        <div className="f-a-qs-banner">
          <h1 className="f-a-qs-text06">
            <span className="f-a-qs-text07">F</span>
            <span>
              requently
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="f-a-qs-text09">A</span>
            <span>
              sked
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <span className="f-a-qs-text12">Q</span>
            <span>uestion</span>
            <span className="f-a-qs-text14">s</span>
          </h1>
          <span className="f-a-qs-text15">
            <span>
              Find answers to common questions about DecentraLoco&apos;s
            </span>
            <br></br>
            <span>terms of service or contact support.</span>
          </span>
        </div>
        <div className="f-a-qs-separator"></div>
        <div
          data-role="accordion-container"
          className="f-a-qs-element accordion-element"
        >
          <div className="f-a-qs-details">
            <span className="f-a-qs-text19">What is DecentraLoco</span>
            <span data-role="accordion-content" className="f-a-qs-text20">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </div>
          <div data-role="accordion-icon">
            <svg viewBox="0 0 1024 1024" className="f-a-qs-icon06">
              <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
            </svg>
          </div>
        </div>
        <div
          data-role="accordion-container"
          className="f-a-qs-element1 accordion-element"
        >
          <div className="f-a-qs-details1">
            <span className="f-a-qs-text23">
              What makes DecentraLoco special?
            </span>
            <span data-role="accordion-content" className="f-a-qs-text24">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </div>
          <div data-role="accordion-icon">
            <svg viewBox="0 0 1024 1024" className="f-a-qs-icon08">
              <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
            </svg>
          </div>
        </div>
        <div
          data-role="accordion-container"
          className="f-a-qs-element2 accordion-element"
        >
          <div className="f-a-qs-details2">
            <span className="f-a-qs-text27">
              How much does DecentraLoco cost?
            </span>
            <span data-role="accordion-content" className="f-a-qs-text28">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </div>
          <div data-role="accordion-icon">
            <svg viewBox="0 0 1024 1024" className="f-a-qs-icon10">
              <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
            </svg>
          </div>
        </div>
        <div
          data-role="accordion-container"
          className="f-a-qs-element3 accordion-element"
        >
          <div className="f-a-qs-details3">
            <span className="f-a-qs-text31">
              What can be done with DecentraLoco?
            </span>
            <span data-role="accordion-content" className="f-a-qs-text32">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </div>
          <div data-role="accordion-icon">
            <svg viewBox="0 0 1024 1024" className="f-a-qs-icon12">
              <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
            </svg>
          </div>
        </div>
        <div
          data-role="accordion-container"
          className="f-a-qs-element4 accordion-element"
        >
          <div className="f-a-qs-details4">
            <span className="f-a-qs-text35">
              <span>
                How do we get started with Gigster?
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <span data-role="accordion-content" className="f-a-qs-text38">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </div>
          <div data-role="accordion-icon">
            <svg viewBox="0 0 1024 1024" className="f-a-qs-icon14">
              <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
            </svg>
          </div>
        </div>
        <div
          data-role="accordion-container"
          className="f-a-qs-element5 accordion-element"
        >
          <div className="f-a-qs-details5">
            <span className="f-a-qs-text41">
              What is the minimum project size Gigster will work on?
            </span>
            <span data-role="accordion-content" className="f-a-qs-text42">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </div>
          <div data-role="accordion-icon">
            <svg viewBox="0 0 1024 1024" className="f-a-qs-icon16">
              <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
            </svg>
          </div>
        </div>
        <div
          data-role="accordion-container"
          className="f-a-qs-element6 accordion-element"
        >
          <div className="f-a-qs-details6">
            <span className="f-a-qs-text45">
              Amet, consectetur adipiscing elit mollit anim id est laborum.
            </span>
            <span data-role="accordion-content" className="f-a-qs-text46">
              <span>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
                Excepteur sint occaecat cupidatat non proident, sunt in culpa
                qui officia deserunt mollit anim id est laborum sint occaecat
                cupidatat non proident, sunt in culpa qui officia deserunt
                mollit anim id.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </div>
          <div data-role="accordion-icon">
            <svg viewBox="0 0 1024 1024" className="f-a-qs-icon18">
              <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
            </svg>
          </div>
        </div>
        <div
          data-role="accordion-container"
          className="f-a-qs-element7 accordion-element"
        >
          <div className="f-a-qs-details7">
            <span className="f-a-qs-text49">
              I cannot find the answer to my question!
            </span>
            <span data-role="accordion-content" className="f-a-qs-text50">
              <span>
                You can find more information and answers in our Help Center. If
                you&apos;ll still have any additional questions, contact us at
                support@remotehub.com and we&apos;ll get back to you right away.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </div>
          <div data-role="accordion-icon">
            <svg viewBox="0 0 1024 1024" className="f-a-qs-icon20">
              <path d="M366 708l196-196-196-196 60-60 256 256-256 256z"></path>
            </svg>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name2"></Footer>
    </div>
  );
};

export default FAQs;
