/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

class MM extends Component {
  // componentDidMount() {
  //   this.$el = this.el;
  //   this.mm = new MetisMenu(this.$el);
  // }
  // componentWillUnmount() {
  //   this.mm.dispose();
  //   console.log(this.mm);
  // }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");

    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    const { toggle, setToggle, handleLogout } = this.props;

    return (
      <div className="deznav" style={{ display: toggle ? "none" : "block" }}>
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <Link to="/decentradmin/dashboard" aria-expanded="false">
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Dashboard</span>
            </Link>
            <Link to="/decentradmin/candidates">
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Candidates</span>
            </Link>
            <Link to="/decentradmin/clients">
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Clients</span>
            </Link>
            <Link to="/decentradmin/jobs">
              <i className="flaticon-381-networking"></i>
              <span className="nav-text">Jobs</span>
            </Link>

            {/* <Link to="/decentradmin/settings">
                <i className="flaticon-381-networking"></i>
                <span className="nav-text">Settings</span>
              </Link> */}
          </MM>
          <div
            onClick={() => handleLogout()}
            className="logout"
            style={{ position: "fixed", bottom: "5vh", left: "90px" }}
          >
            <strong>Logout</strong>
          </div>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
