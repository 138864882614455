import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Spinner from "./Spinner";

import "./supportForm.css";

const regexEmailPattern =
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

const SupportForm = ({ home }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm({
    mode: "onTouched",
  });
  const [isSuccess, setIsSuccess] = useState(false);
  const [Message, setMessage] = useState("");

  const onSubmit = async (data, e) => {
    await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data, null, 2),
    })
      .then(async (response) => {
        let json = await response.json();
        if (json.success) {
          setIsSuccess(true);
          setMessage(json.message);
          e.target.reset();
          reset();
        } else {
          setIsSuccess(false);
          setMessage(json.message);
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setMessage("Client Error. Please check the console.log for more info");
        console.log(error);
      });
  };

  return (
    <div className="contact-us-container14">
      <div className="contact-us-container15">
        <div className="contact-us-container16">
          {home ? (
            <span className="home-text165">
              <span>
                We’re here to
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="home-text167">help</span>
            </span>
          ) : (
            <span className="contact-us-text19">We’re here to help</span>
          )}
        </div>
        {!isSubmitSuccessful && (
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <input
              type="hidden"
              value="9fd29174-7a4c-4eba-8cbf-b1dac0042cdf"
              {...register("access_key")}
            />
            <div className="contact-us-container17">
              <div className="contact-us-container18">
                <span className="contact-us-text20">
                  <span>Full Name</span>
                  <br></br>
                </span>
                <input
                  {...register("fullName", { required: true })}
                  type="text"
                  placeholder="Full name"
                  className={
                    "contact-us-textinput input " +
                    (errors.fullName ? "error-border" : "")
                  }
                />
                {errors.fullName && <p className="error-text">*Required</p>}
              </div>
              <div className="contact-us-container19">
                <span className="contact-us-text25">
                  <span>Email</span>
                  <br></br>
                </span>
                <input
                  {...register("email", {
                    required: true,
                    pattern: regexEmailPattern,
                  })}
                  type="text"
                  placeholder="Email"
                  className={
                    "contact-us-textinput1 teleport-show input " +
                    (errors.email ? "error-border" : "")
                  }
                />
                {errors.email && (
                  <p className="error-text">*Required, must be a valid email</p>
                )}
              </div>
            </div>
            <div className="contact-us-container20">
              <span className="contact-us-text30">
                <span>Message</span>
                <br></br>
              </span>
              <textarea
                {...register("message", {
                  required: true,
                  minLength: 24,
                })}
                type="text"
                placeholder="Message"
                className={
                  "contact-us-textinput2 teleport-show input " +
                  (errors.message ? "error-border" : "")
                }
                style={{ paddingTop: "15px" }}
              ></textarea>

              {errors.message && (
                <p className="error-text">
                  *Required, must have minimum 24 chars
                </p>
              )}
            </div>
            <button className={`${home ? `home` : `contact-us`}-button button`}>
              <span className="contact-us-text35">
                {isSubmitting ? <Spinner /> : "Next"}
              </span>
            </button>
          </form>
        )}
        {isSubmitSuccessful && isSuccess && (
          <>
            <div className="success-wrapper">
              <svg
                width="100"
                height="100"
                className="text-green-500"
                viewBox="0 0 100 100"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.6666 50L46.6666 66.6667L73.3333 33.3333M50 96.6667C43.8716 96.6667 37.8033 95.4596 32.1414 93.1144C26.4796 90.7692 21.3351 87.3317 17.0017 82.9983C12.6683 78.6649 9.23082 73.5204 6.8856 67.8586C4.54038 62.1967 3.33331 56.1283 3.33331 50C3.33331 43.8716 4.54038 37.8033 6.8856 32.1414C9.23082 26.4796 12.6683 21.3351 17.0017 17.0017C21.3351 12.6683 26.4796 9.23084 32.1414 6.88562C37.8033 4.5404 43.8716 3.33333 50 3.33333C62.3767 3.33333 74.2466 8.24998 82.9983 17.0017C91.75 25.7534 96.6666 37.6232 96.6666 50C96.6666 62.3768 91.75 74.2466 82.9983 82.9983C74.2466 91.75 62.3767 96.6667 50 96.6667Z"
                  stroke="currentColor"
                  strokeWidth="3"
                />
              </svg>
              <h3 className="success-title text-green-500">Success</h3>
              <p className="text-green-500">{Message}</p>
              <button
                style={{
                  color: "#fff",
                  cursor: "pointer",
                  fontFamily: "Space Grotesk",
                  fontSize: "14px",
                  paddingTop: "10px",
                  textDecoration: "underline",
                }}
                onClick={() => reset()}
              >
                Go back
              </button>
            </div>
          </>
        )}
        {isSubmitSuccessful && !isSuccess && (
          <div className="error-wrapper">
            <svg
              width="97"
              height="97"
              viewBox="0 0 97 97"
              className="text-danger-500"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.9995 69C43.6205 53.379 52.3786 44.621 67.9995 29M26.8077 29L67.9995 69M48.2189 95C42.0906 95 36.0222 93.7929 30.3604 91.4477C24.6985 89.1025 19.554 85.6651 15.2206 81.3316C10.8872 76.9982 7.44975 71.8538 5.10454 66.1919C2.75932 60.53 1.55225 54.4617 1.55225 48.3333C1.55225 42.205 2.75932 36.1366 5.10454 30.4748C7.44975 24.8129 10.8872 19.6684 15.2206 15.335C19.554 11.0016 24.6985 7.56418 30.3604 5.21896C36.0222 2.87374 42.0906 1.66667 48.2189 1.66667C60.5957 1.66667 72.4655 6.58333 81.2172 15.335C89.9689 24.0867 94.8856 35.9566 94.8856 48.3333C94.8856 60.7101 89.9689 72.58 81.2172 81.3316C72.4655 90.0833 60.5957 95 48.2189 95Z"
                stroke="CurrentColor"
                strokeWidth="3"
              />
            </svg>

            <h3 className="error-title text-danger-500">
              Oops, Something went wrong!
            </h3>
            <p className="text-danger-500">{Message}</p>
            <button
              style={{
                color: "#fff",
                cursor: "pointer",
                marginTop: "10px",
                textDecoration: "underline",
              }}
              onClick={() => reset()}
            >
              Try Again
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SupportForm;
