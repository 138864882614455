import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./change-password.css";

const ChangePassword = (props) => {
  return (
    <div className="change-password-container">
      <Helmet>
        <title>Change Password Decentraloco</title>
        <meta property="og:title" content="Change Password Decentraloco" />
      </Helmet>
      <NavbarLogIn rootClassName="navbar-log-in-root-class-name14"></NavbarLogIn>
      <div className="change-password-hero">
        <div className="change-password-container1">
          <h1 className="change-password-text">Create your new password</h1>
          <div className="change-password-container2">
            <span className="change-password-text01">
              <span>Create a new password</span>
              <br></br>
            </span>
          </div>
          <div className="change-password-container3">
            <span className="change-password-text04">New password</span>
            <input
              type="text"
              placeholder="New password"
              className="change-password-textinput teleport-show input"
            />
          </div>
          <div className="change-password-container4">
            <span className="change-password-text05">
              <span>Confirm password</span>
              <br className="change-password-text07"></br>
            </span>
            <input
              type="text"
              placeholder="Confirm password"
              className="change-password-textinput1 teleport-show input"
            />
          </div>
          <div className="change-password-btn-group">
            <Link to="/log-in" className="change-password-navlink button">
              <span className="change-password-text08">Continue</span>
            </Link>
          </div>
          <div className="change-password-container5">
            <span className="change-password-text09">Go back to </span>
            <Link to="/log-in" className="change-password-navlink1">
              Log In
            </Link>
          </div>
        </div>
        <img
          src="/playground_assets/art-work%20011-1500h.png"
          loading="lazy"
          className="change-password-image"
        />
      </div>
      <FooterV2></FooterV2>
    </div>
  );
};

export default ChangePassword;
