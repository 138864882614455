import React from "react";

import PropTypes from "prop-types";

import "./ourvalues.css";

const OurValues = (props) => {
  return (
    <div className="ourvalues-container">
      <header className="ourvalues-header">
        <h2 className="ourvalues-heading">
          <span className="ourvalues-text">
            Our
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="ourvalues-text01">Values</span>
          <br></br>
        </h2>
      </header>
      <main className="ourvalues-cards">
        <section className="ourvalues-card">
          <main className="ourvalues-content">
            <h1 className="ourvalues-header1">Build</h1>
            <div className="ourvalues-container02"></div>
            <p className="ourvalues-description">
              <span>We build pipelines, teams, and relationships. </span>
              <span></span>
            </p>
          </main>
        </section>
        <section className="ourvalues-card">
          <main className="ourvalues-content1">
            <h1 className="ourvalues-header2">
              Provide
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </h1>
            <div className="ourvalues-container03"></div>
            <p className="ourvalues-description1">
              <span>We provide data-driven insights, not just fill jobs. </span>
              <span></span>
            </p>
          </main>
        </section>
        <section className="ourvalues-card">
          <main className="ourvalues-content2">
            <h1 className="ourvalues-header3">Scale</h1>
            <div className="ourvalues-container05"></div>
            <p className="ourvalues-description2">
              <span>We scale remote, hybrid, and on-site teams, globally.</span>
              <span></span>
            </p>
          </main>
        </section>
        <section className="ourvalues-card">
          <main className="ourvalues-content3">
            <h1 className="ourvalues-header4">
              Craft
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </h1>
            <div className="ourvalues-container06"></div>
            <p className="ourvalues-description3">
              <span>
                We craft solutions to speed, quality, and cost in the hiring
                process. 
              </span>
              <br className="ourvalues-text10"></br>
              <br></br>
            </p>
          </main>
        </section>
        <section className="ourvalues-card">
          <main className="ourvalues-content3">
            <h1 className="ourvalues-header4">
              Onboard
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </h1>
            <div className="ourvalues-container06"></div>
            <p className="ourvalues-description3">
              <span>We onboard niche talent into our ecosystem 24/7.</span>
              <br className="ourvalues-text10"></br>
              <br></br>
            </p>
          </main>
        </section>
        <section className="ourvalues-card">
          <main className="ourvalues-content3">
            <h1 className="ourvalues-header4">
              Disrupt
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </h1>
            <div className="ourvalues-container06"></div>
            <p className="ourvalues-description3">
              <span>
                Because we disrupt outdated recruitment processes and
                methodologies.
              </span>
              <br className="ourvalues-text10"></br>
              <br></br>
            </p>
          </main>
        </section>
      </main>
    </div>
  );
};

OurValues.defaultProps = {
  Header3: "Upload your resume",
  image_src32: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt11: "image",
  image_alt: "image",
  image_src4: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  Header2: "01",
  Header1: "Register account",
  Description2:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
  image_src: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  Description1:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
  image_alt12: "image",
  image_src2: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  image_src1: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt22: "image",
  Header5: "Apply for job",
  image_alt32: "image",
  Header21: "02",
  image_alt31: "image",
  image_src21: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  Header: "01",
  image_src11: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt5: "image",
  Description: "Register to join DecentraLoco Talent Network",
  Header31: "Upload your resume",
  image_src5: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  image_alt21: "image",
  image_alt1: "image",
  image_src22: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  image_src12: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_src3: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt4: "image",
  image_alt2: "image",
  Header4: "03",
  image_alt3: "image",
  image_src31: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  Description11:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
  text: "How to get started with",
  text1: "DecentraLoco",
};

OurValues.propTypes = {
  Header3: PropTypes.string,
  image_src32: PropTypes.string,
  image_alt11: PropTypes.string,
  image_alt: PropTypes.string,
  image_src4: PropTypes.string,
  Header2: PropTypes.string,
  Header1: PropTypes.string,
  Description2: PropTypes.string,
  image_src: PropTypes.string,
  Description1: PropTypes.string,
  image_alt12: PropTypes.string,
  image_src2: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt22: PropTypes.string,
  Header5: PropTypes.string,
  image_alt32: PropTypes.string,
  Header21: PropTypes.string,
  image_alt31: PropTypes.string,
  image_src21: PropTypes.string,
  Header: PropTypes.string,
  image_src11: PropTypes.string,
  image_alt5: PropTypes.string,
  Description: PropTypes.string,
  Header31: PropTypes.string,
  image_src5: PropTypes.string,
  image_alt21: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src22: PropTypes.string,
  image_src12: PropTypes.string,
  image_src3: PropTypes.string,
  image_alt4: PropTypes.string,
  image_alt2: PropTypes.string,
  Header4: PropTypes.string,
  image_alt3: PropTypes.string,
  image_src31: PropTypes.string,
  Description11: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
};

export default OurValues;
