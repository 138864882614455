import React from "react";
import { Navigate } from "react-router-dom";
import { useUserState } from "./stateContext";

const CandidateProtectedRoute = ({ children }) => {
  const { user, setUser } = useUserState();
  // if (user?.email && user?.userUID && user?.userType === "client") {
  //   return <Navigate to="/matched-talents" />;
  // }
  if (
    user?.bio &&
    user?.email &&
    user?.userUID &&
    user?.userType === "candidate"
  ) {
    return <Navigate to="/apply-as-a-candidate" />;
  }
  return children;
};

export default CandidateProtectedRoute;
