import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";

import "./courses.css";
import { useUserState } from "../../utils/stateContext";
import { signOut } from "firebase/auth";
import { auth } from "../../database/firebase";

const Courses = (props) => {
  const navigate = useNavigate();
  const { user, setUser } = useUserState();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    signOut(auth)
      .then(navigate("/"))
      .catch((error) => console.log(error));
  };
  return (
    <div className="courses-container">
      <Helmet>
        <title>Resources Decentraloco</title>
        <meta property="og:title" content="Resources Decentraloco" />
      </Helmet>
      <nav className="courses-navbar">
        <header data-thq="thq-navbar" className="courses-navbar-interactive">
          <Link to="/" className="courses-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="courses-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="courses-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="courses-nav"
            >
              <Link
                to="/apply-as-a-candidate"
                className="courses-navlink1 button"
              >
                Candidate&apos;s Home
              </Link>
              <Link to="/jobs" className="courses-navlink2 button">
                Jobs
              </Link>
              <span className="courses-text button">Resources</span>
            </nav>
          </div>
          <div data-thq="thq-navbar-btn-group" className="courses-btn-group">
            <Link to="/apply-as-a-candidate" className="courses-login button">
              Apply as a Talent
            </Link>
            {user ? (
              <Link
                className="courses-register button"
                onClick={() => setShow(!show)}
              >
                {`${user?.firstName} ${user?.lastName}`}
              </Link>
            ) : (
              <Link to="/log-in" className="courses-register button">
                Log In
              </Link>
            )}
            {show && (
              <div
                onClick={() => handleLogout()}
                style={{
                  backgroundColor: "#1E1C24",
                  borderRadius: "8px",
                  color: "#fff",
                  cursor: "pointer",
                  fontFamily: "Space Grotesk",
                  marginTop: "1px",
                  padding: "15px 10px",
                  position: "absolute",
                  right: 0,
                  textAlign: "center",
                  top: "48px",
                  width: "125px",
                }}
              >
                <span>Log out</span>
              </div>
            )}
          </div>
          <div data-thq="thq-burger-menu" className="courses-burger-menu">
            <svg viewBox="0 0 1024 1024" className="courses-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="courses-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="courses-nav1"
            >
              <div className="courses-container1">
                <Link to="/" className="courses-navlink3">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="courses-finbest1"
                  />
                </Link>
                <div data-thq="thq-close-menu" className="courses-menu-close">
                  <svg viewBox="0 0 1024 1024" className="courses-icon2">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="courses-nav2"
              >
                <Link to="/apply-as-a-candidate" className="courses-navlink4">
                  Candidate&apos;s Home
                </Link>
                <Link to="/jobs" className="courses-navlink5">
                  Jobs
                </Link>
                <Link to="/courses" className="courses-navlink6">
                  Resources
                </Link>
              </nav>
              <div className="courses-container2">
                <Link
                  to="/apply-as-a-candidate"
                  className="courses-login1 button"
                >
                  Apply as a Talent
                </Link>
                <Link to="/build-your-team" className="courses-login2 button">
                  Hire Talent
                </Link>
                <Link to="/log-in" className="courses-register1 button">
                  Log In
                </Link>
              </div>
            </div>
          </div>
        </header>
      </nav>
      <div className="courses-hero">
        <div className="courses-container3">
          <h1 className="courses-text07">We are</h1>
          <h1 className="courses-text08">Coming soon !</h1>
          <div className="courses-container4"></div>
          <h2 className="courses-text09">
            <span>We are going to launch this feature very soon !</span>
            <br></br>
            <span>Stay tuned</span>
            <br></br>
          </h2>
          <div className="courses-btn-group1">
            <Link
              to="/apply-as-a-candidate"
              className="courses-navlink7 button"
            >
              <span className="courses-text14">Back to Home</span>
            </Link>
          </div>
        </div>
        <img
          alt="image"
          src="/playground_assets/hand%20coding-cuate%201.svg"
          className="courses-image1"
        />
      </div>
    </div>
  );
};

export default Courses;
