import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  query,
  orderBy,
  limit,
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { Helmet } from "react-helmet";

import JobsBoard from "../../components/jobs-board";
import Footer from "../../components/footer";
import "./apply-as-a-candidate.css";
import { useUserState } from "../../utils/stateContext";
import { signOut } from "firebase/auth";
import { auth, db } from "../../database/firebase";
import LoginUserComponent from "../../components/LoginUserComponent";

import Modal from "react-modal";
import JobDescriptionComponent from "../jobs/job-description-component";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
SwiperCore.use([Navigation]);

// import required modules
import SwiperCore, { Autoplay, Navigation } from "swiper";
import useWindowSize from "../../utils/GetWindowSize";
import Companys from "../../components/companys";

const customStyles = {
  overlay: {
    background: "rgba(0,0,0,0.1)",
  },
  content: {
    background: "rgba(255, 255, 255, 0)",
    border: 0,
    minHeight: "102vh",
    height: "100%",
    left: "0%",
    overflowX: "hidden",
    overflowY: "scroll",
    top: "-25px",
    // transform: "translate(50%, 50%)",
    width: "100%",
    zIndex: 99999,
  },
};

const ApplyAsACandidate = (props) => {
  const navigate = useNavigate();
  const { user, setUser } = useUserState();
  const [show, setShow] = useState(false);

  const [jobsList, setJobsList] = useState([]);
  const [currentJob, setCurrentJob] = useState(null);
  const [toggleReadJob, setToggleReadJob] = useState(false);
  const [refreshJobs, setRefreshJobs] = useState(null);
  const partnersSliderRef = useRef(null);
  const size = useWindowSize();

  useEffect(() => {
    async function fetchJobs() {
      const q = query(
        collection(db, "jobs"),
        orderBy("updatedAt", "desc"),
        limit(6)
      );
      const snapShot = await getDocs(q);
      const jobsData = snapShot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setJobsList(jobsData.reverse().slice(0, 6));
    }
    fetchJobs();
  }, [refreshJobs]);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        navigate("/");
      })
      .catch((error) => console.log(error));
  };

  // const openPreview = (job) => {
  //   setCurrentJob(job);
  //   setToggleReadJob(true);
  // }

  // const closePreview = () => {
  //   setToggleReadJob(false);
  //   setCurrentJob(null)
  // }

  const openPreview = (job) => {
    setCurrentJob(job);
    setToggleReadJob(true);
    const mobileMenu = document.querySelector("#mobile-menu");
    mobileMenu.style.transform = `translateX(${
      window.innerWidth >= 984 ? "20" : "0"
    }%)`;
    const overlay = document.createElement("div");
    overlay.id = "overlayBlured";
    document.querySelector("#app").appendChild(overlay);
    document.querySelector("#overlayBlured").style.display = "block";
  };

  const closePreview = () => {
    setToggleReadJob(false);
    setCurrentJob(null);
    const mobileMenu = document.querySelector("#mobile-menu");
    mobileMenu.style.transform = "translateX(100%)";
    const overlay = document.querySelector("#overlayBlured");
    overlay.style.display = "none";
    overlay.remove();
  };

  const applyToJob = async (job) => {
    if (job?.id && user?.userUID && user?.userType === "candidate") {
      const jobApplyRef = doc(db, "jobs", job.id);
      const jobDoc = await getDoc(jobApplyRef);
      const jobData = jobDoc.data();
      if (!jobData.applies) {
        jobData.applies = [];
      }
      if (
        !jobData?.applies?.map((apl) => apl.user_uid).includes(user?.userUID)
      ) {
        jobData.applies.push({
          user_uid: user?.userUID,
          created_at: Date.now(),
        });
      }
      await setDoc(jobApplyRef, { ...jobData });
      setRefreshJobs((refreshJobs || 0) + 1);
      setCurrentJob(jobData);
    } else {
      navigate("/candidate-step-0");
    }
  };

  return (
    <div className="apply-as-a-candidate-container">
      <Helmet>
        <title>Candidate's Home Decentraloco</title>
        <meta property="og:title" content="Candidate's Home Decentraloco" />
      </Helmet>
      <nav className="apply-as-a-candidate-navbar">
        <header
          data-thq="thq-navbar"
          className="apply-as-a-candidate-navbar-interactive"
        >
          <Link to="/" className="apply-as-a-candidate-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="apply-as-a-candidate-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="apply-as-a-candidate-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="apply-as-a-candidate-nav"
            >
              <Link
                to="/apply-as-a-candidate"
                className="apply-as-a-candidate-navlink01 button"
              >
                Candidate&apos;s Home
              </Link>
              <Link
                to="/jobs"
                className="apply-as-a-candidate-navlink02 button"
              >
                Jobs
              </Link>
              <Link
                to="/courses"
                className="apply-as-a-candidate-navlink03 button"
              >
                Resources
              </Link>
            </nav>
          </div>
          <div
            data-thq="thq-navbar-btn-group"
            className="apply-as-a-candidate-btn-group"
          >
            <Link
              to="/candidate-step-0"
              className="apply-as-a-candidate-login button"
            >
              Join Talent Network
            </Link>

            <LoginUserComponent
              user={user}
              handleLogout={handleLogout}
              show={show}
              setShow={setShow}
            ></LoginUserComponent>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="apply-as-a-candidate-burger-menu"
          >
            <svg viewBox="0 0 1024 1024" className="apply-as-a-candidate-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="apply-as-a-candidate-mobile-menu"
          >
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="apply-as-a-candidate-nav1"
            >
              <div className="apply-as-a-candidate-container01">
                <Link to="/" className="apply-as-a-candidate-navlink04">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="apply-as-a-candidate-finbest1"
                  />
                </Link>
                <div
                  data-thq="thq-close-menu"
                  className="apply-as-a-candidate-menu-close"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="apply-as-a-candidate-icon2"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="apply-as-a-candidate-nav2"
              >
                <Link to="/" className="apply-as-a-candidate-navlink05">
                  Candidate&apos;s Home
                </Link>
                <Link to="/jobs" className="apply-as-a-candidate-navlink06">
                  Jobs
                </Link>
                <Link to="/courses" className="apply-as-a-candidate-navlink07">
                  Resources
                </Link>
              </nav>
              <div className="apply-as-a-candidate-container02">
                <Link
                  to="/apply-as-a-candidate"
                  className="apply-as-a-candidate-login1 button"
                >
                  Join Talent Network
                </Link>

                <LoginUserComponent
                  user={user}
                  handleLogout={handleLogout}
                  show={show}
                  setShow={setShow}
                ></LoginUserComponent>
              </div>
            </div>
          </div>
        </header>
        <div id="mobile-menu" className="jobs-mobile">
          <JobDescriptionComponent
            closePreview={closePreview}
            job={currentJob}
            user={user}
            applyToJob={applyToJob}
          ></JobDescriptionComponent>
        </div>
      </nav>
      <div className="apply-as-a-candidate-a-s-t">
        <div className="apply-as-a-candidate-container03">
          <h2 className="apply-as-a-candidate-text06">For Talent</h2>
          <h2 className="apply-as-a-candidate-text07">
            <span>
              Privately apply to Top-tech companies & startups with
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br className="apply-as-a-candidate-text09"></br>
            <span className="apply-as-a-candidate-text10">one</span>
            <span className="apply-as-a-candidate-text11">
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="apply-as-a-candidate-text12">profile.</span>
            {/* <br />
            <span>
              It all starts by uploading your resume.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span> */}
          </h2>
          <span className="apply-as-a-candidate-text13">
            <span>
              Apply for Blockchain, Gaming, & AI jobs today and, we’ll match you
              to all future projects.
              <br />
              It all starts by uploading your resume.
            </span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </span>
          <div className="apply-as-a-candidate-btn-group1">
            <Link
              to="/candidate-step-0"
              className="apply-as-a-candidate-navlink08"
            >
              <div className="apply-as-a-candidate-sign-up-btn">
                <span className="apply-as-a-candidate-sign-up">
                  Apply to Jobs
                </span>
              </div>
            </Link>
          </div>
          <div className="apply-as-a-candidate-container04">
            <span className="apply-as-a-candidate-text18">
              Looking to hire instead?  
            </span>
            <Link
              to="/build-your-team"
              className="apply-as-a-candidate-navlink09"
            >
              Hire Talent
            </Link>
          </div>
          <div className="apply-as-a-candidate-container05">
            <span className="apply-as-a-candidate-text19">We accept: </span>
            <div className="apply-as-a-candidate-container06">
              <img
                alt="image"
                src="/playground_assets/btc.svg"
                className="apply-as-a-candidate-image01"
              />
              <img
                alt="image"
                src="/playground_assets/eth.svg"
                className="apply-as-a-candidate-image02"
              />
              <img
                alt="image"
                src="/playground_assets/metamask-fox.svg"
                className="apply-as-a-candidate-image03"
              />
              <img
                alt="image"
                src="/playground_assets/bnb.svg"
                className="apply-as-a-candidate-image04"
              />
              <img
                alt="image"
                src="/playground_assets/tether-usdt-logo.png"
                className="apply-as-a-candidate-image05"
                style={{ backgroundColor: "#408C77" }}
              />
            </div>
            <span className="apply-as-a-candidate-text20">...</span>
          </div>
        </div>
        <img
          src="/playground_assets/art-work%2001-1500h.png"
          loading="lazy"
          className="apply-as-a-candidate-image06"
        />
      </div>
      {/* <Companys /> */}
      <section className="apply-as-a-candidate-section">
        <header className="apply-as-a-candidate-header">
          <header className="apply-as-a-candidate-left">
            <h2 className="apply-as-a-candidate-heading section-heading">
              <span>
                Welcome to our Top-tech
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br />
              <span className="apply-as-a-candidate-text22">
                Talent Network
              </span>
            </h2>
          </header>
          <div className="apply-as-a-candidate-right">
            <p className="apply-as-a-candidate-paragraph section-description">
              Say goodbye to boring job searches and hello to exciting career
              opportunities with top startups and tech companies - your profile
              is all you need.
            </p>
          </div>
        </header>
        <main className="apply-as-a-candidate-cards">
          <section className="apply-as-a-candidate-card">
            <img
              alt="image"
              src="/playground_assets/profile-400h.png"
              className="apply-as-a-candidate-image07"
            />
            <main className="apply-as-a-candidate-content">
              <h1 className="apply-as-a-candidate-header01">
                Candidate profile
              </h1>
              <p className="apply-as-a-candidate-description">
                One profile is all you need, One  free account gives you access
                to top job opportunities.
              </p>
            </main>
          </section>
          <section className="apply-as-a-candidate-card1">
            <img
              alt="image"
              src="/playground_assets/community-400h.png"
              className="apply-as-a-candidate-image08"
            />
            <main className="apply-as-a-candidate-content1">
              <h1 className="apply-as-a-candidate-header02">Our Community</h1>
              <p className="apply-as-a-candidate-description1">
                Join a supportive community of experienced individuals and
                advance your professional journey
              </p>
            </main>
          </section>
          <section className="apply-as-a-candidate-card2">
            <img
              alt="image"
              src="/playground_assets/career%20growth-400h.png"
              className="apply-as-a-candidate-image09"
            />
            <main className="apply-as-a-candidate-content2">
              <h1 className="apply-as-a-candidate-header03">Career Growth</h1>
              <p className="apply-as-a-candidate-description2">
                Grow rapidly by working on challenging technical and business
                problems on the latest technologies.
              </p>
            </main>
          </section>
        </main>
      </section>
      <div className="apply-as-a-candidate-separator"></div>
      <section className="apply-as-a-candidate-section1">
        <header className="apply-as-a-candidate-header04">
          <header className="apply-as-a-candidate-left1">
            <h2 className="apply-as-a-candidate-heading1 section-heading">
              <span>How to get started with</span>
              <br></br>
              <span className="apply-as-a-candidate-text30">DecentraLoco</span>
              <br></br>
            </h2>
          </header>
        </header>
        <main className="apply-as-a-candidate-cards1">
          <section className="apply-as-a-candidate-card3">
            <main className="apply-as-a-candidate-content3">
              <h1 className="apply-as-a-candidate-header05">01</h1>
              <div className="apply-as-a-candidate-container07"></div>
              <h1 className="apply-as-a-candidate-header06">
                Upload your resume
              </h1>
              <p className="apply-as-a-candidate-description3">
                Before uploading your resume, please make sure that all details
                are up to date and correct
              </p>
            </main>
          </section>
          <img
            alt="image"
            src="/playground_assets/group%2033635-200h.png"
            className="apply-as-a-candidate-image10"
          />
          <img
            alt="image"
            src="/playground_assets/group%2033634-200w.png"
            className="apply-as-a-candidate-image11"
          />
          <section className="apply-as-a-candidate-card4">
            <main className="apply-as-a-candidate-content4">
              <h1 className="apply-as-a-candidate-header07">02</h1>
              <div className="apply-as-a-candidate-container08"></div>
              <h1 className="apply-as-a-candidate-header08">Build Profile</h1>
              <p className="apply-as-a-candidate-description4">
                Join our Talent Network and get matched to all future projects.
              </p>
              <br />
            </main>
          </section>
          <img
            alt="image"
            src="/playground_assets/group%2033635-200h.png"
            className="apply-as-a-candidate-image12"
          />
          <img
            alt="image"
            src="/playground_assets/group%2033634-200w.png"
            className="apply-as-a-candidate-image13"
          />
          <section className="apply-as-a-candidate-card5">
            <main className="apply-as-a-candidate-content5">
              <h1 className="apply-as-a-candidate-header09">03</h1>
              <div className="apply-as-a-candidate-container09"></div>
              <h1 className="apply-as-a-candidate-header10">Apply for jobs</h1>
              <p className="apply-as-a-candidate-description5">
                Please read descriptions fully before applying to make sure it
                is a good fit
              </p>
            </main>
          </section>
        </main>
      </section>
      <div className="apply-as-a-candidate-separator1"></div>

      {jobsList?.length > 0 && (
        <JobsBoard
          jobsList={jobsList}
          applyToJob={applyToJob}
          user={user}
          openPreview={openPreview}
        ></JobsBoard>
      )}

      {/* 
      <Modal
        isOpen={toggleReadJob}
        // onAfterOpen={afterOpenModal}
        ariaHideApp={false}
        onRequestClose={() => closePreview() }
        style={customStyles}
        contentLabel="Job Preview"
        key={refreshJobs}
      >
        <JobDescriptionComponent 
          closePreview={closePreview} 
          job={currentJob} 
          user={user} 
          applyToJob={applyToJob}
        ></JobDescriptionComponent>
      </Modal>
 */}

      <Footer rootClassName="footer-root-class-name1"></Footer>
    </div>
  );
};

export default ApplyAsACandidate;
