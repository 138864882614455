import React, { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-modal";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./forgot-password.css";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../database/firebase";
import { useForm } from "react-hook-form";
import EmailVerification from "../../components/email-verification";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 0.1)",
  },
};

const ForgotPassword = (props) => {
  // If email not exist in our database set and display the error
  const [error, setError] = useState(false);
  const [sentEmail, setSentEmail] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm();

  function closeModal() {
    setIsOpen(false);
  }
  const sendEmail = ({ email }) => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSentEmail(true);
        setIsOpen(true);
      })
      .catch((error) => setError(true));
  };
  return (
    <div className="forgot-password-container">
      <Helmet>
        <title>Forgot Password Decentraloco</title>
        <meta property="og:title" content="Forgot Password Decentraloco" />
      </Helmet>
      <NavbarLogIn rootClassName="navbar-log-in-root-class-name13"></NavbarLogIn>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Forgot Password"
      >
        <EmailVerification closeModal={closeModal} />
      </Modal>
      <div className="forgot-password-hero">
        <div className="forgot-password-container1">
          <h1 className="forgot-password-text">Forgot your password ?</h1>
          <div className="forgot-password-container2">
            <span className="forgot-password-text01">
              <span>
                Enter your email address and we&apos;ll send you a link to
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <br></br>
              <span>reset your password.</span>
              <br></br>
            </span>
          </div>
          <div className="forgot-password-container3">
            {sentEmail && (
              <p
                style={{
                  color: "green",
                  fontFamily: "Space Grotesk",
                  marginLeft: "8px",
                }}
              >
                An email with verification code was sent.
              </p>
            )}
            <span className="forgot-password-text06">
              <span>Email address</span>
              <span className="forgot-password-text08"></span>
            </span>
            <input
              {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
              type="email"
              placeholder="you@example.com"
              className={
                "forgot-password-textinput teleport-show input " +
                (errors.email ? "error-border" : "")
              }
            />
            {errors.email && (
              <p className="error-text">*Required, must be a valid email</p>
            )}
            {error && (
              <p className="error-text">
                Email address not found in our database
              </p>
            )}
          </div>
          <div className="forgot-password-btn-group">
            <Link
              onClick={handleSubmit(sendEmail)}
              className="forgot-password-navlink button"
            >
              <span className="forgot-password-text09">Reset Password</span>
            </Link>
          </div>
          <div className="forgot-password-container4">
            <span className="forgot-password-text10">Go back to </span>
            <Link to="/log-in" className="forgot-password-navlink1">
              Log In
            </Link>
          </div>
        </div>
        <img
          src="/playground_assets/art-work%2001-1500h.png"
          loading="lazy"
          className="forgot-password-image"
        />
      </div>
      <FooterV2></FooterV2>
    </div>
  );
};

export default ForgotPassword;
