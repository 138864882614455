import React from "react";

import PropTypes from "prop-types";

import "./email-verification.css";

const EmailVerification = (props) => {
  const { closeModal } = props;
  return (
    <div className="email-verification-container">
      <div className="email-verification-container1">
        <div className="email-verification-container2">
          <h1 className="email-verification-text">Check your email</h1>
          <span className="email-verification-text1">
            We&apos;ve sent a confirmation email to
          </span>
          <span className="email-verification-text2">the email</span>
        </div>
        <div className="email-verification-container3">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="email-verification-image"
          />
          <div className="email-verification-container4">
            <input type="text" className="email-verification-textinput input" />
            <input
              type="text"
              className="email-verification-textinput1 input"
            />
            <input
              type="text"
              className="email-verification-textinput2 input"
            />
            <input
              type="text"
              className="email-verification-textinput3 input"
            />
          </div>
          <div className="email-verification-container5">
            <span className="email-verification-text3">
              Didn&apos;t receive Email? 
            </span>
            <span className="email-verification-text4 button">
              Resend Code!
            </span>
          </div>
          <button
            className="email-verification-button button"
            onClick={() => closeModal()}
          >
            Edit email address
          </button>
        </div>
      </div>
    </div>
  );
};

EmailVerification.defaultProps = {
  button: "Button",
  text1: "Text",
  textinput_placeholder: "placeholder",
  heading: "Heading",
  text: "Text",
  image_alt: "image",
  image_src: "/playground_assets/email%20verfication-200h.png",
};

EmailVerification.propTypes = {
  button: PropTypes.string,
  text1: PropTypes.string,
  textinput_placeholder: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  image_alt: PropTypes.string,
  image_src: PropTypes.string,
};

export default EmailVerification;
