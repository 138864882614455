import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import "./next-steps.css";
import { useUserState } from "../../utils/stateContext";
import { signOut } from "firebase/auth";
import { auth } from "../../database/firebase";
import SupportForm from "../../components/SupportForm";
import OurValues from "../../components/OurValues";
import OurNextSteps from "../../components/OurNextSteps";

const NextSteps = (props) => {
  const { user, setUser } = useUserState();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        setShow(false);
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="next-steps-client-container">
      <Helmet>
        <title>Next Steps Decentraloco</title>
        <meta property="og:title" content="Next Steps Decentraloco" />
      </Helmet>
      <nav className="next-steps-client-navbar">
        <header
          data-thq="thq-navbar"
          className="next-steps-client-navbar-interactive"
        >
          <Link to="/" className="next-steps-client-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="next-steps-client-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="next-steps-client-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="next-steps-client-nav"
            >
              <Link
                to="/build-your-team"
                className="next-steps-client-navlink1 button"
              >
                Client&apos;s Home
              </Link>
              <Link
                to="/contact-us-client"
                className="next-steps-client-navlink3 button"
              >
                Values
              </Link>
              <Link
                to="/our-process"
                className="next-steps-client-navlink4 button"
              >
                Our Process
              </Link>
              <Link to="/pricing" className="next-steps-client-navlink3 button">
                Pricing
              </Link>
              <Link
                to="/next-steps"
                className="next-steps-client-navlink2 button"
              >
                Next Steps
              </Link>
            </nav>
          </div>
          <div
            data-thq="thq-navbar-btn-group"
            className="next-steps-client-btn-group"
          >
            <Link
              to="/build-your-team"
              className="next-steps-client-login button"
            >
              Hire Talent
            </Link>
            {user ? (
              <Link
                className="build-your-team-register button"
                onClick={() => setShow(!show)}
              >
                {`${user.firstName} ${user.lastName}`}
              </Link>
            ) : (
              <Link to="/log-in" className="build-your-team-register button">
                Log In
              </Link>
            )}
            {show && (
              <div
                onClick={() => handleLogout()}
                style={{
                  backgroundColor: "#1E1C24",
                  borderRadius: "8px",
                  color: "#fff",
                  cursor: "pointer",
                  fontFamily: "Space Grotesk",
                  marginTop: "1px",
                  padding: "15px 10px",
                  position: "absolute",
                  right: 0,
                  textAlign: "center",
                  top: "48px",
                  width: "125px",
                }}
              >
                <span>Log out</span>
              </div>
            )}
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="next-steps-client-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="next-steps-client-mobile-menu"
          >
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="next-steps-client-nav1"
            >
              <div className="next-steps-client-container01">
                <Link to="/" className="next-steps-client-navlink4">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="next-steps-client-finbest1"
                  />
                </Link>
                <div
                  data-thq="thq-close-menu"
                  className="next-steps-client-menu-close"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="next-steps-client-icon02"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="next-steps-client-nav2"
              >
                <Link
                  to="/build-your-team"
                  className="next-steps-client-navlink5"
                >
                  Client&apos;s Home
                </Link>
                <Link
                  to="/contact-us-client"
                  className="next-steps-client-navlink31"
                >
                  Values
                </Link>
                <Link to="/our-process" className="build-your-team-navlink6">
                  Our Process
                </Link>
                <Link to="/pricing" className="next-steps-client-navlink7">
                  Pricing
                </Link>
                <Link
                  to="/next-steps"
                  className="next-steps-client-navlink21 button"
                >
                  Next Steps
                </Link>
              </nav>
              <div className="next-steps-client-container02">
                {user ? (
                  <Link
                    className="home-register button"
                    onClick={() => setShow(!show)}
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </Link>
                ) : (
                  <Link to="/log-in" className="home-register button">
                    Log In
                  </Link>
                )}
                {show && (
                  <div
                    onClick={() => handleLogout()}
                    style={{
                      backgroundColor: "#1E1C24",
                      borderRadius: "8px",
                      color: "#fff",
                      cursor: "pointer",
                      fontFamily: "Space Grotesk",
                      marginTop: "1px",
                      padding: "15px 10px",
                      position: "absolute",
                      right: 0,
                      textAlign: "center",
                      top: "48px",
                      width: "125px",
                    }}
                  >
                    <span>Log out</span>
                  </div>
                )}
                <Link
                  to="/build-your-team"
                  className="next-steps-client-login1 button"
                >
                  Hire Talent
                </Link>
              </div>
            </div>
          </div>
        </header>
      </nav>
      <OurNextSteps />
      <div className="next-steps-client-separator"></div>
      <SupportForm />
      <Footer></Footer>
    </div>
  );
};

export default NextSteps;
