import { doc, getDoc, getDocs, collection } from "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";
import { auth, db } from "../database/firebase";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import { Navigate, Route } from "react-router-dom";

export const AppStateContext = createContext();
export const UserStateContext = createContext();

export function ContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [appState, setAppState] = useState({
    client: {},
    candidate: {},
  });
  function signup(email, password) {
    createUserWithEmailAndPassword(auth, email, password);
    return;
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function verifyUserEmail() {
    sendEmailVerification(user);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (firebaseUser) => {
      if (firebaseUser) {
        setUser(firebaseUser);
        const userRef = doc(db, "users", firebaseUser.uid);
        const queryAdmins = await getDocs(collection(db, "admins"));
        const admins = queryAdmins.docs
          .map((doc) => doc.data())
          .map((u) => u.email);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUser(docSnap.data());
          setUser((prevState) => ({
            ...prevState,
            userUID: firebaseUser.uid,
            isAdmin: admins?.includes(firebaseUser?.email),
          }));
        }
        setLoading(false);
      } else {
        setUser(null);
      }
    });
    return unsubscribe;
  }, []);

  const value = {
    user,
    setUser,
    login,
    signup,
    logout,
    verifyUserEmail,
  };

  return (
    <UserStateContext.Provider value={value}>
      <AppStateContext.Provider value={{ appState, setAppState }}>
        {children}
      </AppStateContext.Provider>
    </UserStateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(AppStateContext);
  if (!context) {
    throw new Error("useAppState must be use inside Context Provider");
  }

  return context;
}

export function useUserState() {
  const context = useContext(UserStateContext);
  if (!context) {
    throw new Error("useUserState must be use inside Context Provider");
  }

  return context;
}

// export function AuthenticatedRoute ({ component: C, ...props }) {
//   const { user } = useUserState()
//   console.log(`AuthenticatedRoute: ${user}`)
//   return (
//     <Route
//       {...props}
//       render={routeProps =>
//         user ? <C {...routeProps} /> : <Navigate to="/login" />
//       }
//     />
//   )
// }

// export function PublicRoute ({ component: C, ...props }) {
//   const { user } = useUserState()
//   console.log(`UnauthenticatedRoute: ${isAuthenticated}`)
//   return (
//     <Route
//       {...props}
//       render={routeProps =>
//         !user ? <C {...routeProps} /> : <Navigate to="/" />
//       }
//     />
//   )
// }
