import React from "react";

import PropTypes from "prop-types";

import "./stats.css";

const Stats = (props) => {
  return (
    <div className="stats-stats">
      <div className="stats-testimonial">
        <span className="stats-text">
          <span className="stats-text01">Real</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="stats-text03">Results</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <br></br>
          <span className="stats-text08">Not Just</span>
          <span>
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="stats-text10">Promises</span>
        </span>
        <span className="white-text testimonials-tagline">
          Hiring time reduced by 50%, quality hires increased by 30%, and cost
          savings of 20%.
        </span>
        <div className="stats-container">
          <div className="stats-container1">
            <div className="stats-testimonial-card">
              <div className="stats-testimonial1">
                <span className="stats-text11">{props.text}</span>
                <span className="stats-text12">{props.text1}</span>
                <span className="stats-text13">{props.text2}</span>
              </div>
              <img
                alt={props.image_alt}
                src={props.image_src}
                className="stats-image"
              />
            </div>
            <div className="stats-testimonial-card1">
              <div className="stats-testimonial2">
                <span className="stats-text14">{props.text3}</span>
                <span className="stats-text15">{props.text4}</span>
                <span className="stats-text16">{props.text5}</span>
              </div>
              <img
                alt={props.image_alt1}
                src={props.image_src1}
                className="stats-image1"
              />
            </div>
          </div>
          <div className="stats-container2">
            <div className="stats-testimonial-card2">
              <span className="stats-text17">
                <span>
                  The team at Decentraloco contacted me with an indirect,
                  refreshing approach to outreach and once on the Zoom I
                  realised that these guys had some innovative ideas and
                  processes around how talent in the web3 space is sourced,
                  incentivised and packaged up.
                </span>
                <br></br>
                <br></br>
                <span>
                  The quality of the candidates were above and beyond
                  expectations and of a higher quality than most of the larger
                  and well-known Blockchain Recruiters I have previously worked
                  with.
                </span>
                <br></br>
              </span>
              <div className="stats-profile">
                <div className="stats-container3">
                  <span className="stats-text23">{props.text6}</span>
                  <span className="stats-text24">{props.text7}</span>
                  <img
                    alt={props.image_alt2}
                    src={props.image_src2}
                    className="stats-image2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Stats.defaultProps = {
  image_src: "/1677689265808-200h.jpeg",
  image_alt: "profile",
  image_src1: "/external/profile-i0rh-200h.jpg",
  image_alt1: "profile",
  text: "I first met James when Companion was in the process of being founded, after his team reached out via LinkedIn. I needed high quality engineers and James had the means, knowledge and drive to secure them for me. We've now hired 4-5 candidates introduced by James and his team over the last 6 months. ",
  text1: "Liam Brennan",
  text2: "Founder @ Companion Group",
  text3:
    "James has built a very reliable recruitment agency and I highly recommend DecentraLoco. They have played an important role in hiring our senior marketing team. He and his team understood our needs and supported us throughout the hiring process. If I had any problems, I could always call James. His advice was always valuable. He delivered top-shelf engaged candidates and helped us make the final conversion.",
  text4: "Justin Pethers",
  text5: "Co-Founder @ Elevated",
  text6: "Jonathan MacDonald",
  text7: "CMO @ Minima",
  image_src2: "/external/profile-cbko-200h.png",
  image_alt2: "profile",
};

Stats.propTypes = {
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt1: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text5: PropTypes.string,
  text6: PropTypes.string,
  text7: PropTypes.string,
  image_src2: PropTypes.string,
  image_alt2: PropTypes.string,
};

export default Stats;
