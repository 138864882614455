import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "aos/dist/aos.css";
import * as AOS from "aos";
import "./style.css";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./views/home/";
import Pricing from "./views/pricing/";
import Courses from "./views/courses/";
import Blogs from "./views/blogs/";
import FAQs from "./views/faqs/";
// import Jobs from "./views/jobs-coming-soon";
import BuildYourTeam from "./views/build-team/build-your-team";
import Jobs from "./views/jobs/jobs";
import PrivacyPolicy from "./views/privacy-policy/privacy-policy";
// candidate
import ApplyAsACandidate from "./views/candidate/apply-as-a-candidate";
import AddSkills from "./views/candidate/add-skills";
import CandidateStep0 from "./views/candidate/candidate-step-0";
import CandidateStep1 from "./views/candidate/candidate-step-1";
import CandidateStep2 from "./views/candidate/candidate-step-2";
import CandidateStep6 from "./views/candidate/candidate-step-6";
import CandidateStep3 from "./views/candidate/candidate-step-3";
import CandidateStep4 from "./views/candidate/candidate-step-4";
import CandidateStep5 from "./views/candidate/candidate-step-5";
import CandidateProfileEdit from "./views/candidate/candidate-profile-edit";

// client
import ClientStep0 from "./views/client/client-step-0";
import ClientStep2 from "./views/client/client-step-2";
import ClientStep1 from "./views/client/client-step-1";
import ClientStep3 from "./views/client/client-step-3";
import ClientStep4 from "./views/client/client-step-4";
import ClientStep5meeting from "./views/client/client-step-5meeting";

// user auth
import LogIn from "./views/auth/log-in";
import ChangePassword from "./views/auth/change-password";
import ForgotPassword from "./views/auth/forgot-password";
import EmailVerification from "./components/email-verification";
//
import Register from "./dashboard/pages/Registration";
import LoginDashboard from "./dashboard/pages/LoginDashboard";

import ErrorPage from "./views/errors/error-page";
import ContactUs from "./views/contact/contact-us";
import ContactUsClient from "./views/contact/contact-us-client";
import MatchedTalents from "./views/matched-talents/matched-talents";

import { ContextProvider } from "./utils/stateContext";
import AdminRoutes from "./AdminRoutes";

import DashboardRoutes from "./dashboard";
import AboutUsCom from "./views/about/about-us-com";
import OurProcessPage from "./views/client/OurProcessPage";
import NextSteps from "./views/client/next-steps";
import ReactGA from "react-ga4";
import ClientProfileEdit from "./views/client/client-profile-edit";
import ClientJoinWaitlist from "./views/client/client-join-waitlist";
import ClientConfirmAccount from "./views/client/client-confirm-account";

const TRACKING_ID = "G-890SFTLKFL";

const App = () => {
  ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    AOS.init();
    // ReactGA.pageview(window?.location?.pathname + window?.location?.search);
  }, []);
  return (
    <ContextProvider>
      <BrowserRouter>
        <ScrollToTop></ScrollToTop>
        <Routes>
          <Route element={<BuildYourTeam />} exact path="/build-your-team" />
          <Route
            element={<EmailVerification />}
            exact
            path="/email-verification"
          />
          <Route element={<Courses />} exact path="/courses" />
          <Route element={<Blogs />} exact path="/blogs" />
          <Route element={<FAQs />} exact path="/f-a-qs" />
          <Route element={<CandidateStep4 />} exact path="/candidate-step-4" />
          <Route element={<CandidateStep3 />} exact path="/candidate-step-3" />
          <Route element={<ClientStep4 />} exact path="/client-step-4" />
          <Route element={<Pricing />} exact path="/pricing" />
          <Route element={<ChangePassword />} exact path="/change-password" />
          <Route element={<ErrorPage />} exact path="/error-page" />
          <Route
            element={<CandidateProfileEdit />}
            exact
            path="/candidate-profile-edit"
          />
          <Route element={<ClientStep1 />} exact path="/client-step-1" />
          <Route element={<NextSteps />} exact path="/next-steps" />
          <Route element={<OurProcessPage />} exact path="/our-process" />
          <Route element={<LogIn />} exact path="/log-in" />
          <Route element={<CandidateStep5 />} exact path="/candidate-step-5" />
          <Route element={<ForgotPassword />} exact path="/forgot-password" />
          <Route element={<Home />} exact path="/" />
          <Route element={<AboutUsCom />} exact path="/about-us" />
          <Route element={<Jobs />} exact path="/jobs" />
          <Route element={<PrivacyPolicy />} exact path="/privacy-policy" />
          <Route element={<ClientStep3 />} exact path="/client-step-3" />
          <Route element={<CandidateStep0 />} exact path="/candidate-step-0" />
          <Route element={<MatchedTalents />} exact path="/matched-talents" />
          <Route
            element={<ApplyAsACandidate />}
            exact
            path="/apply-as-a-candidate"
          />
          <Route element={<ClientStep0 />} exact path="/client-step-0" />
          <Route
            element={<ClientJoinWaitlist />}
            exact
            path="/client-join-waitlist"
          />
          <Route
            element={<ClientConfirmAccount />}
            exact
            path="/client-confirm-account"
          />
          <Route
            element={<ClientProfileEdit />}
            exact
            path="/client-profile-edit"
          />
          <Route element={<CandidateStep2 />} exact path="/candidate-step-2" />
          <Route element={<CandidateStep1 />} exact path="/candidate-step-1" />
          <Route element={<ClientStep2 />} exact path="/client-step-2" />
          <Route element={<CandidateStep6 />} exact path="/candidate-step-6" />
          <Route element={<ContactUs />} exact path="/contact-us" />
          <Route element={<AddSkills />} exact path="/add-skills" />
          <Route
            element={<ContactUsClient />}
            exact
            path="/contact-us-client"
          />
          <Route
            element={<ClientStep5meeting />}
            exact
            path="/client-step-5meeting"
          />
          <Route element={<DashboardRoutes />} path="/decentradmin/*" />

          <Route
            element={<LoginDashboard />}
            exact
            path="/decentradmin/login"
          />
          <Route element={<ErrorPage />} exact path="/*" />
        </Routes>
      </BrowserRouter>
    </ContextProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("app"));
