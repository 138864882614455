import { Link } from "react-router-dom";
import { useUserState } from "../utils/stateContext";

const LoginUserComponent = (props) => {
  const { user, handleLogout, show, setShow } = props;
  return (
    <>
      {user ? (
        <Link
          className="home-register button cc"
          onClick={() => setShow(!show)}
        >
          {user &&
            user?.firstName &&
            user?.lastName &&
            `${user.firstName} ${user.lastName}`}
        </Link>
      ) : (
        <Link to="/log-in" className="home-register button">
          Log In
        </Link>
      )}
      {show && (
        <div
          style={{
            backgroundColor: "#1E1C24",
            borderRadius: "8px",
            color: "#fff",
            cursor: "pointer",
            fontFamily: "Space Grotesk",
            marginTop: "1px",
            padding: "15px 10px",
            position: "absolute",
            right: 0,
            textAlign: "center",
            top: "48px",
            width: "125px",
            zIndex: 9,
          }}
        >
          {user && user?.userType === "candidate" ? (
            <div style={{ marginTop: "5px" }}>
              <Link to="/candidate-profile-edit" className="">
                Profile
              </Link>
            </div>
          ) : (
            <div style={{ marginTop: "5px" }}>
              <Link to="/client-profile-edit" className="">
                Profile
              </Link>
            </div>
          )}
          <button
            className="hoverPointer"
            onClick={() => handleLogout()}
            style={{ marginTop: "15px", color: "#fff" }}
          >
            Log out
          </button>
        </div>
      )}
    </>
  );
};

export default LoginUserComponent;
