import React, { Fragment, useState } from "react";
import SideBar from "./SideBar";
import NavHader from "./NavHader";
import Header from "./Header";
import ChatBox from "../ChatBox";

const JobieNav = ({ currentPageTitle, toggle, setToggle, handleLogout }) => {
   
   // const onClick = (name) => setToggle(toggle === name ? "" : name);
   return (
      <Fragment>
         <NavHader setToggle={setToggle} toggle={toggle} />
         <SideBar toggle={toggle} setToggle={setToggle} handleLogout={handleLogout} />
         <Header
            // onNote={() => onClick("chatbox")}
            // onNotification={() => onClick("notification")}
            // onProfile={() => onClick("profile")}
            // onBox={() => onClick("box")}
            // onClick={() => ClickToAddEvent()}
            toggle={toggle}
            currentPageTitle={currentPageTitle}
         />
         <ChatBox  toggle={toggle} />
      </Fragment>
   );
};

export default JobieNav;
