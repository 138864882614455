import React, { useState } from "react";

import PropTypes from "prop-types";

import "./add-skills.css";

const AddSkills = ({
  skills,
  skillsList,
  setSkillsList,
  setIsOpen,
  setValue,
}) => {
  const [selectedSkills, setSelectedSkills] = useState(skillsList);
  const [skillsError, setSkillsError] = useState(false);

  const handleSkillClick = (skill) => {
    if (selectedSkills.includes(skill)) {
      setSelectedSkills(
        selectedSkills.filter((selectedSkills) => selectedSkills !== skill)
      );
    } else if (selectedSkills.length < 5) {
      setSelectedSkills([...selectedSkills, skill]);
    }
  };

  const saveSkills = () => {
    if (selectedSkills.length > 2) {
      setSkillsList(selectedSkills);
      setValue("skills", selectedSkills);
      setSkillsError(false);
      setIsOpen(false);
    } else {
      setSkillsError(true);
    }
  };
  return (
    <div className="add-skills-container">
      <div className="add-skills-container1">
        <span className="add-skills-text">Add your skills</span>
        <svg
          viewBox="0 0 1024 1024"
          className="add-skills-icon"
          onClick={() => setIsOpen(false)}
        >
          <path d="M810 554h-596v-84h596v84z"></path>
        </svg>
      </div>
      <div className="add-skills-separator"></div>
      <p style={{ color: "#fff", fontFamily: "Space Grotesk" }}>
        Please select between 3 and 5 skills
      </p>
      <div className="add-skills-container2">
        {skills.map((skill, i) => (
          <button
            key={i}
            type="button"
            className="add-skills-button button"
            onClick={() => handleSkillClick(skill.label)}
            style={{
              border: selectedSkills.includes(skill.label)
                ? "2px solid #6741ffff"
                : "1px solid #595959",
              color: selectedSkills.includes(skill.label) ? "#fff" : "#999",
            }}
          >
            <span className="add-skills-text1">{skill.label}</span>
          </button>
        ))}
        {skillsError && (
          <p className="error-text">*Please choose at least 3 skills</p>
        )}
      </div>
      <div className="add-skills-container3">
        <button
          className="add-skills-button3 button"
          onClick={() => setIsOpen(false)}
        >
          <span className="add-skills-text4">Cancel</span>
        </button>
        <button
          className="add-skills-button4 button"
          onClick={() => saveSkills()}
        >
          <span className="add-skills-text5">Save</span>
        </button>
      </div>
    </div>
  );
};

AddSkills.defaultProps = {
  text: "Ready to interview",
};

AddSkills.propTypes = {
  text: PropTypes.string,
};

export default AddSkills;
