import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  query,
  orderBy,
  limit,
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";

import CountUp, { useCountUp } from "react-countup";
import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";

import JobsBoard from "../../components/jobs-board";
import Footer from "../../components/footer";
import "./home.css";
import { useUserState } from "../../utils/stateContext";
import { signOut } from "firebase/auth";
import { db, auth } from "../../database/firebase";
import testimonials from "../../data/testimonials.json";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
SwiperCore.use([Navigation]);

// import required modules
import SwiperCore, { Autoplay, Navigation } from "swiper";
import useWindowSize from "../../utils/GetWindowSize";
import SupportForm from "../../components/SupportForm";
import LoginUserComponent from "../../components/LoginUserComponent";
import JobDescriptionComponent from "../jobs/job-description-component";
import Companys from "../../components/companys";
import Stats from "../../components/stats";

const Home = (props) => {
  const navigate = useNavigate();
  const size = useWindowSize();
  const { user, setUser, logout } = useUserState();
  const [show, setShow] = useState(false);
  const [jobsList, setJobsList] = useState([]);
  const [currentJob, setCurrentJob] = useState(null);
  const [toggleReadJob, setToggleReadJob] = useState(false);
  const [refreshJobs, setRefreshJobs] = useState(null);
  const gamingCounterRef = useRef(null);
  const talentsCounterRef = useRef(null);
  const clientsCounterRef = useRef(null);
  const { start: startGamingCounter } = useCountUp({
    ref: gamingCounterRef,
    start: 0,
    end: 100,
    duration: 2.75,
  });
  const { start: startTalentsCounter } = useCountUp({
    ref: talentsCounterRef,
    start: 100,
    end: 5000,
    duration: 3.25,
  });
  const { start: startClientsCounter } = useCountUp({
    ref: clientsCounterRef,
    start: 0,
    end: 100,
    duration: 2.75,
  });

  const isScrolledIntoView = (el) => {
    var rect = document.querySelector(el).getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    return isVisible;
  };

  const settings = {
    dots: true,
    centerMode: true,
    centerPadding: "0",
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: "slick testimonial",
  };

  const swiperRef = useRef(null);
  const partnersSliderRef = useRef(null);

  const handleLogout = () => {
    logout()
      .then(() => {
        setUser(null);
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  let counterStarted = false;

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (
        !counterStarted &&
        gamingCounterRef &&
        gamingCounterRef.current &&
        document.querySelector("#gamingCounter")
      ) {
        if (isScrolledIntoView("#gamingCounter")) {
          startGamingCounter();
          startTalentsCounter();
          startClientsCounter();
          counterStarted = true;
        }
      }
    });

    const controller = new AbortController();
    async function fetchJobs() {
      const q = query(
        collection(db, "jobs"),
        orderBy("updatedAt", "desc"),
        limit(6)
      );
      const snapShot = await getDocs(q);
      const jobsData = snapShot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setJobsList(jobsData.reverse().slice(0, 6));
    }
    fetchJobs();
    return () => {
      controller.abort();
    };
  }, [refreshJobs]);

  const openPreview = (job) => {
    setCurrentJob(job);
    setToggleReadJob(true);
    const mobileMenu = document.querySelector("#mobile-menu");
    mobileMenu.style.transform = `translateX(${
      window.innerWidth >= 984 ? "20" : "0"
    }%)`;
    const overlay = document.createElement("div");
    overlay.id = "overlayBlured";
    document.querySelector("#app").appendChild(overlay);
    document.querySelector("#overlayBlured").style.display = "block";
  };

  const closePreview = () => {
    setToggleReadJob(false);
    setCurrentJob(null);
    const mobileMenu = document.querySelector("#mobile-menu");
    mobileMenu.style.transform = "translateX(100%)";
    const overlay = document.querySelector("#overlayBlured");
    overlay.style.display = "none";
    overlay.remove();
  };

  const applyToJob = async (job) => {
    if (job?.id && user?.userUID && user?.userType === "candidate") {
      const jobApplyRef = doc(db, "jobs", job.id);
      const jobDoc = await getDoc(jobApplyRef);
      const jobData = jobDoc.data();
      if (!jobData.applies) {
        jobData.applies = [];
      }
      if (
        !jobData?.applies?.map((apl) => apl.user_uid).includes(user?.userUID)
      ) {
        jobData.applies.push({
          user_uid: user?.userUID,
          created_at: Date.now(),
        });
      }
      await setDoc(jobApplyRef, { ...jobData });
      setRefreshJobs((refreshJobs || 0) + 1);
      setCurrentJob(jobData);
    } else {
      navigate("/candidate-step-0");
    }
  };

  return (
    <div className="home-container">
      <Helmet>
        <title>Decentraloco</title>
        <meta property="og:title" content="Decentraloco" />
      </Helmet>
      <nav className="home-navbar">
        <header data-thq="thq-navbar" className="home-navbar-interactive">
          <Link to="/" className="home-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="home-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="home-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="home-nav"
            >
              <Link to="/" className="home-navlink01 button">
                Home
              </Link>
              <Link to="/about-us" className="home-navlink02 button">
                About Us
              </Link>
              <Link to="/contact-us" className="home-navlink02 button">
                Values
              </Link>
              <Link to="/blogs" className="home-navlink03 button">
                Blog
              </Link>
            </nav>
          </div>
          <div data-thq="thq-navbar-btn-group" className="home-btn-group">
            <Link to="/apply-as-a-candidate" className="home-login button">
              Join Talent Network
            </Link>
            <Link to="/build-your-team" className="home-login1 button">
              Hire Talent
            </Link>

            <LoginUserComponent
              user={user}
              handleLogout={handleLogout}
              show={show}
              setShow={setShow}
            ></LoginUserComponent>
          </div>
          <div data-thq="thq-burger-menu" className="home-burger-menu">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="home-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="home-nav1"
            >
              <div className="home-container01">
                <Link to="/" className="home-navlink04">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="home-finbest1"
                  />
                </Link>
                <div data-thq="thq-close-menu" className="home-menu-close">
                  <svg viewBox="0 0 1024 1024" className="home-icon02">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="home-nav2"
              >
                <Link to="/" className="home-navlink05">
                  Home
                </Link>
                <Link to="/contact-us" className="home-navlink06">
                  Values
                </Link>
                <Link to="/blogs" className="home-navlink07">
                  Blog
                </Link>
              </nav>
              <div className="home-container02">
                <LoginUserComponent
                  user={user}
                  handleLogout={handleLogout}
                  show={show}
                  setShow={setShow}
                ></LoginUserComponent>

                <Link to="/build-your-team" className="home-login2 button">
                  Hire Talent
                </Link>
                <Link to="/apply-as-a-candidate" className="home-login3 button">
                  Apply as a Talent
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div id="mobile-menu" className="jobs-mobile">
          <JobDescriptionComponent
            closePreview={closePreview}
            job={currentJob}
            user={user}
            applyToJob={applyToJob}
          ></JobDescriptionComponent>
        </div>
      </nav>
      <div className="home-hero">
        <div className="home-container03">
          <h2 className="home-text006">
            <span className="home-text007">Building The Future Of</span>
            <br className="home-text008"></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="home-text011">Blockchain,</span>
            <span className="home-text012">
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="home-text011">Gaming</span>
            <span className="home-text012">
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>

            <span className="home-text013">
              &amp;
              <span className="home-text014">&nbsp;AI</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>

            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </h2>
          <span className="home-text018">
            <span>
              Discover top jobs in the Blockchain, Gaming & AI globally or,
              build your dream team.
            </span>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br></br>
          </span>
          <div className="home-btn-group1">
            <Link to="/apply-as-a-candidate" className="home-navlink08 button">
              <span className="home-text024">Register as a candidate</span>
            </Link>
            <Link to="/build-your-team" className="home-navlink09">
              <div className="home-sign-up-btn">
                <span className="home-sign-up">Hire Talent</span>
              </div>
            </Link>
          </div>
          <div className="home-btn-group2">
            <div className="home-container04">
              <div className="home-container05">
                <span className="home-text025">
                  <span className="home-text026">
                    <CountUp duration={5.75} start={100} end={5000}></CountUp>
                  </span>
                  <span className="home-text027">+</span>
                </span>
                <span className="home-text028">Active Candidates</span>
              </div>
              <div className="home-container06">
                <span className="home-text029">
                  <span className="home-text030">
                    <CountUp duration={5.5} start={1} end={100}></CountUp>
                  </span>
                  <span className="home-text031">+</span>
                </span>
                <span className="home-text032">Completed Projects</span>
              </div>
              <div className="home-container07">
                <span className="home-text033">
                  <span className="home-text034">
                    <CountUp duration={4.75} start={1} end={100}></CountUp>
                  </span>
                  <span>{"  %"}</span>
                </span>
                <span className="home-text036">Satisfied Clients</span>
              </div>
            </div>
          </div>
          <div className="home-container08">
            <span className="home-text037">We accept: </span>
            <div className="home-container09">
              <img
                alt="image"
                src="/playground_assets/btc.svg"
                className="home-image01"
              />
              <img
                alt="image"
                src="/playground_assets/eth.svg"
                className="home-image02"
              />
              <img
                alt="image"
                src="/playground_assets/metamask-fox.svg"
                className="home-image03"
              />
              <img
                alt="image"
                src="/playground_assets/bnb.svg"
                className="home-image04"
              />
              <img
                alt="image"
                src="/playground_assets/tether-usdt-logo.png"
                className="home-image05"
                style={{ backgroundColor: "#408C77" }}
              />
            </div>
            <span className="home-text038">...</span>
          </div>
        </div>
        <img
          src="/playground_assets/art-work%2001-1500h.png"
          loading="lazy"
          className="home-image-1"
        />
      </div>
      {/* <Companys /> */}
      <div className="home-about-us">
        <div className="home-container10">
          <span className="home-text039">
            <span>
              Looking for
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="home-text041">Projects</span>
            <span>?</span>
          </span>
          <div className="home-container11">
            <span className="home-text043">
              <span>
                Find your next opportunity in Blockchain, Gaming, & AI. We’ll
                match you with current & future projects. <br />
                You can expect from us.
              </span>
              <br></br>
            </span>
            <div className="home-container12">
              <div className="home-container13">
                <svg viewBox="0 0 1024 1024" className="home-icon06">
                  <path d="M384 692l452-454 60 60-512 512-238-238 58-60z"></path>
                </svg>
                <span className="home-text046">To be treated like a human</span>
              </div>
              <div className="home-container14">
                <svg viewBox="0 0 1024 1024" className="home-icon08">
                  <path d="M384 692l452-454 60 60-512 512-238-238 58-60z"></path>
                </svg>
                <span className="home-text047">
                  Personalised feedback from interviews
                </span>
              </div>
              <div className="home-container15">
                <div className="home-container16">
                  <svg viewBox="0 0 1024 1024" className="home-icon10">
                    <path d="M384 692l452-454 60 60-512 512-238-238 58-60z"></path>
                  </svg>
                  <span className="home-text048">
                    Become part of a growth-mindset community
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Link to="/apply-as-a-candidate">
            <div className="home-sign-up-btn1">
              <span className="home-sign-up1">Register as a candidate</span>
            </div>
          </Link>
        </div>
        <img
          data-aos="fade-right"
          alt="image"
          src="/playground_assets/art-work%2002-1500h.png"
          loading="eager"
          className="home-image06"
        />
        <div className="home-container19" data-aos="fade-left">
          <span className="home-text051">
            <span className="home-text052">
              Looking for
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="home-text053">Projects</span>
            <span className="home-text054">?</span>
            <br></br>
          </span>
          <div className="home-container20">
            <span className="home-text056">
              <span>
                Find your next opportunity in Blockchain, Gaming, & AI. <br />
                We’ll match you with current & future projects. <br />
                You can expect from us.
              </span>
              <br></br>
            </span>
            <div className="home-container21">
              <div className="home-container22">
                <svg viewBox="0 0 1024 1024" className="home-icon16">
                  <path d="M384 692l452-454 60 60-512 512-238-238 58-60z"></path>
                </svg>
                <span className="home-text059">To be treated like a human</span>
              </div>
              <div className="home-container23">
                <svg viewBox="0 0 1024 1024" className="home-icon18">
                  <path d="M384 692l452-454 60 60-512 512-238-238 58-60z"></path>
                </svg>
                <span className="home-text060">
                  Personalised feedback from interviews
                </span>
              </div>
              <div className="home-container24">
                <div className="home-container25">
                  <svg viewBox="0 0 1024 1024" className="home-icon20">
                    <path d="M384 692l452-454 60 60-512 512-238-238 58-60z"></path>
                  </svg>
                  <span className="home-text061">
                    Become part of a growth-mindset community
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Link to="/apply-as-a-candidate" className="home-navlink11">
            <div className="home-sign-up-btn2">
              <span className="home-sign-up2">Register as a candidate</span>
            </div>
          </Link>
        </div>
      </div>
      <div className="home-separator"></div>
      <div className="home-about-us1">
        <div className="home-container28" data-aos="fade-right">
          <span className="home-text064">
            <span>
              Need
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="home-text066">Talent</span>
            <span>?</span>
          </span>
          <div className="home-container29">
            <span className="home-text068">
              <span>
                Looking for high-performing remote, hybrid, or on-site
                personnel? Our talent has been placed in every continent (except
                Antarctica) We solve three main problems when making niche
                hires.
              </span>
              <br></br>
            </span>
            <div className="home-container30">
              <div className="home-container31">
                <svg viewBox="0 0 1024 1024" className="home-icon26">
                  <path d="M384 692l452-454 60 60-512 512-238-238 58-60z"></path>
                </svg>
                <span className="home-text071">Cost of hire</span>
              </div>
              <div className="home-container32">
                <svg viewBox="0 0 1024 1024" className="home-icon28">
                  <path d="M384 692l452-454 60 60-512 512-238-238 58-60z"></path>
                </svg>
                <span className="home-text072">Speed of delivery</span>
              </div>
              <div className="home-container33">
                <div className="home-container34">
                  <svg viewBox="0 0 1024 1024" className="home-icon30">
                    <path d="M384 692l452-454 60 60-512 512-238-238 58-60z"></path>
                  </svg>
                  <span className="home-text073">Quality of candidates</span>
                </div>
              </div>
            </div>
          </div>
          <Link to="/build-your-team" className="home-navlink12">
            <div className="home-sign-up-btn3">
              <span className="home-sign-up3">Hire Talent</span>
            </div>
          </Link>
        </div>
        <img
          alt="image"
          src="/playground_assets/art-work%2003-1500h.png"
          loading="lazy"
          className="home-image07"
          data-aos="fade-left"
        />
      </div>
      <div className="home-stats">
        <span className="home-text076">
          <span className="home-text077">
            Our
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="home-text078">Stats</span>
        </span>
        <div className="home-container37">
          <div className="home-stat">
            <span className="home-caption">
              +<span id="gamingCounter" ref={gamingCounterRef}></span>
            </span>
            <span className="home-description">
              Completed projects in Blockchain, Gaming &amp; AI
            </span>
          </div>
          <div className="home-stat1">
            <span className="home-caption1">
              +<span ref={talentsCounterRef}></span>
            </span>
            <span className="home-description1">
              Top-tech talent ready to work on your
              <br /> project
            </span>
          </div>
          <div className="home-stat2">
            <span className="home-caption2">
              <span ref={clientsCounterRef}></span>%
            </span>
            <span className="home-description2">
              <span className="home-text079">
                Satisfied clients working with Decentraloco
              </span>
              <br className="home-text080"></br>
              <span className="home-text081">
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </div>
        </div>
      </div>
      {/* <div className="home-container38" style={{ width: size.width }}>
        <span className="home-text082">
          <span>
            Here's what
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="home-text084">
            people
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span>say</span>
          <br></br>
          <span>
            about
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="home-text088">DecentraLoco</span>
        </span>
        <div className="home-testimonials">
          <Swiper
            modules={[Autoplay, Navigation]}
            centeredSlides
            spaceBetween={150}
            initialSlide={2}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            ref={swiperRef}
            loop
            style={{ width: size.width - size.width / 100 }}
            breakpoints={{
              200: {
                slidesPerView: 1,
              },
              1000: {
                slidesPerView: 2,
              },
            }}
          >
            {testimonials.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="home-logo-container1">
                  <img
                    alt="pastedImage"
                    src={item.logo}
                    className="home-logo"
                  />
                </div>
                <div className="home-text100">{item.text}</div>
                <br />
                <br />
                <br />
                <div className="home-content1">
                  <div className="home-person">
                    <img
                      alt="person-avatar"
                      src={item.image}
                      className="home-avatar"
                    />
                    <div className="home-person-details">
                      <span id="quote-author" className="home-text094">
                        {item.name}
                      </span>
                      <span id="quote-title" className="home-text095">
                        {item.role}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className="home-buttons1">
              <div
                id="quote-before"
                className="home-left"
                onClick={() => swiperRef.current.swiper.slidePrev()}
              >
                <svg viewBox="0 0 1024 1024" className="home-icon36">
                  <path d="M854 470v84h-520l238 240-60 60-342-342 342-342 60 60-238 240h520z"></path>
                </svg>
              </div>
              <div
                id="quote-next"
                className="home-right"
                onClick={() => swiperRef.current.swiper.slideNext()}
              >
                <svg viewBox="0 0 1024 1024" className="home-icon38">
                  <path d="M512 170l342 342-342 342-60-60 238-240h-520v-84h520l-238-240z"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <Stats />
      {/* <div className="home-separator1"></div> */}
      {jobsList?.length > 0 && (
        <JobsBoard
          jobsList={jobsList}
          applyToJob={applyToJob}
          user={user}
          openPreview={openPreview}
        ></JobsBoard>
      )}

      {/* 
      <Modal
        isOpen={toggleReadJob}
        // onAfterOpen={afterOpenModal}
        ariaHideApp={false}
        onRequestClose={() => closePreview() }
        style={customStyles}
        contentLabel="Job Preview"
        key={refreshJobs}
        className={'job-description-popup-home'}
      >
        <JobDescriptionComponent 
          closePreview={closePreview} 
          job={currentJob} 
          user={user} 
          applyToJob={applyToJob}
        ></JobDescriptionComponent>
      </Modal> */}

      <Link to="/jobs" className="home-navlink13 button">
        <span className="home-text164">Browse all Job&apos;s</span>
      </Link>
      <div className="home-separator2"></div>
      <div className="home-blog">
        <div className="home-container42">
          <div className="home-container43">
            <span className="home-text122">
              <span>
                From the
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className="home-text124">Blog</span>
            </span>
          </div>
          <div className="home-container44">
            <div className="home-container45">
              <a
                href="https://medium.com/@jifure/blast-l2-airdrop-how-to-get-blast-invite-e4e6256b0882"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link"
              >
                <div className="home-blog-post-card">
                  <img
                    alt="image"
                    src="https://miro.medium.com/v2/resize:fit:4800/format:webp/1*fEFjaCbgnfe6hzyztXuYYA.jpeg"
                    className="home-image08"
                  />
                  <div className="home-container46">
                    <span className="home-text125">A.I.</span>
                    <span className="home-text126">
                      <span>
                        <span>
                          BLAST L2 Airdrop: How to get BLAST invite?
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span>
                      </span>
                    </span>
                    <span className="home-text134">
                      A newly introduced Ethereum layer 2 offering called Blast
                      is swiftly gaining attention for its closed, invite-only
                      early access campaign prior to public launch. Created
                      by...
                    </span>
                    <div className="home-container47">
                      <span className="home-text135">2 min read</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="home-container48">
              <a
                href="https://medium.com/yield-guild-games/9-top-web3-games-in-the-ygg-ecosystem-0f1a9daedb57"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link1"
              >
                <div className="home-blog-post-card1">
                  <img
                    alt="image"
                    src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*xp3kJKlCvE_0Z_DV"
                    className="home-image09"
                  />
                  <div className="home-container49">
                    <span className="home-text136">Web3 Gaming</span>
                    <span className="home-text137">
                      9 Top Web3 Games in the YGG Ecosystem
                    </span>
                    <span className="home-text138">
                      <span>
                        Entering another exciting year, the YGG community
                        continues to show their support for the most promising
                        games in the guild’s ecosystem. Some of these games...
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                    </span>
                    <div className="home-container50">
                      <span className="home-text141">12 min read</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="home-container51">
              <a
                href="https://medium.com/crypto-hub-launchpad/areon-network-introducing-a-new-metaverse-paradigm-in-blockchain-313c5e0d73b1"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link2"
              >
                <div className="home-blog-post-card2">
                  <img
                    alt="image"
                    src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*QKMlM109HGV2tiw5zOx_bA.png"
                    className="home-image10"
                  />
                  <div className="home-container52">
                    <span className="home-text142">
                      <span>Blockchain</span>
                      <br></br>
                    </span>
                    <span className="home-text145">
                      <span>
                        <span>
                          Areon Network | Introducing a New Metaverse...
                        </span>
                      </span>
                    </span>
                    <span className="home-text150">
                      Areon Network sets itself apart not only with its
                      innovative Proof of AREA consensus mechanism but also its
                      groundbreaking velocity, achieving an impressive
                      throughput...
                    </span>
                    <div className="home-container53">
                      <span className="home-text151">2 min read</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
          <div className="home-container54">
            <div className="home-container55">
              <a
                href="https://medium.com/the-quantified-world/ps5-pro-unleashed-unraveling-the-future-of-console-gaming-e1981a7c812b"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link3"
              >
                <div className="home-blog-post-card3">
                  <img
                    alt="image"
                    src="https://miro.medium.com/v2/resize:fit:2854/format:webp/1*M7TYgTyP1Wx3nSOOwlzxYw.jpeg"
                    className="home-image11"
                  />
                  <div className="home-container56">
                    <span className="home-text152">Gaming</span>
                    <span className="home-text153">
                      PS5 Pro Unleashed: Unraveling the Future of Console Gaming
                    </span>
                    <span className="home-text154">
                      If you’re a dedicated gamer, I bet you’re always on the
                      hunt for the next level of gameplay experience, aren’t
                      you? Well, your vigilant pursuit might just be rewarded...
                    </span>
                    <div className="home-container57">
                      <span className="home-text155">6 min read</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="home-container58">
              <a
                href="https://medium.com/@ignacio.de.gregorio.noblejas/microsofts-100-billion-bet-on-openai-is-stupid-unless-5dc03d9c0ec9"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link4"
              >
                <div className="home-blog-post-card4">
                  <img
                    alt="image"
                    src="https://miro.medium.com/v2/resize:fit:3584/format:webp/0*H1SVF7_4JxC7-uRa"
                    className="home-image12"
                  />
                  <div className="home-container59">
                    <span className="home-text156">A.I.</span>
                    <span className="home-text157">
                      Microsoft’s $100 Billion Bet on OpenAI Is Stupid. Unless…
                    </span>
                    <span className="home-text158">
                      An expensive supercomputer built in the Arizona desert by
                      a multibillion-dollar corporation in order to create
                      superintelligence. Sounds like a movie (or we wish it was)
                      but it seems it’s something that ....
                    </span>
                    <div className="home-container60">
                      <span className="home-text159">9 min read</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <div className="home-container61">
              <a
                href="https://medium.com/@thredmedia/whats-up-with-the-constant-gaming-layoffs-in-2024-5b2fe9a3139e"
                target="_blank"
                rel="noreferrer noopener"
                className="home-link5"
              >
                <div className="home-blog-post-card5">
                  <img
                    alt="image"
                    src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*-C1k1ZSF0QcdHHhF.png"
                    className="home-image13"
                  />
                  <div className="home-container62">
                    <span className="home-text160">Gaming</span>
                    <span className="home-text161">
                      What’s up with the constant gaming layoffs...
                    </span>
                    <span className="home-text162">
                      The gaming industry has never exactly been synonymous with
                      job security, but what is causing this unprecedented
                      amount of layoffs in 2024?
                    </span>
                    <div className="home-container63">
                      <span className="home-text163">4 min read</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
        </div>
        <Link to="/blogs" className="home-navlink13 button">
          <span className="home-text164">Browse all Blog&apos;s</span>
        </Link>
      </div>
      <div className="home-separator3"></div>
      <SupportForm home />
      <Footer />
    </div>
  );
};

export default Home;
