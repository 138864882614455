import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAppState } from "./stateContext";

// This component is a utility component designed to prevent users from skipping
// steps in a multi-step process by directly accessing a later step's URL.
const ClientProtectedSteps = ({ children }) => {
  const { appState, setAppState } = useAppState();
  if (!appState.client.email) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ClientProtectedSteps;
