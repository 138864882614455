import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './job-description-component.css'

const JobDescriptionComponent = (props) => {

  const { job, closePreview, applyToJob, user } = props;


  return (
    <div className="job-description-component-container">
      <div className="job-description-component-container01">
        <span onClick={ () => closePreview() }>
          <svg viewBox="0 0 1024 1024" className="job-description-component-icon">
            <path d="M896 470v84h-604l152 154-60 60-256-256 256-256 60 60-152 154h604z"></path>
          </svg>
          <span className="job-description-component-text" style={{ marginLeft: '10px' }}>Go Back</span>
        </span>
      </div>
      <div className="job-description-component-separator"></div>
      <div className="job-description-component-container02">
        {/* <svg
          viewBox="0 0 1024 1024"
          className="job-description-component-icon2"
        >
          <path d="M726 768v-554h-428v554l214-94zM726 128q34 0 59 26t25 60v682l-298-128-298 128v-682q0-34 25-60t59-26h428z"></path>
        </svg> */}
        <div className="job-description-component-container03">
          <img
            alt={ job?.companyName ||  props.image_alt}
            src={job?.companyLogo || props.image_src}
            className="job-description-component-image"
          />
          <div className="job-description-component-container04">
            <span className="job-description-component-text01">{ job?.jobTitle }</span>
            <div className="job-description-component-container05">
              <span className="job-description-component-text02">
                { job?.companyName }
              </span>
              <span className="job-description-component-text03">
                { job?.employeLocation }
              </span>
            </div>
          </div>
        </div>
        <div className="job-description-component-container06">
          <div className="job-description-component-container07">
            <div className="job-description-component-container08">
              <span className="job-description-component-text04">
                <span>Salary</span>
                <br></br>
              </span>
              <span className="job-description-component-text07">
                { job?.salaryRange }
              </span>
            </div>
            <div className="job-description-component-container09">
              <span className="job-description-component-text08">
                <span>Type</span>
                <br></br>
              </span>
              <span className="job-description-component-text11">
                { `${ job?.jobType } (${ job?.workspaceType || job?.workplaceType })` }
              </span>
            </div>
          </div>

          {
            job?.applies?.map(ap => ap.user_uid).includes(user?.userUID) ?
              <button className="job-description-component-navlink" >
                <div className="job-description-component-sign-up-btn button disabled">
                  <span className="job-description-component-sign-up w-20">
                    Your application <br/> has been received
                  </span>
                </div>
              </button>
            : 
              <button className="job-description-component-navlink" onClick={ () => applyToJob(job) }>
                <div className="job-description-component-sign-up-btn button">
                  <span className="job-description-component-sign-up">
                    {props.SignUp3}
                  </span>
                </div>
              </button>
          }

        </div>
      </div>
      <div className="job-description-component-separator1"></div>
      <div className="job-description-component-container10">
        <div className="job-description-component-container11">
          <span className="job-description-component-text12">
            job description
          </span>
          <div className="job-description-component-container12">
            <span className="job-description-component-text13" dangerouslySetInnerHTML={{__html: job?.fullDescription} }></span>
          </div>
        </div>
      </div>
      <div className="job-description-component-separator2"></div>
      <div className="job-description-component-container13">
        { job?.jobType && <button className="job-description-component-button button">{ job?.jobType }</button> }
        { (job?.workplaceType || job?.workspaceType) && <button className="job-description-component-button1 button">{ job?.workplaceType || job?.workspaceType }</button> }
        { job?.salaryRange && <button className="job-description-component-button2 button">{ job?.salaryRange }</button>}
      </div>
    </div>
  )
}

JobDescriptionComponent.defaultProps = {
  SignUp3: 'Apply now',
  image_alt: 'image',
  text1: 'Text',
  image_src: '/playground_assets/1673861952696-200h.jpg',
  button1: 'Full-time',
  button2: 'Full-time',
  text: 'Text',
  button: 'Full-time',
}

JobDescriptionComponent.propTypes = {
  SignUp3: PropTypes.string,
  image_alt: PropTypes.string,
  text1: PropTypes.string,
  image_src: PropTypes.string,
  button1: PropTypes.string,
  button2: PropTypes.string,
  text: PropTypes.string,
  button: PropTypes.string,
}

export default JobDescriptionComponent
