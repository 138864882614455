import React from 'react'
import { Link } from 'react-router-dom'

import DangerousHTML from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './footer-v2.css'

const FooterV2 = (props) => {
  return (
    <nav className={`footer-v2-footer-v2 ${props.rootClassName} `}>
      <div className="footer-v2-desktop">
        <span className="footer-v2-text">{props.text}</span>
        <div className="footer-v2-container">
          <Link to="/privacy-policy" className="footer-v2-navlink">
            {props.text2}
          </Link>
        </div>
      </div>
      <div id="mobile-menu" className="footer-v2-mobile">
        <div className="footer-v2-top">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="footer-v2-image"
          />
          <svg
            id="close-mobile-menu"
            viewBox="0 0 1024 1024"
            className="footer-v2-icon"
          >
            <path
              d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
              className=""
            ></path>
          </svg>
        </div>
        <div className="footer-v2-links">
          <span className="footer-v2-text1">{props.text3}</span>
          <span className="footer-v2-text2">{props.text4}</span>
          <span className="footer-v2-text3">{props.text5}</span>
          <span className="footer-v2-text4">{props.text6}</span>
          <div className="footer-v2-buttons">
            <div className="footer-v2-btn">
              <span className="footer-v2-text5">{props.text7}</span>
            </div>
            <div className="footer-v2-btn1">
              <span className="footer-v2-text6">{props.text8}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <DangerousHTML
          html={`<script>
/*
Mobile menu - Code Embed
*/
window.onload = () => {

  // Mobile menu
  const mobileMenu = document.querySelector("#mobile-menu")

  // Buttons
  const closeButton = document.querySelector("#close-mobile-menu")
  const openButton = document.querySelector("#open-mobile-menu")

  // On openButton click, set the mobileMenu position left to -100vw
  openButton?.addEventListener("click", function() {
      mobileMenu.style.transform = "translateX(0%)"
  })

  // On closeButton click, set the mobileMenu position to 0vw
  closeButton?.addEventListener("click", function() {
      mobileMenu.style.transform = "translateX(100%)"
  })
}
</script>`}
          className=""
        ></DangerousHTML>
      </div>
    </nav>
  )
}

FooterV2.defaultProps = {
  rootClassName: '',
  text2: 'Privacy Policy',
  text6: 'Contact',
  image_src: 'https://play.teleporthq.io/static/svg/default-img.svg',
  text4: 'How it works',
  text7: 'Log in',
  image_alt: 'image',
  text8: 'Sign up',
  text5: 'Prices',
  text3: 'Features',
  text: '©  2023  DecentraLoco',
}

FooterV2.propTypes = {
  rootClassName: PropTypes.string,
  text2: PropTypes.string,
  text6: PropTypes.string,
  image_src: PropTypes.string,
  text4: PropTypes.string,
  text7: PropTypes.string,
  image_alt: PropTypes.string,
  text8: PropTypes.string,
  text5: PropTypes.string,
  text3: PropTypes.string,
  text: PropTypes.string,
}

export default FooterV2
