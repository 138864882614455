import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";
import CountUp, { useCountUp } from "react-countup";
import Footer from "../../components/footer";
import "./build-your-team.css";
import { useUserState } from "../../utils/stateContext";
import { signOut } from "firebase/auth";
import { auth } from "../../database/firebase";
import SupportForm from "../../components/SupportForm";
import LoginUserComponent from "../../components/LoginUserComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
SwiperCore.use([Navigation]);

// import required modules
import SwiperCore, { Autoplay, Navigation } from "swiper";
import useWindowSize from "../../utils/GetWindowSize";
import OurProcess from "../../components/OurProcess";
import Companys from "../../components/companys";
import OurValues from "../../components/OurValues";

const BuildYourTeam = (props) => {
  const navigate = useNavigate();
  const { user, setUser } = useUserState();
  const [show, setShow] = useState(false);
  const partnersSliderRef = useRef(null);
  const size = useWindowSize();

  const gamingCounterRef = useRef(null);
  const talentsCounterRef = useRef(null);
  const clientsCounterRef = useRef(null);
  const { start: startGamingCounter } = useCountUp({
    ref: gamingCounterRef,
    start: 0,
    end: 100,
    duration: 2.75,
  });
  const { start: startTalentsCounter } = useCountUp({
    ref: talentsCounterRef,
    start: 100,
    end: 5000,
    duration: 3.25,
  });
  const { start: startClientsCounter } = useCountUp({
    ref: clientsCounterRef,
    start: 0,
    end: 100,
    duration: 2.75,
  });

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        navigate("/");
      })
      .catch((error) => console.log(error));
  };

  const isScrolledIntoView = (el) => {
    var rect = document.querySelector(el).getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    // Partially visible elements return true:
    // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  };

  let counterStarted = false;
  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (
        !counterStarted &&
        gamingCounterRef &&
        gamingCounterRef.current &&
        document.querySelector("#gamingCounter")
      ) {
        if (isScrolledIntoView("#gamingCounter")) {
          startGamingCounter();
          startTalentsCounter();
          startClientsCounter();
          counterStarted = true;
        }
      }
    });
  }, []);

  return (
    <div className="build-your-team-container">
      <Helmet>
        <title>Build your team Decentraloco</title>
        <meta property="og:title" content="Build your team Decentraloco" />
      </Helmet>
      <nav className="build-your-team-navbar">
        <header
          data-thq="thq-navbar"
          className="build-your-team-navbar-interactive"
        >
          <Link to="/" className="build-your-team-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="build-your-team-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="build-your-team-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="build-your-team-nav"
            >
              <Link
                to="/build-your-team"
                className="build-your-team-navlink1 button"
              >
                Client&apos;s Home
              </Link>
              <Link
                to="/contact-us-client"
                className="build-your-team-navlink2 button"
              >
                Values
              </Link>
              <Link
                to="/our-process"
                className="build-your-team-navlink2 button"
              >
                Our Process
              </Link>
              <Link to="/pricing" className="build-your-team-navlink3 button">
                Pricing
              </Link>
              <Link
                to="/next-steps"
                className="build-your-team-navlink3 button"
              >
                Next Steps
              </Link>
            </nav>
          </div>
          <div
            data-thq="thq-navbar-btn-group"
            className="build-your-team-btn-group"
          >
            <Link
              to="/build-your-team"
              className="build-your-team-login button"
            >
              Hire Talent
            </Link>

            <LoginUserComponent
              user={user}
              handleLogout={handleLogout}
              show={show}
              setShow={setShow}
            ></LoginUserComponent>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="build-your-team-burger-menu"
          >
            <svg viewBox="0 0 1024 1024" className="build-your-team-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="build-your-team-mobile-menu"
          >
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="build-your-team-nav1"
            >
              <div className="build-your-team-container01">
                <Link to="/" className="build-your-team-navlink4">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="build-your-team-finbest1"
                  />
                </Link>
                <div
                  data-thq="thq-close-menu"
                  className="build-your-team-menu-close"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="build-your-team-icon2"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="build-your-team-nav2"
              >
                <Link
                  to="/build-your-team"
                  className="build-your-team-navlink5"
                >
                  Client&apos;s Home
                </Link>
                <Link
                  to="/contact-us-client"
                  className="build-your-team-navlink6"
                >
                  Values
                </Link>
                <Link to="/our-process" className="build-your-team-navlink6">
                  Our Process
                </Link>
                <Link to="/pricing" className="build-your-team-navlink7">
                  Pricing
                </Link>
                <Link
                  to="/next-steps"
                  className="build-your-team-navlink3 button"
                >
                  Next Steps
                </Link>
              </nav>
              <div className="build-your-team-container02">
                <LoginUserComponent
                  user={user}
                  handleLogout={handleLogout}
                  show={show}
                  setShow={setShow}
                ></LoginUserComponent>

                <Link
                  to="/client-step-0"
                  className="build-your-team-login1 button"
                >
                  Hire Talent
                </Link>
              </div>
            </div>
          </div>
        </header>
      </nav>
      <div className="build-your-team-hero">
        <div className="build-your-team-container03">
          <h2 className="build-your-team-text06">For Companies</h2>
          <h2 className="build-your-team-text07">
            <span>Hire Talent that brings </span>
            <span>
              your
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="build-your-team-text10">Vision</span>
            <span>
              {" "}
              to
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="build-your-team-text12">Life</span>
          </h2>
          <span className="build-your-team-text13">
            <span>
              Hire top-talent with a proven track record of technical expertise
              and exceptional communication skills, available to work with your
              timezone.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <br></br>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </span>
          <div className="build-your-team-btn-group1">
            <Link
              to="/client-step-0"
              className="build-your-team-navlink8 button"
            >
              <span className="build-your-team-text18">Book a Meeting</span>
            </Link>
          </div>
          <div className="build-your-team-btn-group2">
            <div className="build-your-team-container04">
              <div className="build-your-team-container05">
                <span className="build-your-team-text19">
                  <span className="build-your-team-text21">
                    <CountUp duration={5.75} start={100} end={5000}></CountUp>
                  </span>
                  <span>+</span>
                </span>
                <span className="build-your-team-text22">
                  Active Candidates
                </span>
              </div>
              <div className="build-your-team-container06">
                <span className="build-your-team-text23">
                  <span className="build-your-team-text25">
                    <CountUp duration={5.5} start={1} end={100}></CountUp>
                  </span>
                  <span>+</span>
                </span>
                <span className="build-your-team-text26">
                  Completed Projects
                </span>
              </div>
              <div className="build-your-team-container07">
                <span className="build-your-team-text27">
                  <span className="build-your-team-text28">
                    <CountUp duration={4.75} start={1} end={100}></CountUp>
                  </span>
                  <span>{"  %"}</span>
                </span>
                <span className="build-your-team-text30">
                  Satisfied Clients
                </span>
              </div>
            </div>
          </div>
          <div className="build-your-team-container08">
            <span className="build-your-team-text31">We accept: </span>
            <div className="build-your-team-container09">
              <img
                alt="image"
                src="/playground_assets/btc.svg"
                className="build-your-team-image01"
              />
              <img
                alt="image"
                src="/playground_assets/eth.svg"
                className="build-your-team-image02"
              />
              <img
                alt="image"
                src="/playground_assets/metamask-fox.svg"
                className="build-your-team-image03"
              />
              <img
                alt="image"
                src="/playground_assets/bnb.svg"
                className="build-your-team-image04"
              />
              <img
                alt="image"
                src="/playground_assets/tether-usdt-logo.png"
                className="build-your-team-image05"
                style={{ backgroundColor: "#408C77" }}
              />
            </div>
            <span className="build-your-team-text32">...</span>
          </div>
        </div>
        <img
          src="/playground_assets/art-work-002-1500h.png"
          loading="lazy"
          className="build-your-team-image06"
        />
      </div>
      {/* <OurProcess /> */}
      <span className="white-text">
        Our platform lets projects scale tech teams at speed, saving thousands
        and introducing the top 10% of niche tech talent to their interview
        pipelines.
        <span
          dangerouslySetInnerHTML={{
            __html: " ",
          }}
        />
      </span>
      <br />
      <span>
        <span
          dangerouslySetInnerHTML={{
            __html: " ",
          }}
        />
      </span>
      <span className="white-text">
        For our talent, we provide a growth mindset community, resources, and
        exposure to exciting projects in Blockchain, Gaming, and AI.
      </span>
      <br />
      <span className="white-text">
        The only thing worse than not making your hire is making the wrong hire,
        the time, cost, and resources for a bad hire can be fatal for a
        start-up.
      </span>
      <br />
      <span className="white-text">
        We help our partners find the right candidate, the first time, every
        time.
      </span>
      <br />
      {/* <span className="white-text">
        We work with early-stage start-ups, hypergrowth tech projects, Gaming
        Studios, and beyond.
      </span>
      <br />
      <span className="white-text">
        Essentially, awesome projects that build cool 💩 in Blockchain, Gaming,
        and AI.
      </span>
      <br />
      <span className="white-text">You build awesome technology.</span>
      <br />

      <span className="white-text">We build awesome teams.</span>
      <br />
      <span className="white-text">Together we'll build the future.</span>
      <br />
      <span className="white-text">
        Our boutique size means we are big enough to compete globally.
      </span>
      <br />
      <span className="white-text">But small enough to care individually.</span>
      <br />
      <span className="white-text">A global brand with personal service.</span>
      <br />
      <span className="white-text">Top Talent is what we sell.</span>
      <br />
      <span className="white-text">
        And providing tools, resources, and opportunity is what we do.
      </span>
      <br />
      <span className="white-text">Welcome to our ecosystem 🫡</span>
      <br /> */}

      <div className="build-your-team-stats">
        <span className="build-your-team-text42">
          <span className="build-your-team-text43">
            Our
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="build-your-team-text44">Stats</span>
        </span>
        <div className="build-your-team-container12">
          <div className="build-your-team-stat">
            <span className="build-your-team-caption">
              +<span id="gamingCounter" ref={gamingCounterRef}></span>
            </span>
            <span className="build-your-team-description6">
               Completed projects in Blockchain, Gaming, AI, UX/UI & more
            </span>
          </div>
          <div className="home-stat1">
            <span className="home-caption1">
              +<span ref={talentsCounterRef}></span>
            </span>
            <span className="home-description1">
              Top-tech talent ready to work on your
              <br /> project
            </span>
          </div>
          <div className="build-your-team-stat2">
            <span className="build-your-team-caption2">
              <span ref={clientsCounterRef}></span>%
            </span>
            <span className="build-your-team-description8">
              <span className="build-your-team-text45">
                Satisfied clients working with Decentraloco
              </span>
              <br className="build-your-team-text46"></br>
              <span className="build-your-team-text47">
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </div>
        </div>
      </div>
      <SupportForm home />
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  );
};

export default BuildYourTeam;
