import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import "./blogs.css";
import { signOut } from "firebase/auth";
import { auth } from "../../database/firebase";
import { useUserState } from "../../utils/stateContext";
import LoginUserComponent from "../../components/LoginUserComponent";

const Blogs = (props) => {
  const navigate = useNavigate();
  const { user, setUser } = useUserState();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser({});
        navigate("/");
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="blogs-container">
      <Helmet>
        <title>Blogs Decentraloco</title>
        <meta property="og:title" content="Blogs Decentraloco" />
      </Helmet>
      <nav className="blogs-navbar">
        <header data-thq="thq-navbar" className="blogs-navbar-interactive">
          <Link to="/" className="blogs-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="blogs-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="blogs-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="blogs-nav"
            >
              <Link to="/" className="blogs-navlink1 button">
                Home
              </Link>
              <Link to="/about-us" className="home-navlink02 button">
                About Us
              </Link>
              <Link to="/contact-us" className="blogs-navlink2 button">
                Values
              </Link>
              <Link to="/blogs" className="blogs-navlink3 button">
                Blog
              </Link>
            </nav>
          </div>
          <div data-thq="thq-navbar-btn-group" className="blogs-btn-group">
            <Link to="/apply-as-a-candidate" className="blogs-login button">
              Apply as a Talent
            </Link>
            <Link to="/build-your-team" className="blogs-login1 button">
              Hire Talent
            </Link>
            <LoginUserComponent
              user={user}
              handleLogout={handleLogout}
              show={show}
              setShow={setShow}
            ></LoginUserComponent>
          </div>
          <div data-thq="thq-burger-menu" className="blogs-burger-menu">
            <svg viewBox="0 0 1024 1024" className="blogs-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="blogs-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="blogs-nav1"
            >
              <div className="blogs-container01">
                <Link to="/" className="blogs-navlink4">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="blogs-finbest1"
                  />
                </Link>
                <div data-thq="thq-close-menu" className="blogs-menu-close">
                  <svg viewBox="0 0 1024 1024" className="blogs-icon2">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="blogs-nav2"
              >
                <Link to="/" className="blogs-navlink5">
                  Home
                </Link>
                <Link to="/about-us" className="home-navlink02 button">
                  About Us
                </Link>
                <Link to="/contact-us" className="blogs-navlink6">
                  Values
                </Link>
                <Link to="/blogs" className="blogs-navlink7">
                  Blog
                </Link>
              </nav>
              <div className="blogs-container02">
                <Link
                  to="/apply-as-a-candidate"
                  className="blogs-login2 button"
                >
                  Apply as a Talent
                </Link>

                <LoginUserComponent
                  user={user}
                  handleLogout={handleLogout}
                  show={show}
                  setShow={setShow}
                ></LoginUserComponent>

                <Link to="/build-your-team" className="blogs-login3 button">
                  Hire Talent
                </Link>
              </div>
            </div>
          </div>
        </header>
      </nav>
      <div className="blogs-container03">
        <div className="blogs-banner">
          <h1 className="blogs-text06">
            <span className="blogs-text07">F</span>
            <span>
              rom The
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="blogs-text09">B</span>
            <span>log</span>
            <br></br>
          </h1>
          <span className="blogs-text12">
            <span>News & Updates Across Blockchain, Gaming, & AI</span>
            <br></br>
          </span>
        </div>
        <div className="blogs-separator"></div>
        <div className="blogs-blog">
          <div className="blogs-container04">
            <div className="blogs-container05">
              <div className="blogs-container06">
                <a
                  href="https://medium.com/@jifure/blast-l2-airdrop-how-to-get-blast-invite-e4e6256b0882"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link"
                >
                  <div className="blogs-blog-post-card">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:4800/format:webp/1*fEFjaCbgnfe6hzyztXuYYA.jpeg"
                      className="blogs-image01"
                    />
                    <div className="blogs-container07">
                      <span className="blogs-text16">A.I.</span>
                      <span className="blogs-text17">
                        <span>BLAST L2 Airdrop: How to get BLAST invite?</span>
                        <br className="blogs-text19"></br>
                        <span>
                          <span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: " ",
                              }}
                            />
                          </span>
                          <span>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: " ",
                              }}
                            />
                          </span>
                        </span>
                      </span>
                      <span className="blogs-text23">
                        <span>
                          A newly introduced Ethereum layer 2 offering called
                          Blast is swiftly gaining attention for its closed,
                          invite-only early access campaign prior to public
                          launch. Created by Blur Platform founder “Pacman,”
                          Blast has already brought on board investments from
                          top crypto funds like Paradigm and eGirl Capital.This
                          guide covers everything currently known regarding
                          receiving access to join the private Blast community
                          and airdrop initiative...
                        </span>
                        <br></br>
                      </span>
                      <div className="blogs-container08">
                        <span className="blogs-text26">2 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="blogs-container09">
              <div className="blogs-container10">
                <a
                  href="https://medium.com/yield-guild-games/9-top-web3-games-in-the-ygg-ecosystem-0f1a9daedb57"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link01"
                >
                  <div className="blogs-blog-post-card01">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*xp3kJKlCvE_0Z_DV"
                      className="blogs-image02"
                    />
                    <div className="blogs-container11">
                      <span className="blogs-text27">Web3 Gaming</span>
                      <span className="blogs-text28">
                        <span>
                          <span>
                            9 Top Web3 Games in the YGG Ecosystem
                            <span
                              dangerouslySetInnerHTML={{
                                __html: " ",
                              }}
                            />
                          </span>
                        </span>
                      </span>
                      <span className="blogs-text36">
                        Entering another exciting year, the YGG community
                        continues to show their support for the most promising
                        games in the guild’s ecosystem. Some of these games...
                      </span>
                      <div className="blogs-container12">
                        <span className="blogs-text37">12 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="blogs-container13">
                <a
                  href="https://medium.com/crypto-hub-launchpad/areon-network-introducing-a-new-metaverse-paradigm-in-blockchain-313c5e0d73b1"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link02"
                >
                  <div className="blogs-blog-post-card02">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*QKMlM109HGV2tiw5zOx_bA.png"
                      className="blogs-image03"
                    />
                    <div className="blogs-container14">
                      <span className="blogs-text38">Blockchain</span>
                      <span className="blogs-text39">
                        Areon Network | Introducing a New Metaverse...
                      </span>
                      <span className="blogs-text40">
                        <span>
                          Areon Network sets itself apart not only with its
                          innovative Proof of AREA consensus mechanism but also
                          its groundbreaking velocity, achieving an impressive
                          throughput...
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span>
                      </span>
                      <div className="blogs-container15">
                        <span className="blogs-text43">2 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="blogs-container16">
                <a
                  href="https://medium.com/the-quantified-world/ps5-pro-unleashed-unraveling-the-future-of-console-gaming-e1981a7c812b"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link03"
                >
                  <div className="blogs-blog-post-card03">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:2854/format:webp/1*M7TYgTyP1Wx3nSOOwlzxYw.jpeg"
                      className="blogs-image04"
                    />
                    <div className="blogs-container17">
                      <span className="blogs-text44">
                        <span>Gaming</span>
                        <br></br>
                      </span>
                      <span className="blogs-text47">
                        <span>
                          <span>
                            PS5 Pro Unleashed: Unraveling the Future of Console
                            Gaming
                            <span
                              dangerouslySetInnerHTML={{
                                __html: " ",
                              }}
                            />
                          </span>
                        </span>
                      </span>
                      <span className="blogs-text52">
                        If you’re a dedicated gamer, I bet you’re always on the
                        hunt for the next level of gameplay experience, aren’t
                        you? Well, your vigilant pursuit might just be
                        rewarded...
                      </span>
                      <div className="blogs-container18">
                        <span className="blogs-text53">6 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="blogs-container19">
              <div className="blogs-container20">
                <a
                  href="https://medium.com/@ignacio.de.gregorio.noblejas/microsofts-100-billion-bet-on-openai-is-stupid-unless-5dc03d9c0ec9"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link04"
                >
                  <div className="blogs-blog-post-card04">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:3584/format:webp/0*H1SVF7_4JxC7-uRa"
                      className="blogs-image05"
                    />
                    <div className="blogs-container21">
                      <span className="blogs-text54">A.I.</span>
                      <span className="blogs-text55">
                        Microsoft’s $100 Billion Bet on OpenAI Is Stupid.
                        Unless…
                      </span>
                      <span className="blogs-text56">
                        An expensive supercomputer built in the Arizona desert
                        by a multibillion-dollar corporation in order to create
                        superintelligence. Sounds like a movie (or we wish it
                        was) but it seems it’s something that ....
                      </span>
                      <div className="blogs-container22">
                        <span className="blogs-text57">9 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="blogs-container23">
                <a
                  href="https://medium.com/@thredmedia/whats-up-with-the-constant-gaming-layoffs-in-2024-5b2fe9a3139e"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link05"
                >
                  <div className="blogs-blog-post-card05">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*-C1k1ZSF0QcdHHhF.png"
                      className="blogs-image06"
                    />
                    <div className="blogs-container24">
                      <span className="blogs-text58">Gaming</span>
                      <span className="blogs-text59">
                        What’s up with the constant gaming layoffs...
                      </span>
                      <span className="blogs-text60">
                        <span>
                          The gaming industry has never exactly been synonymous
                          with job security, but what is causing this
                          unprecedented amount of layoffs in 2024?
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span>
                      </span>
                      <div className="blogs-container25">
                        <span className="blogs-text63">4 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="blogs-container26">
                <a
                  href="https://medium.com/invest-gaming-journal/decoding-bitcoin-etfs-recent-negative-flows-gbtc-losses-600m-2d29777a77a9"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link06"
                >
                  <div className="blogs-blog-post-card06">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*2hN8OdjgJwdfNpOjSV9tRQ.jpeg"
                      className="blogs-image07"
                    />
                    <div className="blogs-container27">
                      <span className="blogs-text64"> Blockchain</span>
                      <span className="blogs-text65">
                        {" "}
                        Decoding bitcoin ETFs recent negative flows ...
                      </span>
                      <span className="blogs-text66">
                        Look into the recent trend of Bitcoin ETFs experiencing
                        negative flows, with GBTC losing a significant amount in
                        a day. Bitcoin ETFs, or Exchange-Traded Funds, are
                        exchange ...
                      </span>
                      <div className="blogs-container28">
                        <span className="blogs-text67">3 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="blogs-container29">
              <div className="blogs-container30">
                <a
                  href="https://medium.com/@playhoneyland/new-honeyland-update-c09a79894192"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link07"
                >
                  <div className="blogs-blog-post-card07">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*ChHKP28w7CFwsuv9KBnHWw.jpeg"
                      className="blogs-image08"
                    />
                    <div className="blogs-container31">
                      <span className="blogs-text68">Web3 Gaming</span>
                      <span className="blogs-text69">New Honeyland Update</span>
                      <span className="blogs-text70">
                        Honeyland will be unavailable on April 6th from 12:30pm
                        to approximately 4:30pm UTC to perform needed
                        maintenance for the launch of version 51. REMINDER: Any
                        unclaimed rewards that are over two weeks old will be
                        automatically claimed ...
                      </span>
                      <div className="blogs-container32">
                        <span className="blogs-text71">3 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="blogs-container33">
                <a
                  href="https://medium.com/@tonylixu/openai-best-practices-of-using-tokens-6709e1df6cc5"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link08"
                >
                  <div className="blogs-blog-post-card08">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*FrFq_onziPa4yGi_BD3llw.png"
                      className="blogs-image09"
                    />
                    <div className="blogs-container34">
                      <span className="blogs-text72">A.I.</span>
                      <span className="blogs-text73">
                        OpenAI — Best Practices of Using Tokens
                      </span>
                      <span className="blogs-text74">
                        In the realm of OpenAI’s advanced language models, such
                        as GPT-3.5 and GPT-4, the term “token” refers to a
                        sequence of characters that commonly appear together in
                        a text. These models are ...
                      </span>
                      <div className="blogs-container35">
                        <span className="blogs-text75">6 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="blogs-container36">
                <a
                  href="https://medium.com/unique-network/unique-collaborates-with-dega-for-the-benefit-of-builders-on-the-unique-ecosystem-b785063def83"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link09"
                >
                  <div className="blogs-blog-post-card09">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:720/format:webp/0*GfJr631DZL5_IduI.jpg"
                      className="blogs-image10"
                    />
                    <div className="blogs-container37">
                      <span className="blogs-text76">Web3 Gaming</span>
                      <span className="blogs-text77">
                        Unique Collaborates with DEGA for the Benefit...
                      </span>
                      <span className="blogs-text78">
                        We’re excited to announce our integration with DEGA, a
                        platform offering scalable infrastructure and rapid
                        transactions for Web3 gaming and the Metaverse...
                      </span>
                      <div className="blogs-container38">
                        <span className="blogs-text79">3 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="blogs-container39">
              <div className="blogs-container40">
                <a
                  href="https://medium.com/@stakingbits/move-crypto-from-exchanges-to-l2-blockchains-up-to-10x-cheaper-with-layerswap-e1a54f41e6a0"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link10"
                >
                  <div className="blogs-blog-post-card10">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*DcCQp1o_BJMogSs3Oy0TEw.png"
                      className="blogs-image11"
                    />
                    <div className="blogs-container41">
                      <span className="blogs-text80">Blockchain</span>
                      <span className="blogs-text81">
                        Move crypto from exchanges to L2 blockchains up to
                        10x... 
                      </span>
                      <span className="blogs-text82">
                        LayerSwap is a protocol that allows users to directly
                        send cryptocurrencies (e.g. ETH, USDT) from centralised
                        exchanges to various Layer 2 networks with low fees.
                      </span>
                      <div className="blogs-container42">
                        <span className="blogs-text83">2 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="blogs-container43">
                <a
                  href="https://medium.com/@MaplestoryU/maplestory-universe-gdc-2024-session-full-script-a1a19b06ab25"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link11"
                >
                  <div className="blogs-blog-post-card11">
                    <img
                      alt="image"
                      src="https://miro.medium.com/v2/resize:fit:720/format:webp/1*xrofjpd5lf6FAEH6riZ-4w.png"
                      className="blogs-image12"
                    />
                    <div className="blogs-container44">
                      <span className="blogs-text84">Gaming</span>
                      <span className="blogs-text85">
                        MapleStory Universe GDC 2024 Session Full Script
                      </span>
                      <span className="blogs-text86">
                        <span>
                          This is the full script for MapleStory Universe’s GDC
                          session “MapleStory Universe: Bringing the Real World
                          Economy to MMORPG”. Hi everyone, I’m Keith, head of
                          strategy at MapleStory...
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span>
                        <span>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span>
                      </span>
                      <div className="blogs-container45">
                        <span className="blogs-text89">16 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              {/* <div className="blogs-container46">
                <a
                  href="https://medium.com/@seedifyfund/ideathon-for-nft-gaming-with-sfund-rewards-seed-funding-3fb24dbadf3e"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="blogs-link12"
                >
                  <div className="blogs-blog-post-card12">
                    <img
                      alt="image"
                      src="https://miro.medium.com/max/720/1*yisfzUSB7nb0HdghpvNQ4Q.webp"
                      className="blogs-image13"
                    />
                    <div className="blogs-container47">
                      <span className="blogs-text90">NFT</span>
                      <span className="blogs-text91">
                        Ideathon for NFT-Gaming with SFUND
                      </span>
                      <span className="blogs-text92">
                        The Ideathon on NFT-Gaming will start on the 15th of May
                        and will last for 2 weeks, and until then we will market
                        and promote this event so we can bring a lot more
                        innovators who can compete...
                      </span>
                      <div className="blogs-container48">
                        <span className="blogs-text93">4 min read</span>
                      </div>
                    </div>
                  </div>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name4"></Footer>
    </div>
  );
};

export default Blogs;
