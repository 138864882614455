import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";
import CountUp, { useCountUp } from "react-countup";
import Footer from "../../components/footer";
import "./our-process-page.css";
import { useUserState } from "../../utils/stateContext";
import { signOut } from "firebase/auth";
import { auth } from "../../database/firebase";
import SupportForm from "../../components/SupportForm";
import LoginUserComponent from "../../components/LoginUserComponent";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
SwiperCore.use([Navigation]);

// import required modules
import SwiperCore, { Autoplay, Navigation } from "swiper";
import useWindowSize from "../../utils/GetWindowSize";
import OurProcess from "../../components/OurProcess";

const OurProcessPage = (props) => {
  const navigate = useNavigate();
  const { user, setUser } = useUserState();
  const [show, setShow] = useState(false);
  const partnersSliderRef = useRef(null);
  const size = useWindowSize();

  const gamingCounterRef = useRef(null);
  const talentsCounterRef = useRef(null);
  const clientsCounterRef = useRef(null);
  const { start: startGamingCounter } = useCountUp({
    ref: gamingCounterRef,
    start: 0,
    end: 300,
    duration: 2.75,
  });
  const { start: startTalentsCounter } = useCountUp({
    ref: talentsCounterRef,
    start: 100,
    end: 10000,
    duration: 3.25,
  });
  const { start: startClientsCounter } = useCountUp({
    ref: clientsCounterRef,
    start: 0,
    end: 100,
    duration: 2.75,
  });

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        navigate("/");
      })
      .catch((error) => console.log(error));
  };

  const isScrolledIntoView = (el) => {
    var rect = document.querySelector(el).getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    // Partially visible elements return true:
    // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
    return isVisible;
  };

  let counterStarted = false;
  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (
        !counterStarted &&
        gamingCounterRef &&
        gamingCounterRef.current &&
        document.querySelector("#gamingCounter")
      ) {
        if (isScrolledIntoView("#gamingCounter")) {
          startGamingCounter();
          startTalentsCounter();
          startClientsCounter();
          counterStarted = true;
        }
      }
    });
  }, []);

  return (
    <div className="our-process-container">
      <Helmet>
        <title>Our process Decentraloco</title>
        <meta property="og:title" content="Our process Decentraloco" />
      </Helmet>
      <nav className="our-process-navbar">
        <header
          data-thq="thq-navbar"
          className="our-process-navbar-interactive"
        >
          <Link to="/" className="our-process-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="our-process-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="our-process-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="our-process-nav"
            >
              <Link
                to="/build-your-team"
                className="our-process-navlink1 button"
              >
                Client&apos;s Home
              </Link>
              <Link
                to="/contact-us-client"
                className="our-process-navlink2 button"
              >
                Values
              </Link>
              <Link to="/our-process" className="our-process-navlinkk4 button">
                Our Process
              </Link>
              <Link to="/pricing" className="our-process-navlink3 button">
                Pricing
              </Link>
              <Link
                to="/next-steps"
                className="build-your-team-navlink3 button"
              >
                Next Steps
              </Link>
            </nav>
          </div>
          <div
            data-thq="thq-navbar-btn-group"
            className="our-process-btn-group"
          >
            <Link to="/build-your-team" className="our-process-login button">
              Hire Talent
            </Link>

            <LoginUserComponent
              user={user}
              handleLogout={handleLogout}
              show={show}
              setShow={setShow}
            ></LoginUserComponent>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="build-your-team-burger-menu"
          >
            <svg viewBox="0 0 1024 1024" className="our-process-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="build-your-team-mobile-menu"
          >
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="our-process-nav1"
            >
              <div className="our-process-container01">
                <Link to="/" className="our-process-navlink4">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="our-process-finbest1"
                  />
                </Link>
                <div
                  data-thq="thq-close-menu"
                  className="our-process-menu-close"
                >
                  <svg viewBox="0 0 1024 1024" className="our-process-icon2">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="our-process-nav2"
              >
                <Link
                  to="/build-your-team"
                  className="our-process-navlink5 button"
                >
                  Client&apos;s Home
                </Link>
                <Link
                  to="/contact-us-client"
                  className="our-process-navlink5 button"
                >
                  Values
                </Link>
                <Link
                  to="/our-process"
                  className="our-process-navlink55 button"
                >
                  Our Process
                </Link>
                <Link to="/pricing" className="our-process-navlink5 button">
                  Pricing
                </Link>
                <Link
                  to="/next-steps"
                  className="build-your-team-navlink3 button"
                >
                  Next Steps
                </Link>
              </nav>
              <div className="our-process-container02">
                <LoginUserComponent
                  user={user}
                  handleLogout={handleLogout}
                  show={show}
                  setShow={setShow}
                ></LoginUserComponent>

                <Link
                  to="/build-your-team"
                  className="our-process-login1 button"
                >
                  Hire Talent
                </Link>
              </div>
            </div>
          </div>
        </header>
      </nav>
      <OurProcess />
      <Footer rootClassName="footer-root-class-name"></Footer>
    </div>
  );
};

export default OurProcessPage;
