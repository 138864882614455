import React from "react";

const Step3Item = ({
  title,
  text,
  index,
  setValue,
  setLevel,
  level,
  register,
  name,
}) => {
  return (
    <div>
      <div
        style={{
          borderColor: level === title && "#6741ffff",
          cursor: "pointer",
        }}
        className={
          "candidate-step3-container-custom active-quote " +
          (index === 0 ? "dl-top-threeunits" : "")
        }
        onClick={() => {
          setLevel(title);
          setValue(name, title);
        }}
      >
        <input
          {...register(name)}
          type="radio"
          name={name}
          value={title}
          style={{ display: "none" }}
        />
        <span className="candidate-step3-text11">{title}</span>
        <span className="candidate-step3-text12">{text}</span>
      </div>
    </div>
  );
};

export default Step3Item;
