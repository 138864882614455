import React from "react";

import { Helmet } from "react-helmet";

import "./error-page.css";
import { useNavigate } from "react-router-dom";

const ErrorPage = (props) => {
  const navigate = useNavigate();
  return (
    <div className="error-page-container">
      <Helmet>
        <title>Error Page Decentraloco</title>
        <meta property="og:title" content="Error Page Decentraloco" />
      </Helmet>
      <div className="error-page-hero">
        <div className="error-page-container1">
          <h1 className="error-page-text">Whoops!</h1>
          <h1 className="error-page-text1">Looks like you’re lost</h1>
          <div className="error-page-container2"></div>
          <h2 className="error-page-text2">
            <span>The page you’re looking for isn’t found :(</span>
            <br></br>
            <span>We suggest you back to Home</span>
          </h2>
          <div className="error-page-btn-group">
            <button
              className="error-page-button button"
              onClick={() => navigate("/")}
            >
              <span className="error-page-text6">Back to Home</span>
            </button>
          </div>
        </div>
        <img
          alt="image"
          src="/playground_assets/art-work%20404.svg"
          className="error-page-image"
        />
      </div>
    </div>
  );
};

export default ErrorPage;
