import React, { useState, useEffect, useContext, Fragment } from "react";

/// React router dom
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";

import Application from "./pages/Dashboard/Application";
import DashboardHome from "./pages/Dashboard/DashboardHome";
import CandidateProfile from "./pages/Dashboard/CandidateProfile";
import ClientProfile from "./pages/Dashboard/ClientProfile";
import DashboardSettings from "./pages/Dashboard/DashboardSettings";
import DashboardJobs from "./pages/Dashboard/DashboardJobs";
import Candidates from "./pages/Dashboard/Candidates";
import Clients from "./pages/Dashboard/Clients";

import ModalExample from "./components/bootstrap/Modal";

import { useUserState } from "../utils/stateContext";
// import Statistics from "./components/Dashboard/Statistics";
// import Companies from "./components/Dashboard/Companies";

import AdminProtectedRoute from "./utils/AdminProtectedRoutes";
import { useNavigation, useNavigate } from "react-router-dom";

const DashboardViews = () => {
  const navigate = useNavigate();
  const { user, setUser, logout } = useUserState();
  // const route = useNavigation();
  let path = window?.location?.pathname;
  path = path?.split("/")?.includes("admin") ? path?.split("/") : false;
  path = path ? path[path.length - 1] : false;
  let pagePath = path;

  const [currentPageTitle, setCurrentPageTitle] = useState("Dashboard");

  console.log("path", window?.location?.pathname);
  console.log("pagePath", pagePath);

  const [activeEvent, setActiveEvent] = useState(null);
  const [toggle, setToggle] = useState(false);

  const handleLogout = () => {
    logout().then(() => {
      setUser(null);
      navigate("/decentradmin/login");
    });
  };

  return (
    <AdminProtectedRoute>
      <div
        id={`${!pagePath ? "main-wrapper" : "main-wrapper"}`}
        className={`${!pagePath ? "show" : "show mh100vh"}`}
      >
        {
          <Nav
            // onClick={() => setActiveEvent(!activeEvent)}
            // activeEvent={activeEvent}
            // onClick2={() => setActiveEvent(false)}
            // onClick3={() => setActiveEvent(true)}
            setToggle={setToggle}
            toggle={toggle}
            currentPageTitle={currentPageTitle}
            handleLogout={handleLogout}
          />
        }
        <div
          className="content-body"
          style={{ marginLeft: toggle ? "0px" : "15rem" }}
        >
          <div
            className={`${!pagePath ? "container-fluid" : "container-fluid"}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Routes>
              {/* <Route exact path={`/profile`} element={<Profile></Profile>} /> */}
              <Route
                exact
                path={`/dashboard`}
                element={<DashboardHome></DashboardHome>}
              />
              <Route
                exact
                path={`/settings`}
                element={<DashboardSettings></DashboardSettings>}
              />
              <Route
                exact
                path={`/jobs`}
                element={<DashboardJobs></DashboardJobs>}
              />
              <Route
                exact
                path={`/candidates`}
                element={
                  <Candidates
                    setCurrentPageTitle={setCurrentPageTitle}
                  ></Candidates>
                }
              />
              <Route
                exact
                path={`/clients`}
                element={
                  <Clients setCurrentPageTitle={setCurrentPageTitle}></Clients>
                }
              />
              <Route
                exact
                path={`/candidate/:candidateId`}
                element={
                  <CandidateProfile
                    setCurrentPageTitle={setCurrentPageTitle}
                  ></CandidateProfile>
                }
              />
              <Route
                exact
                path={`/client/:clientId`}
                element={
                  <ClientProfile
                    setCurrentPageTitle={setCurrentPageTitle}
                  ></ClientProfile>
                }
              />
              {/* <Route exact path={`/modal`} element={<ModalExample></ModalExample>}/> */}
            </Routes>
          </div>
        </div>
        {<Footer />}
      </div>
    </AdminProtectedRoute>
  );
};

export default DashboardViews;
