import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import "./pricing.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
SwiperCore.use([Navigation]);
import useWindowSize from "../../utils/GetWindowSize";
import LoginUserComponent from "../../components/LoginUserComponent";
import { useUserState } from "../../utils/stateContext";
// import required modules
import SwiperCore, { Autoplay, Navigation } from "swiper";
import SupportForm from "../../components/SupportForm";
import Companys from "../../components/companys";

const Pricing = (props) => {
  const partnersSliderRef = useRef(null);
  const size = useWindowSize();
  const { user, setUser, logout } = useUserState();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    logout()
      .then(() => {
        setUser(null);
        setShow(false);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="pricing-container">
      <Helmet>
        <title>Pricing Decentraloco</title>
        <meta property="og:title" content="Pricing Decentraloco" />
      </Helmet>
      <nav className="pricing-navbar">
        <header data-thq="thq-navbar" className="pricing-navbar-interactive">
          <Link to="/" className="pricing-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="pricing-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="pricing-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="pricing-nav"
            >
              <Link to="/build-your-team" className="pricing-navlink01 button">
                Client&apos;s Home
              </Link>
              <Link
                to="/contact-us-client"
                className="pricing-navlink02 button"
              >
                Values
              </Link>
              <Link to="/our-process" className="pricing-navlinkk04 button">
                Our Process
              </Link>
              <Link to="/pricing" className="pricing-navlink03 button">
                Pricing
              </Link>
              <Link
                to="/next-steps"
                className="build-your-team-navlink3 button"
              >
                Next Steps
              </Link>
            </nav>
          </div>
          <div data-thq="thq-navbar-btn-group" className="pricing-btn-group">
            <Link to="/build-your-team" className="pricing-login button">
              Hire Talent
            </Link>

            <LoginUserComponent
              user={user}
              handleLogout={handleLogout}
              show={show}
              setShow={setShow}
            ></LoginUserComponent>
          </div>
          <div data-thq="thq-burger-menu" className="pricing-burger-menu">
            <svg viewBox="0 0 1024 1024" className="pricing-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div data-thq="thq-mobile-menu" className="pricing-mobile-menu">
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="pricing-nav1"
            >
              <div className="pricing-container01">
                <Link to="/" className="pricing-navlink04">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="pricing-finbest1"
                  />
                </Link>
                <div data-thq="thq-close-menu" className="pricing-menu-close">
                  <svg viewBox="0 0 1024 1024" className="pricing-icon2">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="pricing-nav2"
              >
                <Link to="/build-your-team" className="pricing-navlink05">
                  Client&apos;s Home
                </Link>
                <Link to="/contact-us-client" className="pricing-navlink06">
                  Values
                </Link>
                <Link to="/our-process" className="build-your-team-navlink6">
                  Our Process
                </Link>
                <Link to="/pricing" className="pricing-navlink07">
                  Pricing
                </Link>
                <Link
                  to="/next-steps"
                  className="build-your-team-navlink3 button"
                >
                  Next Steps
                </Link>
              </nav>
              <div className="pricing-container02">
                <LoginUserComponent
                  user={user}
                  handleLogout={handleLogout}
                  show={show}
                  setShow={setShow}
                ></LoginUserComponent>

                <Link to="/build-your-team" className="pricing-login1 button">
                  Hire Talent
                </Link>
              </div>
            </div>
          </div>
        </header>
      </nav>
      <div className="pricing-banner">
        <h1 className="pricing-text006">
          <span className="pricing-text007">Ready to get </span>
          <span className="pricing-text008">Started</span>
          <span className="pricing-text009">?</span>
          <br></br>
        </h1>
        <span className="pricing-text011">
          Choose a plan trailored to your needs
        </span>
      </div>
      <div className="pricing-separator"></div>
      <div className="pricing-pricing">
        <div className="pricing-container03">
          <div className="pricing-container04">
            <div className="pricing-container05">
              <div className="pricing-pricing-card">
                <div className="pricing-container06">
                  <span className="pricing-text012">Job Board</span>
                  <span className="pricing-tagline-h pricing-tagline">
                    Forever Free
                  </span>
                  <span className="pricing-text013">
                    <span>Everything You Need To Start Hiring</span>
                    <br></br>
                  </span>
                </div>
                <div className="pricing-separator1"></div>
                <div className="pricing-container07">
                  <span className="pricing-text016">
                    <span className="pricing-text017">✔</span>
                    <span>  Free To Use</span>
                  </span>
                  <span className="pricing-text019">
                    <span className="pricing-text020">✔</span>
                    <span>  5,000+ Active Candidates</span>
                  </span>
                  <span className="pricing-text022">
                    <span className="pricing-text023">✔</span>
                    <span>  Job Add Templates</span>
                  </span>
                  <span className="pricing-text025">
                    <span className="pricing-text026">✔</span>
                    <span>  Screening Questions</span>
                  </span>
                  <span className="pricing-text028">
                    <span className="pricing-text029">✔</span>
                    <span>  Social Media Posts</span>
                  </span>
                </div>
                <div className="pricing-separator2"></div>
                {/* <div className="pricing-container08">
                  <span className="pricing-text031">Custom</span>
                  <span className="pricing-text032">
                    tailored to your needs
                  </span>
                </div> */}
                <Link
                  to="/client-join-waitlist"
                  className="pricing-navlink08 button"
                >
                  <span className="pricing-text033">Join Waitlist</span>
                </Link>
              </div>
              <div className="pricing-pricing-card1">
                <div className="pricing-container09">
                  <div className="pricing-container10">
                    <span className="pricing-text034">
                      Direct Hire
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span className="pricing-text035">Most Popular</span>
                  </div>
                  <span className="pricing-tagline-h pricing-tagline ">
                    10% of first year salary
                  </span>
                  <span className="pricing-text036">
                    <span>Find the Talent of Tomorrow, Today</span>
                    <br></br>
                  </span>
                </div>
                <div className="pricing-separator3"></div>
                <div className="pricing-container11">
                  <span className="pricing-text039">
                    <span className="pricing-text040">✔</span>
                    <span>  No Hire, No Fee</span>
                  </span>
                  <span className="pricing-text042">
                    <span className="pricing-text043">✔</span>
                    <span>  Dedicated Search</span>
                  </span>
                  <span className="pricing-text045">
                    <span className="pricing-text046">✔</span>
                    <span>  Access to Talent Network</span>
                  </span>
                  <span className="pricing-text048">
                    <span className="pricing-text049">✔</span>
                    <span>  Client Portal</span>
                  </span>
                  <span className="pricing-text051">
                    <span className="pricing-text052">✔</span>
                    <span>  Video Intro's</span>
                  </span>
                </div>
                <div className="pricing-separator4"></div>
                <Link
                  to="/client-step-5meeting"
                  className="pricing-navlink08 button"
                >
                  <span className="pricing-text033">Book Discovery call</span>
                </Link>
              </div>
              <div className="pricing-pricing-card2">
                <div className="pricing-container13">
                  <span className="pricing-text057">Executive Search</span>
                  <span className="pricing-tagline-h pricing-tagline">
                    Contact For Price
                  </span>
                  <span className="pricing-text058">
                    <span>C-suite Search And Headhunting</span>
                    <br></br>
                  </span>
                </div>
                <div className="pricing-separator5"></div>
                <div className="pricing-container14">
                  <span className="pricing-text061">
                    <span className="pricing-text062">✔</span>
                    <span>  Exhaustive Market Search</span>
                  </span>
                  <span className="pricing-text064">
                    <span className="pricing-text065">✔</span>
                    <span>  Premium Talent Network</span>
                  </span>
                  <span className="pricing-text067">
                    <span className="pricing-text068">✔</span>
                    <span>  Exclusive Outreach</span>
                  </span>
                  <span className="pricing-text070">
                    <span className="pricing-text071">✔</span>
                    <span>  Guaranteed Delivery</span>
                  </span>
                  <span className="pricing-text073">
                    <span className="pricing-text074">✔</span>
                    <span>  Significant ROI</span>
                  </span>
                </div>
                <div className="pricing-separator6"></div>
                <Link
                  to="/client-step-5meeting"
                  className="pricing-navlink08 button"
                >
                  <span className="pricing-text033">Book Discovery call</span>
                </Link>
              </div>
            </div>
            <div className="pricing-container16">
              <span className="pricing-text079">
                <span>
                  © 
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <Link to="/build-your-team" className="pricing-navlink11">
                  Create an account
                </Link>
                <span>
                  {" "}
                  or
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <Link to="/contact-us" className="pricing-navlink12">
                  Contact Us
                </Link>
                <span> - No credit card required</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <Companys /> */}
      <SupportForm home />
      <Footer rootClassName="footer-root-class-name5"></Footer>
      <div className="pricing-container23"></div>
    </div>
  );
};

export default Pricing;
