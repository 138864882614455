import React, { useCallback, useEffect, useState, createRef } from "react";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";

const CustomDropzone = (props) => {
  const { name, label = name } = props;
  const [errorExtension, setErrorExtension] = useState({});
  const {
    formState: { errors },
    register,
    unregister,
    setValue,
    watch,
  } = useForm();
  const files = watch(name);
  const onDrop = useCallback(
    (droppedFiles) => {
      droppedFiles.forEach((file) => {
        if (
          file.type !== "application/pdf" &&
          file.type !== "application/msword"
        ) {
          setErrorExtension({
            type: "manual",
            message: "Only PDF and DOC files are accepted",
          });
        }
        // Process the accepted files
      });
      setErrorExtension({});
      setValue(name, droppedFiles, { shouldValidate: true });
    },
    [setValue, name]
  );
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    // noClick: false,
    // noKeyboard: false,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc", ".docx"],
    },
  });

  useEffect(() => {
    register(name);
    return () => {
      unregister(name);
    };
  }, [register, unregister, name]);

  return (
    <div>
      <div
        className="candidate-step5-container7"
        {...getRootProps()}
        type="file"
        role="button"
        aria-label="File Upload"
        id={name}
      >
        <input {...props} {...getInputProps()} {...register("resume.file")} />
        <svg viewBox="0 0 1024 1024" className="candidate-step5-icon">
          <path d="M598 554h128l-214-212-214 212h128v172h172v-172zM826 428q82 6 140 67t58 145q0 88-63 151t-151 63h-554q-106 0-181-75t-75-181q0-94 67-169t161-85q42-78 118-126t166-48q108 0 201 76t113 182z"></path>
        </svg>
        <span className="candidate-step5-text04">Drag and drop</span>
        <span className="candidate-step5-text05">
          your file here, or{" "}
          <span onClick={open} style={{ fontWeight: "bold" }}>
            browse{" "}
          </span>
        </span>
        <span
          style={{
            borderTop: "1px solid #fff",
            borderBottom: "1px solid #fff",
            color: "#ccc",
            fontSize: "12px",
            marginTop: "10px",
          }}
        >
          Required formats: .doc, .docx, .pdf
        </span>
        {!!files?.length && (
          <div className=" ">
            {files.map((file) => {
              return (
                <div key={file.name}>
                  <p
                    style={{
                      background: "#fff",
                      fontSize: "13px",
                      fontWeight: "bold",
                      marginTop: "10px",
                      padding: "5px",
                    }}
                  >
                    Selected file: {file.name}
                  </p>
                </div>
              );
            })}
          </div>
        )}
        {errorExtension && <p>{errorExtension.message}</p>}
      </div>
      {errors.resume?.file && (
        <p className="error-text">*Uploading a file is required</p>
      )}
    </div>
  );
};

export default CustomDropzone;
