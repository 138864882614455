import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  doc,
  setDoc,
  getDoc,
  where,
} from "firebase/firestore";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { db } from "../../../database/firebase";

import Spinner from "../../../components/Spinner";

// import loadable from "@loadable/component";
// import pMinDelay from "p-min-delay";
// imeges
import avatar1 from "../../../images/avatar/1.jpg";
import { useUserState } from "../../../utils/stateContext";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const DashboardSettings = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [userAppSettings, setUserAppSettings] = useState({});

  const { user, setUser } = useUserState();

  const navigate = useNavigate();

  const {
    formState: { errors },
    getValues,
    reset,
    handleSubmit,
    register,
  } = useForm({ defaultValues: userAppSettings });

  // useEffect(() => {
  //   const controller = new AbortController();
  //   async function fetchSettings() {
  //     const userSettingsDoc = await getDoc(doc(db, "settings", user?.userUID));
  //     if (userSettingsDoc.exists()) {
  //       setUserAppSettings(userSettingsDoc.data());
  //       reset(userAppSettings);
  //       console.log("current settings", userAppSettings);
  //     }
  //   }
  //   fetchSettings();
  //   return () => {
  //     controller.abort();
  //   };
  // }, [refreshKey]);

  // const saveAppSettings = async (data) => {
  //   setIsLoading(true);
  //   await setDoc(doc(db, "settings", user?.userUID), {
  //     ...userAppSettings,
  //     ...data,
  //   });
  //   setRefreshKey(refreshKey + 1);
  //   setIsLoading(false);
  // };

  // const authorizeApp = () => {
  //
  // if (!userAppSettings?.clientId || !userAppSettings?.callbackUrl) {
  //   return;
  // }
  // const vincereAuthUrl = `https://id.vincere.io/oauth2/authorize?response_type=code&client_id=${userAppSettings?.clientId}&redirect_uri=${userAppSettings?.callbackUrl}&state=${user?.userUID}`;
  // const vincereAuthUrl = `http://localhost:5001/?response_type=code&client_id=${ userAppSettings?.clientId}&redirect_uri=${userAppSettings?.callbackUrl}&state=${user?.userUID}`
  // window.location.href = vincereAuthUrl;
  // };

  return (
    <AdminProtectedRoute>
      user ?
      <div className="row">
        <div className="col-xl-12">
          <div className="d-sm-flex align-items-center mb-sm-3 mt-sm-2 mt-2  mb-2">
            <h4 className="fs-20 text-white mr-auto mb-sm-0 mb-2">Settings</h4>
            {/* <button
            // onClick={handleSubmit(saveAppSettings)}
            className="btn btn-outline-primary mb-sm-0 mb-1 text-white"
            style={{
              backgroundColor: "#6741FF",
              borderColor: "#6741FF",
              borderRadius: 8 + "px",
              minWidth: 152 + "px",
              lineHeight: 18 + "px",
              height: 48 + "px",
            }}
          >
            {isLoading ? (
              <>
                <Spinner /> Saving ...{" "}
              </>
            ) : (
              "Save Settings"
            )}
          </button> */}
            {userAppSettings &&
              userAppSettings?.clientId &&
              userAppSettings?.callbackUrl && (
                <button
                  onClick={() => authorizeApp()}
                  className="btn btn-outline-primary mb-sm-0 mb-1 text-white ml-2"
                  style={{
                    backgroundColor: "#6741FF",
                    borderColor: "#6741FF",
                    borderRadius: 8 + "px",
                    minWidth: 152 + "px",
                    lineHeight: 18 + "px",
                    height: 48 + "px",
                  }}
                >
                  {userAppSettings?.setup
                    ? "Setup done"
                    : "Request Authorization"}
                </button>
              )}
          </div>

          {/* <div className="add-new-app-form">
          <div className="row">
            <div className="col-xl-6 col-sm-12">
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label>Vincere App Client ID</label>
                  <small className="px-2 mt-1"> * Required</small>
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    errors.clientId ? "has-error" : ""
                  }`}
                  placeholder="Enter Vincere App Client ID"
                  defaultValue={userAppSettings?.clientId || ""}
                  {...register("clientId", {
                    minLength: 3,
                    maxLength: 100,
                    required: true,
                  })}
                />
                {errors.clientId && (
                  <small className="mt-4" style={{ color: "red" }}>
                    * required
                  </small>
                )}
              </div>
            </div>

            <div className="col-xl-6 col-sm-12">
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label>Vincere API Key</label>
                  <small className="px-2 mt-1"> * Required</small>
                </div>
                <input
                  type="text"
                  className={`form-control ${errors.apiKey ? "has-error" : ""}`}
                  placeholder="Enter Vincere API Key"
                  defaultValue={userAppSettings?.apiKey || ""}
                  {...register("apiKey", {
                    minLength: 3,
                    maxLength: 100,
                    required: true,
                  })}
                />
                {errors.apiKey && (
                  <small className="mt-4" style={{ color: "red" }}>
                    * required
                  </small>
                )}
              </div>
            </div>

            <div className="col-xl-6 col-sm-12">
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label>Your Callback URL</label>
                  <small className="px-2 mt-1"> * Required</small>
                </div>
                <input
                  type="text"
                  className={`form-control ${
                    errors.callbackUrl ? "has-error" : ""
                  }`}
                  placeholder="Enter your callback url"
                  defaultValue={userAppSettings?.callbackUrl || ""}
                  {...register("callbackUrl", {
                    minLength: 3,
                    maxLength: 150,
                    required: true,
                  })}
                />
                {errors.callbackUrl && (
                  <small className="mt-4" style={{ color: "red" }}>
                    * required
                  </small>
                )}
              </div>
            </div>

            <div className="col-xl-6 col-sm-12">
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label>Authorization Code</label>
                  <small className="px-2 mt-1">This will be filled auto</small>
                </div>
                <input
                  type="text"
                  disabled
                  className={`form-control ${
                    errors.clientSecret ? "has-error" : ""
                  }`}
                  value={userAppSettings?.authorization_code || ""}
                />
              </div>
            </div>
            {/* <div className='col-xl-6 col-sm-12'>
                <div className='form-group'>
                  <label>Token Id</label>
                  <input
                    type='text'
                    className={`form-control`}
                    placeholder='Last name'
                    value={userAppSettings?.token || ''}
                  />
                </div>
              </div> */}
          {/* <div className="col-xl-6 col-sm-12">
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label>Access Token</label>
                  <small className="px-2 mt-1">This will be filled auto</small>
                </div>
                <input
                  disabled
                  type="text"
                  className={`form-control`}
                  value={userAppSettings?.access_token || ""}
                />
              </div>
            </div> */}
          {/* <div className="col-xl-6 col-sm-12">
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  <label>Refresh Token</label>
                  <small className="px-2 mt-1">This will be filled auto</small>
                </div>
                <input
                  type="text"
                  disabled
                  className={`form-control`}
                  value={userAppSettings?.refresh_token || ""}
                />
              </div>
            </div> */}
          {/* </div> */}
          {/* </div> */}
        </div>
      </div>
      : <Navigate to="/decentradmin/login"></Navigate>
    </AdminProtectedRoute>
  );
};

export default DashboardSettings;
