import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Helmet } from "react-helmet";

import FooterV2 from "../../components/footer-v2";
import "./client-confirm-account.css";
import axios from "axios";
import { useUserState } from "../../utils/stateContext";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../database/firebase";

const ClientConfirmAccount = (props) => {
  const { state } = useLocation();
  const [invitee, setInvitee] = useState(null);
  const [inviteeData, setInviteeData] = useState(null);

  const [show, setShow] = useState(false);
  const { user, setUser, logout } = useUserState();
  const navigate = useNavigate();

  const [currentClient, setCurrentClient] = useState({});

  const [candidatesList, setCandidatesList] = useState([]);

  const [foundRoles, setFoundRoles] = useState([]);

  const [filterByRole, setFilterByRole] = useState();

  const [currentPage, setCurrentPage] = useState(0);
  const [totalCandidates, setTotalCandidates] = useState(null);
  const [candidatesPaged, setCandidatesPaged] = useState({});
  const [roleSliderPosition, setRoleSliderPosition] = useState(0);

  const [debug, setDebug] = useState({});

  useEffect(async () => {
    const fetchCandidates = async () => {
      const candidatesDocs = await getDocs(collection(db, "users"));
      let data = candidatesDocs.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      let roles = {};

      data = data
        .filter((c) => c.userType === "candidate")
        .map((c, ind) => {
          const cand = {
            id: c.id,
            index: ind + 1,
            experience: c.experience,
            skills: c.skills,
            avatar: c.profileImage,
          };
          if (c.role in roles) {
            roles[c.role]?.push(cand);
          } else {
            roles[c.role] = [cand];
          }
          return cand;
        });
      setCandidatesList(data);
      let pageIndex = 0;
      const pagedCandidates = {};
      for (let i = 0; i < data?.length; i++) {
        if (
          pageIndex in pagedCandidates &&
          pagedCandidates[pageIndex]?.length == 6
        ) {
          pageIndex++;
        }
        if (pageIndex in pagedCandidates) {
          pagedCandidates[pageIndex].push(data[i]);
        } else {
          pagedCandidates[pageIndex] = [data[i]];
        }
      }
      setCandidatesPaged(pagedCandidates);
      setFoundRoles(roles);
    };

    await fetchCandidates();

    if (user?.eventURI && user?.inviteeURI) {
      axios
        .get(user?.inviteeURI, {
          headers: {
            Authorization: "Bearer " + calendlyToken,
          },
        })
        .then((info) => {
          // console.log('info event ============>', info)
          setInvitee(info.data.resource);
          return axios.get(info.data.resource.event, {
            headers: {
              Authorization: "Bearer " + calendlyToken,
            },
          });
        })
        .then((dataEvent) => {
          // console.log('dataEvent =====> ', dataEvent);
          setInviteeData(
            new Date(dataEvent.data.resource.start_time).toUTCString()
          );
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }, []);

  const handleLogout = () => {
    logout().then(() => {
      setUser(null);
      navigate("/");
    });
  };

  const getCandidates = () => {
    if (filterByRole && foundRoles[filterByRole]?.length > 0) {
      return {
        firstRow: foundRoles[filterByRole]?.slice(0, 3),
        secondRow: foundRoles[filterByRole]?.slice(3, 6),
        total: foundRoles[filterByRole]?.length,
      };
    }
    if (currentPage in candidatesPaged) {
      return {
        firstRow: candidatesPaged[currentPage]?.slice(0, 3),
        secondRow: candidatesPaged[currentPage]?.slice(3, 6),
        total: candidatesList?.length,
      };
    }

    return {
      firstRow: [],
      secondRow: [],
      total: 0,
    };
  };

  // pagination
  const pages = [];
  for (let ind = 0; ind <= candidatesList?.length / 6; ind++) {
    pages.push(ind);
  }

  const roleSlider = (ev, action) => {
    ev.preventDefault();
    let currentPx = 0;
    if (action === "forward") {
      currentPx = roleSliderPosition - 200;
      setRoleSliderPosition(currentPx);
      document.querySelector(
        ".matched-talents-container04"
      ).style.transform = `translateX(${currentPx}px)`;
    }
    if (action === "backward" && roleSliderPosition < 0) {
      currentPx = roleSliderPosition + 200;
      setRoleSliderPosition(currentPx);
      document.querySelector(
        ".matched-talents-container04"
      ).style.transform = `translateX(${currentPx}px)`;
    }
  };

  return (
    <div className="matched-talents-container">
      <Helmet>
        <title>Matched talents Decentraloco</title>
        <meta property="og:title" content="Matched talents Decentraloco" />
      </Helmet>
      <nav className="matched-talents-navbar">
        <header
          data-thq="thq-navbar"
          className="matched-talents-navbar-interactive"
        >
          <Link to="/" className="matched-talents-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="matched-talents-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="matched-talents-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="matched-talents-nav"
            >
              <Link
                to="/matched-talents"
                className="matched-talents-navlink1 button"
              >
                Matched Talents
              </Link>
              <Link
                to="/contact-us-client"
                className="matched-talents-text button"
              >
                Values
              </Link>
              <Link to="/pricing" className="matched-talents-text01 button">
                Pricing
              </Link>
            </nav>
          </div>
          <div
            data-thq="thq-navbar-btn-group"
            className="matched-talents-btn-group"
          >
            {user && user?.userUID && (
              <div
                onClick={() => setShow(!show)}
                className="matched-talents-quick-actions"
              >
                <span className="matched-talents-text02">{`${user?.firstName}   ${user?.lastName}`}</span>
                <svg viewBox="0 0 1024 1024" className="matched-talents-icon">
                  <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                </svg>
                <img
                  id="open-mobile-menu"
                  alt="pastedImage"
                  src="https://play.teleporthq.io/static/svg/default-img.svg"
                  className="matched-talents-hamburger-menu"
                />
              </div>
            )}

            {show && (
              <div
                onClick={() => handleLogout()}
                style={{
                  backgroundColor: "#1E1C24",
                  borderRadius: "8px",
                  color: "#fff",
                  cursor: "pointer",
                  fontFamily: "Space Grotesk",
                  marginTop: "1px",
                  padding: "15px 10px",
                  position: "absolute",
                  right: 0,
                  textAlign: "center",
                  top: "48px",
                  width: "157px",
                }}
              >
                <span>Log out</span>
              </div>
            )}
          </div>

          <div
            data-thq="thq-burger-menu"
            className="matched-talents-burger-menu"
          >
            <svg viewBox="0 0 1024 1024" className="matched-talents-icon02">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="matched-talents-mobile-menu"
          >
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="matched-talents-nav1"
            >
              <div className="matched-talents-container01">
                <Link to="/" className="matched-talents-navlink2">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="matched-talents-finbest1"
                  />
                </Link>
                <div
                  data-thq="thq-close-menu"
                  className="matched-talents-menu-close"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="matched-talents-icon04"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="matched-talents-nav2"
              >
                <Link to="/" className="matched-talents-navlink3">
                  Matched Talents
                </Link>
                <Link
                  to="/contact-us-client"
                  className="matched-talents-navlink4"
                >
                  Values
                </Link>
                <Link to="/pricing" className="matched-talents-navlink5">
                  Pricing
                </Link>
              </nav>
              <div className="matched-talents-container02">
                {user && user?.userUID && (
                  <div className="matched-talents-quick-actions1">
                    <span className="matched-talents-text03">{`${user.firstName}  ${user.lastName}`}</span>
                    <svg
                      viewBox="0 0 1024 1024"
                      className="matched-talents-icon06"
                    >
                      <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
                    </svg>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
      </nav>
      <div className="matched-talents-hero">
        <div className="matched-talents-container03">
          <h1 className="matched-talents-text04">Account created</h1>
        </div>
        <section className="matched-talents-card">
          <h2 className="matched-talents-text20">
            <span className="matched-talents-text17">
              {user?.firstName + " " + user?.lastName}
            </span>
            {!inviteeData && <span>{inviteeData}</span>}
            <br></br>
            <p>
              Please update your profile <a href="/client-profile-edit">here</a>
            </p>
          </h2>
        </section>

        {/* <section className="matched-talents-card1">
          <span
            className="hover-pointer"
            onClick={(ev) => roleSlider(ev, "backward")}
          >
            <svg viewBox="0 0 1024 1024" className="matched-talents-icon08">
              <path d="M598 298v428l-214-214z"></path>
            </svg>
          </span>
          <main className="matched-talents-content">
            <div className="matched-talents-role-slider">
              <div className="matched-talents-container04">
                {Object.keys(foundRoles).map((role) => (
                  <button
                    onClick={() =>
                      setFilterByRole(role === filterByRole ? null : role)
                    }
                    type="button"
                    className="matched-talents-button button"
                    style={{
                      border:
                        role === filterByRole
                          ? "1px solid #6741ff"
                          : "1px solid",
                    }}
                  >
                    <span className="matched-talents-text23">
                      {role
                        ?.split("-")
                        .map(
                          (word) =>
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                        )
                        .join(" ")}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </main>
          <span
            className="hover-pointer"
            onClick={(ev) => roleSlider(ev, "forward")}
          >
            <svg viewBox="0 0 1024 1024" className="matched-talents-icon10">
              <path d="M426 726v-428l214 214z"></path>
            </svg>
          </span>
        </section>

        <section className="matched-talents-card2">
          <h1 className="matched-talents-text29">
            {filterByRole
              ? foundRoles[filterByRole]?.length
              : candidatesList?.length}{" "}
            Candidate Available
          </h1>
        </section>
        <main className="matched-talents-cards">
          {getCandidates()?.firstRow?.map((candidate, key) => (
            <CardCandidate key={key} candidate={candidate}></CardCandidate>
          ))}
        </main>

        <main className="matched-talents-cards1">
          {getCandidates()?.secondRow?.map((candidate, index) => (
            <CardCandidate
              key={index + 1 * 3}
              candidate={candidate}
            ></CardCandidate>
          ))}
        </main>
        {getCandidates()?.total > 6 && (
          <div className="matched-talents-container11">
            {currentPage > 0 && (
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                type="button"
                className="matched-talents-button24 button"
              >
                <svg viewBox="0 0 1024 1024" className="matched-talents-icon12">
                  <path d="M658 708l-60 60-256-256 256-256 60 60-196 196z"></path>
                </svg>
              </button>
            )}

            {pages?.map((page) => (
              <button
                onClick={() => setCurrentPage(page)}
                type="button"
                className="matched-talents-button25 button"
              >
                <span className="matched-talents-text48">{page + 1}</span>
              </button>
            ))}

            {currentPage < pages.length - 1 && (
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                type="button"
                className="matched-talents-button29 button"
              >
                <span className="matched-talents-text52">Next Page</span>
              </button>
            )}
          </div>
        )} */}
      </div>
      <FooterV2 rootClassName="footer-v2-root-class-name14"></FooterV2>
    </div>
  );
};

export default ClientConfirmAccount;
