import React, { useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./candidate-step-5.css";
import { useForm, FormProvider } from "react-hook-form";
import { useAppState } from "../../utils/stateContext";
import CustomDropzone from "../../components/CustomDropzone";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import CandidateProtectedSteps from "../../utils/CandidateProtectedSteps";

const CandidateStep5 = () => {
  const [errorRequired, setErrorRequired] = useState(false);
  const navigate = useNavigate();
  const { appState, setAppState } = useAppState();
  const { handleSubmit } = useForm({
    defaultValues: appState.candidate,
  });

  const [resume, setResume] = useState(
    appState.fileSelected ? appState.fileSelected : null
  );
  const methods = useForm({
    mode: "onBlur",
  });
  const onSubmit = methods.handleSubmit((fileSelected) => {
    if (fileSelected.file?.length > 0) {
      setErrorRequired(false);
      setAppState({
        ...appState,
        fileSelected,
      });
    } else {
      setErrorRequired(true);
      return;
    }

    navigate("/candidate-step-6");
  });

  // const dropzoneRef = forwardRef();
  // const openDialog = () => {
  //   // Note that the ref is set async,
  //   // so it might be null at some point
  //   if (dropzoneRef.current) {
  //     dropzoneRef.current.open()
  //   }
  // };

  return (
    <CandidateProtectedRoute>
      <CandidateProtectedSteps>
        <div className="candidate-step5-container">
          <Helmet>
            <title>Candidate-step-5 - Decentraloco</title>
            <meta
              property="og:title"
              content="Candidate-step-5 - Decentraloco"
            />
          </Helmet>
          <NavbarLogIn rootClassName="navbar-log-in-root-class-name9"></NavbarLogIn>
          <div className="candidate-step5-container1">
            <div className="candidate-step5-container2">
              <span className="candidate-step5-text">Step 5 of 6</span>
              <div className="candidate-step5-container3">
                <div className="candidate-step5-container4"></div>
              </div>
            </div>
            <div className="candidate-step5-container5">
              <span className="candidate-step5-text01">Upload Your Resume</span>
              <span className="candidate-step5-text02">
                Quickly build your profile by uploading your Resume
              </span>
            </div>
            <div className="candidate-step5-container6">
              <span className="candidate-step5-text03">Resume</span>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <div className="">
                    <CustomDropzone name="file" label="File Upload" />
                  </div>
                </form>
              </FormProvider>
              {errorRequired && (
                <p className="error-text">*Please upload your resume</p>
              )}
            </div>
          </div>
          <div className="candidate-step5-container8">
            <Link
              to="/candidate-step-4"
              disabled
              className="candidate-step5-navlink button"
            >
              <span className="candidate-step5-text06">
                <span>Back</span>
                <br></br>
              </span>
            </Link>
            <Link
              form="step-5-form"
              onClick={handleSubmit(onSubmit)}
              className="candidate-step5-navlink1 button"
            >
              <span className="candidate-step5-text09">Next</span>
            </Link>
          </div>
          <FooterV2 rootClassName="footer-v2-root-class-name8"></FooterV2>
        </div>
      </CandidateProtectedSteps>
    </CandidateProtectedRoute>
  );
};

export default CandidateStep5;
