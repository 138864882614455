import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./candidate-step-2.css";
import roles from "../../data/roles.json";
import { useForm } from "react-hook-form";
import { useAppState } from "../../utils/stateContext";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import CandidateProtectedSteps from "../../utils/CandidateProtectedSteps";

const CandidateStep2 = (props) => {
  const { appState, setAppState } = useAppState();
  const navigate = useNavigate();
  const [candidateStatus, setCandidateStatus] = useState(
    appState.candidate.candidateStatus
  );
  const [statusError, setStatusError] = useState(false);
  const [jobType, setJobType] = useState(appState.candidate.jobType);
  const [jobError, setJobError] = useState(false);
  const [availableFull, setAvailableFull] = useState(
    appState.candidate.availableFull
  );
  const [availablePart, setAvailablePart] = useState(
    appState.candidate.availablePart
  );
  const [availabilityError, setAvailabilityError] = useState(false);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: appState.candidate,
  });

  const saveData = (data) => {
    const { candidateStatus, jobType, availableFull, availablePart } = data;
    if (!candidateStatus) {
      setStatusError(true);
      return;
    }
    if (!jobType) {
      setJobError(true);
      return;
    }

    if (!availableFull && !availablePart) {
      setAvailabilityError(true);
      return;
    }
    setAppState({ ...appState, candidate: { ...data } });
    navigate("/candidate-step-3");
  };
  return (
    <CandidateProtectedRoute>
      <CandidateProtectedSteps>
        <div className="candidate-step2-container">
          <Helmet>
            <title>Candidate-step-2 - Decentraloco</title>
            <meta
              property="og:title"
              content="Candidate-step-2 - Decentraloco"
            />
          </Helmet>
          <NavbarLogIn rootClassName="navbar-log-in-root-class-name7"></NavbarLogIn>
          <div className="candidate-step2-container01">
            <div className="candidate-step2-container02">
              <span className="candidate-step2-text">Step 2 of 6</span>
              <div className="candidate-step2-container03">
                <div className="candidate-step2-container04"></div>
              </div>
            </div>
            <div className="candidate-step2-container05">
              <span className="candidate-step2-text01">
                Set your job search preferences
              </span>
              <span className="candidate-step2-text02">
                <span>
                  Your answers determine which jobs we recommend
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <br></br>
                <span>for you and which startups see your profile.</span>
              </span>
            </div>
            <form id="step-2-form">
              <div className="candidate-step2-container06">
                <span className="candidate-step2-text06">
                  Where are you in your job search?
                </span>
                <div className="candidate-step2-container07">
                  <div
                    className="candidate-step2-button button"
                    onClick={() => {
                      setCandidateStatus("Ready to interview");
                      setValue("candidateStatus", "Ready to interview");
                      setStatusError(false);
                    }}
                    style={{
                      borderColor:
                        "Ready to interview" === candidateStatus && "#6741ffff",
                    }}
                  >
                    <input
                      {...register("candidateStatus")}
                      type="radio"
                      name="candidateStatus"
                      className="hidden"
                      value="Ready to interview"
                    />
                    <label className="candidate-step2-text07">
                      Ready to interview
                    </label>
                  </div>
                  <div
                    className="candidate-step2-button01 button"
                    onClick={() => {
                      setCandidateStatus("Open to offers");
                      setValue("candidateStatus", "Open to offers");
                      setStatusError(false);
                    }}
                    style={{
                      borderColor:
                        "Open to offers" === candidateStatus && "#6741ffff",
                    }}
                  >
                    <input
                      {...register("candidateStatus")}
                      type="radio"
                      name="candidateStatus"
                      className="hidden"
                      value="Open to offers"
                    />
                    <label className="candidate-step2-text07">
                      Open to offers
                    </label>
                  </div>
                  <div
                    className="candidate-step2-button02 button"
                    onClick={() => {
                      setCandidateStatus("Closed to offers");
                      setValue("candidateStatus", "Closed to offers");
                      setStatusError(false);
                    }}
                    style={{
                      borderColor:
                        candidateStatus === "Closed to offers" && "#6741ffff",
                    }}
                  >
                    <input
                      {...register("candidateStatus")}
                      type="radio"
                      name="candidateStatus"
                      className="hidden"
                      value="Closed to offers"
                    />
                    <label className="candidate-step2-text07">
                      Closed to offers
                    </label>
                  </div>
                </div>
              </div>
              {statusError && (
                <p className="error-text">*Required, please choose an option</p>
              )}
              <div className="candidate-step2-container08">
                <span className="candidate-step2-text10">
                  What type of job are you interested in?
                </span>
                <div className="candidate-step2-container09">
                  <div
                    className="candidate-step2-button03 button"
                    onClick={() => {
                      setJobType("Full-time Employee");
                      setValue("jobType", "Full-time Employee");
                      setJobError(false);
                    }}
                    style={{
                      borderColor:
                        jobType === "Full-time Employee" && "#6741ffff",
                    }}
                  >
                    <input
                      {...register("jobType")}
                      type="radio"
                      name="jobType"
                      className="hidden"
                    />
                    <label className="candidate-step2-text11">
                      Full-time Employee
                    </label>
                  </div>
                  <div
                    className="candidate-step2-button04 button"
                    onClick={() => {
                      setJobType("Contractor");
                      setValue("jobType", "Contractor");
                      setJobError(false);
                    }}
                    style={{
                      borderColor: jobType === "Contractor" && "#6741ffff",
                    }}
                  >
                    <input
                      {...register("jobType")}
                      type="radio"
                      name="jobType"
                      className="hidden"
                    />
                    <label className="candidate-step2-text12">Contractor</label>
                  </div>
                  <div
                    className="candidate-step2-button05 button"
                    onClick={() => {
                      setJobType("Closed to offers");
                      setValue("jobType", "Closed to offers");
                      setJobError(false);
                    }}
                    style={{
                      borderColor:
                        jobType === "Closed to offers" && "#6741ffff",
                    }}
                  >
                    <input
                      {...register("jobType")}
                      type="radio"
                      name="jobType"
                      className="hidden"
                    />
                    <label className="candidate-step2-text13">
                      Closed to offers
                    </label>
                  </div>
                </div>
              </div>
              {jobError && (
                <p className="error-text">*Required, please choose an option</p>
              )}
              <div className="candidate-step2-container10">
                <div className="candidate-step2-container11">
                  <span className="candidate-step2-text14">
                    What kind of role are you looking for?
                  </span>
                  <select
                    defaultValue={appState.roleLooking}
                    className="candidate-step2-select input"
                    {...register("roleLooking", { required: true })}
                  >
                    <option value="" disabled hidden>
                      Your current role
                    </option>
                    {roles.map((role) => (
                      <option key={role.value} value={role.value}>
                        {role.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {errors.roleLooking && (
                <p className="error-text">*Required, please select a role</p>
              )}
              <div className="candidate-step2-container12">
                <span className="candidate-step2-text15">
                  Are you available for Full-time job?
                </span>
                <div className="candidate-step2-container13">
                  <div
                    className="candidate-step2-button06 button"
                    onClick={() => {
                      setAvailableFull(true);
                      setValue("availableFull", true);
                      setAvailabilityError(false);
                    }}
                    style={{
                      borderColor: availableFull === true && "#6741ffff",
                    }}
                  >
                    <input
                      {...register("availableFull")}
                      type="radio"
                      name="availableFull"
                      className="hidden"
                      value={true}
                    />
                    <label className="candidate-step2-text16">Yes</label>
                  </div>
                  <div
                    className="candidate-step2-button07 button"
                    onClick={() => {
                      setAvailableFull(false);
                      setValue("availableFull", false);
                    }}
                    style={{
                      borderColor: availableFull === false && "#6741ffff",
                    }}
                  >
                    <input
                      {...register("availableFull")}
                      type="radio"
                      name="availableFull"
                      className="hidden"
                      value={false}
                    />
                    <label className="candidate-step2-text17">No</label>
                  </div>
                </div>
              </div>
              {availableFull && (
                <div className="candidate-step2-container14">
                  <span className="candidate-step2-text18">
                    What is your Expected Range Salary (Full-time Job)?
                  </span>
                  <div className="candidate-step2-container15">
                    <select
                      defaultValue="false"
                      className="candidate-step2-select1 input"
                      {...register("fullSalaryMin")}
                    >
                      <option value="false" disabled hidden>
                        Minimum $
                      </option>
                      <option value="10.000">10.000</option>
                      <option value="20.000">20.000</option>
                      <option value="15.000">15.000</option>
                      <option value="25.000">25.000</option>
                      <option value="30.000">30.000</option>
                      <option value="35.000">35.000</option>
                      <option value="40.000">40.000</option>
                      <option value="45.000">45.000</option>
                      <option value="50.000">50.000</option>
                      <option value="55.000">55.000</option>
                      <option value="60.000">60.000</option>
                      <option value="65.000">65.000</option>
                      <option value="70.000">70.000</option>
                      <option value="75.000">75.000</option>
                      <option value="80.000">80.000</option>
                      <option value="85.000">85.000</option>
                      <option value="90.000">90.000</option>
                      <option value="95.000">95.000</option>
                      <option value="100.000">100.000</option>
                      <option value="105.000">105.000</option>
                      <option value="110.000">110.000</option>
                      <option value="115.000">115.000</option>
                      <option value="120.000">120.000</option>
                      <option value="125.000">125.000</option>
                      <option value="130.000">130.000</option>
                      <option value="135.000">135.000</option>
                      <option value="140.000">140.000</option>
                      <option value="145.000">145.000</option>
                      <option value="150.000">150.000</option>
                      <option value="More than 150,000">
                        More than 150,000
                      </option>
                    </select>
                    <select
                      defaultValue="false"
                      className="candidate-step2-select2 input"
                      {...register("fullSalaryMax")}
                    >
                      <option value="false" disabled hidden>
                        Maximum $
                      </option>
                      <option value="10.000">10.000</option>
                      <option value="20.000">20.000</option>
                      <option value="15.000">15.000</option>
                      <option value="25.000">25.000</option>
                      <option value="30.000">30.000</option>
                      <option value="35.000">35.000</option>
                      <option value="40.000">40.000</option>
                      <option value="45.000">45.000</option>
                      <option value="50.000">50.000</option>
                      <option value="55.000">55.000</option>
                      <option value="60.000">60.000</option>
                      <option value="65.000">65.000</option>
                      <option value="70.000">70.000</option>
                      <option value="75.000">75.000</option>
                      <option value="80.000">80.000</option>
                      <option value="85.000">85.000</option>
                      <option value="90.000">90.000</option>
                      <option value="95.000">95.000</option>
                      <option value="100.000">100.000</option>
                      <option value="105.000">105.000</option>
                      <option value="110.000">110.000</option>
                      <option value="115.000">115.000</option>
                      <option value="120.000">120.000</option>
                      <option value="125.000">125.000</option>
                      <option value="130.000">130.000</option>
                      <option value="135.000">135.000</option>
                      <option value="140.000">140.000</option>
                      <option value="145.000">145.000</option>
                      <option value="150.000">150.000</option>
                      <option value="More than 150,000">
                        More than 150,000
                      </option>
                    </select>
                  </div>
                </div>
              )}
              <div className="candidate-step2-container16">
                <span className="candidate-step2-text19">
                  Are you available for Part-time job?
                </span>
                <div className="candidate-step2-container17">
                  <div
                    className="candidate-step2-button08 button"
                    onClick={() => {
                      setAvailablePart(true);
                      setValue("availablePart", true);
                      setAvailabilityError(false);
                    }}
                    style={{
                      borderColor: availablePart === true && "#6741ffff",
                    }}
                  >
                    <input
                      {...register("availablePart")}
                      type="radio"
                      className="hidden"
                      value={true}
                    />
                    <label className="candidate-step2-text20">Yes</label>
                  </div>
                  <div
                    className="candidate-step2-button09 button"
                    onClick={() => {
                      setAvailablePart(false);
                      setValue("availablePart", false);
                    }}
                    style={{
                      borderColor: availablePart === false && "#6741ffff",
                    }}
                  >
                    <input
                      {...register("availablePart")}
                      type="radio"
                      className="hidden"
                      value={false}
                    />
                    <label className="candidate-step2-text21">No</label>
                  </div>
                </div>
              </div>
              {availablePart && (
                <div className="candidate-step2-container18">
                  <span className="candidate-step2-text22">
                    What is your Expected Range Salary (Part-time Job)?
                  </span>
                  <div className="candidate-step2-container19">
                    <select
                      defaultValue="false"
                      className="candidate-step2-select3 input"
                      {...register("partSalaryMin")}
                    >
                      <option value={false} disabled hidden>
                        Minimum $
                      </option>
                      <option value="10.000">10.000</option>
                      <option value="15.000">15.000</option>
                      <option value="20.000">20.000</option>
                      <option value="25.000">25.000</option>
                      <option value="30.000">30.000</option>
                      <option value="35.000">35.000</option>
                      <option value="40.000">40.000</option>
                      <option value="45.000">45.000</option>
                      <option value="50.000">50.000</option>
                      <option value="55.000">55.000</option>
                      <option value="60.000">60.000</option>
                      <option value="65.000">65.000</option>
                      <option value="70.000">70.000</option>
                      <option value="75.000">75.000</option>
                      <option value="80.000">80.000</option>
                      <option value="85.000">85.000</option>
                      <option value="90.000">90.000</option>
                      <option value="95.000">95.000</option>
                      <option value="100.000">100.000</option>
                      <option value="105.000">105.000</option>
                      <option value="110.000">110.000</option>
                      <option value="115.000">115.000</option>
                      <option value="120.000">120.000</option>
                      <option value="125.000">125.000</option>
                      <option value="130.000">130.000</option>
                      <option value="135.000">135.000</option>
                      <option value="140.000">140.000</option>
                      <option value="145.000">145.000</option>
                      <option value="150.000">150.000</option>
                      <option value="More than 150,000">
                        More than 150,000
                      </option>
                    </select>
                    <select
                      defaultValue="false"
                      className="candidate-step2-select4 input"
                      {...register("partSalaryMax")}
                    >
                      <option value={false} disabled hidden>
                        Maximum $
                      </option>
                      <option value="10.000">10.000</option>
                      <option value="20.000">20.000</option>
                      <option value="15.000">15.000</option>
                      <option value="25.000">25.000</option>
                      <option value="30.000">30.000</option>
                      <option value="35.000">35.000</option>
                      <option value="40.000">40.000</option>
                      <option value="45.000">45.000</option>
                      <option value="50.000">50.000</option>
                      <option value="55.000">55.000</option>
                      <option value="60.000">60.000</option>
                      <option value="65.000">65.000</option>
                      <option value="70.000">70.000</option>
                      <option value="75.000">75.000</option>
                      <option value="80.000">80.000</option>
                      <option value="85.000">85.000</option>
                      <option value="90.000">90.000</option>
                      <option value="95.000">95.000</option>
                      <option value="100.000">100.000</option>
                      <option value="105.000">105.000</option>
                      <option value="110.000">110.000</option>
                      <option value="115.000">115.000</option>
                      <option value="120.000">120.000</option>
                      <option value="125.000">125.000</option>
                      <option value="130.000">130.000</option>
                      <option value="135.000">135.000</option>
                      <option value="140.000">140.000</option>
                      <option value="145.000">145.000</option>
                      <option value="150.000">150.000</option>
                      <option value="More than 150,000">
                        More than 150,000
                      </option>
                    </select>
                  </div>
                </div>
              )}
              {availabilityError && (
                <p className="error-text">
                  *Required, please choose when are you available
                </p>
              )}
            </form>
          </div>
          <div className="candidate-step2-container20">
            <Link
              to="/candidate-step-1"
              disabled
              className="candidate-step2-navlink button"
            >
              <span className="candidate-step2-text23">
                <span>Back</span>
                <br></br>
              </span>
            </Link>
            <Link
              form="step-2-form"
              className="candidate-step2-navlink1 button"
              onClick={handleSubmit(saveData)}
            >
              <span className="candidate-step2-text26">Next</span>
            </Link>
          </div>
          <FooterV2 rootClassName="footer-v2-root-class-name6"></FooterV2>
        </div>
      </CandidateProtectedSteps>
    </CandidateProtectedRoute>
  );
};

export default CandidateStep2;
