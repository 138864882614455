import React from 'react'

import PropTypes from 'prop-types'

import './companys.css'

const Companys = (props) => {
  return (
    <div className="companys-container">
      <div className="companys-container1">
        <img
          alt={props.image_alt}
          src={props.image_src}
          className="companys-image"
        />
        <img
          alt={props.image_alt1}
          src={props.image_src1}
          className="companys-image1"
        />
      </div>
      <div className="companys-container2">
        <img
          alt={props.image_alt2}
          src={props.image_src2}
          className="companys-image2"
        />
        <img
          alt={props.image_alt3}
          src={props.image_src3}
          className="companys-image3"
        />
      </div>
    </div>
  )
}

Companys.defaultProps = {
  image_src: "/Other Company's Logo's/frame%2010933-200h.png",
  image_alt: 'image',
  image_src1: '/ethereum%201-200h.png',
  image_alt1: 'image',
  image_src2: "/Other Company's Logo's/frame%2010934-200h.png",
  image_alt2: 'image',
  image_src3: "/Other Company's Logo's/vector-200h.png",
  image_alt3: 'image',
}

Companys.propTypes = {
  image_src: PropTypes.string,
  image_alt: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src2: PropTypes.string,
  image_alt2: PropTypes.string,
  image_src3: PropTypes.string,
  image_alt3: PropTypes.string,
}

export default Companys
