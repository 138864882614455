import React, { useEffect, useState } from "react";
import { Link, redirect, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./log-in.css";
import { useForm } from "react-hook-form";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth, db } from "../../database/firebase";
import { useUserState } from "../../utils/stateContext";
import { doc, getDoc } from "firebase/firestore";
import Spinner from "../../components/Spinner";

const LogIn = (props) => {
  const [errorCredentials, setErrorCredentials] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const { user, setUser } = useUserState();
  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      navigate("/");
    }
  });

  const loginUser = ({ email, password }) => {
    setIsLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        setUser({ user });
        setIsLoading(false);
        // navigate("/candidate-profile-edit");
      })
      .catch((error) => {
        setIsLoading(false);
        setErrorCredentials(true);
      });
  };

  return (
    <div className="log-in-container">
      <Helmet>
        <title>LogIn Decentraloco</title>
        <meta property="og:title" content="LogIn Decentraloco" />
      </Helmet>
      <NavbarLogIn rootClassName="navbar-log-in-root-class-name"></NavbarLogIn>
      <div className="log-in-hero">
        <div className="log-in-container1">
          <h1 className="log-in-text">Log In</h1>
          <div className="log-in-container2">
            <span className="log-in-text01">
              Don&apos;t have a apprentice account yet?
            </span>
            <Link to="/candidate-step-0" className="log-in-navlink">
              Sign up
            </Link>
          </div>
          <div className="log-in-container3">
            {errorCredentials && (
              <p className="error-text">User credentials are invalid</p>
            )}
            <span className="log-in-text02">
              <span>Email address</span>
              <span className="log-in-text04"></span>
            </span>
            <input
              {...register("email", {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
              type="text"
              placeholder="you@example.com"
              className="log-in-textinput teleport-show input"
            />
            {errors.email && (
              <p className="error-text">*Required, must be a valid email</p>
            )}
          </div>
          <div className="log-in-container4">
            <span className="log-in-text05">
              <span>Password</span>
              <br className="log-in-text07"></br>
            </span>
            <input
              {...register("password", { required: true })}
              type="password"
              placeholder="Password"
              className="log-in-textinput1 teleport-show input"
            />
            {errors.password && <p className="error-text">*Required</p>}
          </div>
          <div className="log-in-container5">
            <div className="log-in-container6">
              <input type="checkbox" checked className="log-in-checkbox" />
              <span className="log-in-text08">Remember me</span>
            </div>
            <Link to="/forgot-password" className="log-in-navlink1">
              Forgot Password
            </Link>
          </div>
          <div className="log-in-btn-group">
            <Link
              className="log-in-navlink2 button"
              onClick={handleSubmit(loginUser)}
            >
              <span className="log-in-text09">
                {isLoading ? <Spinner /> : "Log In"}
              </span>
            </Link>
          </div>
          <div className="log-in-container7">
            <span className="log-in-text10">Read our </span>
            <Link to="/f-a-qs" className="log-in-navlink3">
              FAQ&apos;s
            </Link>
          </div>
        </div>
        <img
          src="/playground_assets/art-work%2001-1500h.png"
          loading="lazy"
          className="log-in-image"
        />
      </div>
      <FooterV2></FooterV2>
    </div>
  );
};

export default LogIn;
