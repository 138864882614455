import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import { useAppState } from "../../utils/stateContext";
import "./client-step-0.css";
import countries from "../../data/countries.json";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import CandidateProtectedSteps from "../../utils/CandidateProtectedSteps";
import { auth, db } from "../../database/firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
const regexEmailPattern =
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

const ClientJoinWaitlist = (props) => {
  const navigate = useNavigate();
  const { appState, setAppState } = useAppState();
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
  } = useForm({
    defaultValues: appState.client,
  });

  // Functions
  const saveData = (data) => {
    createUserWithEmailAndPassword(auth, data.email, data.password)
      .then(async (userCredential) => {
        console.log("usercRerdential", userCredential);
        // Signed in
        const uid = userCredential.user.uid;
        // ...
        const clientData = data;
        delete clientData.password;
        const usernew = await setDoc(doc(db, "users", uid), {
          ...clientData,
          userUID: uid,
          createdAt: Date.now(),
        });
        console.log("usernew", usernew);
      })
      .then(() => navigate("/client-confirm-account"))
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log("error", errorMessage);
        // ..
      });
    // navigate("/client-step-5meeting");
  };
  return (
    <CandidateProtectedRoute>
      <div className="client-step0-container">
        <Helmet>
          <title>Join Waitlist</title>
          <meta property="og:title" content="Join Waitlist Decentraloco" />
        </Helmet>
        <NavbarLogIn rootClassName="navbar-log-in-root-class-name16"></NavbarLogIn>
        <div className="client-step0-hero">
          <div className="client-step0-container01">
            <div className="client-step0-container02">
              <span className="client-step0-text">Join Waitlist</span>
            </div>
            <form>
              <div className="client-step0-container03">
                <div className="client-step0-container04">
                  <span className="client-step0-text01">
                    <span>First Name</span>
                    <br></br>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                  <input
                    {...register("firstName", {
                      required: true,
                      maxLength: 25,
                      minLength: 3,
                    })}
                    type="text"
                    placeholder="First name"
                    value={appState.firstName}
                    className={
                      "client-step0-textinput input " +
                      (errors.firstName ? "error-border" : "")
                    }
                  />
                  {errors.firstName && (
                    <p className="error-text">
                      *Required, must have 3-25 chars
                    </p>
                  )}
                </div>
                <div className="client-step0-container05">
                  <span className="client-step0-text06">
                    <span>Last Name</span>
                    <br></br>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                  <input
                    {...register("lastName", {
                      required: true,
                      maxLength: 25,
                      minLength: 3,
                    })}
                    type="text"
                    placeholder="Last name"
                    value={appState.lastName}
                    className={
                      "client-step0-textinput1 teleport-show input " +
                      (errors.lastName ? "error-border" : "")
                    }
                  />
                  {errors.lastName && (
                    <p className="error-text">
                      *Required, must have 3-16 chars
                    </p>
                  )}
                </div>
              </div>
              <div className="client-step0-container06">
                <span className="client-step0-text11">
                  <span>Work Email Address</span>
                  <br></br>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                </span>
                <input
                  {...register("email", {
                    required: true,
                    pattern: regexEmailPattern,
                  })}
                  type="email"
                  placeholder="Email"
                  value={appState.email}
                  className={
                    "client-step0-textinput2 teleport-show input " +
                    (errors.email ? "error-border" : "")
                  }
                />
                {errors.email && (
                  <p className="error-text">*Required, must be a valid email</p>
                )}
              </div>
              <div className="client-step1-container06">
                <span className="client-step1-text05">Password</span>
                <input
                  {...register("password", {
                    required: true,
                    maxLength: 64,
                    minLength: 8,
                  })}
                  type="password"
                  placeholder=""
                  value={appState.password}
                  className={
                    "client-step1-textinput teleport-show input " +
                    (errors.firstName ? "error-border" : "")
                  }
                />
                {errors.password && (
                  <p className="error-text">*Required, must have 8-64 chars</p>
                )}
              </div>
              <input type="hidden" {...register("userType")} value="client" />
              <Link
                onClick={handleSubmit(saveData)}
                className="client-step0-navlink1 button"
              >
                <span className="client-step0-text33">Join Waitlist</span>
              </Link>
            </form>
            {/* <div className="client-step0-container14">
              <span className="client-step0-text34">Or </span>
              <Link to="/log-in" className="client-step0-navlink2">
                Log In
              </Link>
            </div> */}
          </div>
          <img
            src="/playground_assets/art-work%2001-1500h.png"
            loading="lazy"
            className="client-step0-image"
          />
        </div>
        <FooterV2></FooterV2>
      </div>
    </CandidateProtectedRoute>
  );
};

export default ClientJoinWaitlist;
