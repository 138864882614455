import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import "./footer.css";

const regexEmailPattern =
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

const Footer = (props) => {
  const [isSuccess, setIsSuccess] = useState(null);
  const [message, setMessage] = useState(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful, isSubmitting },
  } = useForm({ mode: "onTouched" });

  const addToMailingList = async (data) => {
    console.log(data);

    await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(data, null, 2),
    })
      .then(async (response) => {
        let json = await response.json();
        if (json.success) {
          setIsSuccess(true);
          setMessage(json.message);
          reset({});
          localStorage.setItem("subscribed", true);
        } else {
          setIsSuccess(false);
          setMessage(json.message);
        }
      })
      .catch((error) => {
        setIsSuccess(false);
        setMessage("Client Error. Please check the you are connected");
      });
  };

  return (
    <footer className={`footer-footer ${props.rootClassName} `}>
      <div className="footer-container">
        <span className="footer-text">
          Get DecentraLoco Updates to Your Inbox
        </span>
        <div className="footer-container01">
          <div className="input-field" style={{ position: "relative" }}>
            <input
              type="hidden"
              value="9fd29174-7a4c-4eba-8cbf-b1dac0042cdf"
              {...register("access_key")}
            />
            <input
              type="email"
              placeholder="you@example.com"
              className="footer-textinput teleport-show input"
              {...register("email", {
                required: true,
                pattern: regexEmailPattern,
              })}
            />
            {errors.email && (
              <p
                className="error-text"
                style={{ position: "absolute", left: "20px" }}
              >
                *Required, must be a valid email
              </p>
            )}
          </div>
          {isSubmitSuccessful && isSuccess ? (
            <div>Subscribed Successfully.</div>
          ) : (
            <button
              onClick={handleSubmit(addToMailingList)}
              className="footer-button button"
            >
              <span className="footer-text01">
                {isSubmitting ? "Submitting ..." : "Join Mailing List"}
              </span>
            </button>
          )}
        </div>
      </div>
      <div className="footer-container02">
        <div className="footer-separator"></div>
        <div className="footer-container03">
          <div className="footer-logo">
            <img
              alt={props.image_alt}
              src={props.image_src}
              className="footer-image"
            />
            <span className="footer-text02">
              <span className="">Building The Future Of Blockchain, </span>
              <br className=""></br>
              <span className="">Gaming &amp; AI</span>
            </span>
          </div>
          <div className="footer-links-container">
            <div className="footer-container04">
              <div className="footer-product-container">
                <span className="footer-text06">Home</span>
                <Link to="/" className="footer-navlink">
                  Home Page
                </Link>
                <Link to="/contact-us" className="footer-navlink01">
                  Values
                </Link>
                <Link to="/blogs" className="footer-navlink02">
                  Blog
                </Link>
              </div>
              <div className="footer-product-container1">
                <span className="footer-text07">Candidates</span>
                <Link to="/apply-as-a-candidate" className="footer-navlink03">
                  Candidate&apos;s Home
                </Link>
                <Link to="/jobs" className="footer-navlink04">
                  Jobs
                </Link>
                <Link to="/courses" className="footer-navlink05">
                  Resources
                </Link>
              </div>
              <div className="footer-company-container">
                <span className="footer-text08">Clients</span>
                <Link to="/build-your-team" className="footer-navlink06">
                  Client&apos;s Home
                </Link>
                <Link to="/contact-us-client" className="footer-navlink07">
                  Values
                </Link>
                <Link to="/pricing" className="footer-navlink08">
                  Pricing
                </Link>
              </div>
            </div>
            <div className="footer-container05">
              <div className="footer-contact">
                <span className="footer-text09">{props.text10}</span>
                <span className="footer-text10">info@decentraloco.xyz</span>
              </div>
              <div className="footer-socials">
                <span className="footer-text11">{props.text13}</span>
                <div className="footer-icon-group">
                  <a
                    href="https://www.linkedin.com/company/decentraloco/"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="footer-link"
                  >
                    <div className="footer-container06">
                      <svg viewBox="0 0 1024 1024" className="footer-icon">
                        <path
                          d="M384 384h177.106v90.782h2.532c24.64-44.194 84.958-90.782 174.842-90.782 186.946 0 221.52 116.376 221.52 267.734v308.266h-184.61v-273.278c0-65.184-1.334-149.026-96.028-149.026-96.148 0-110.82 70.986-110.82 144.292v278.012h-184.542v-576z"
                          className=""
                        ></path>
                        <path
                          d="M64 384h192v576h-192v-576z"
                          className=""
                        ></path>
                        <path
                          d="M256 224c0 53.019-42.981 96-96 96s-96-42.981-96-96c0-53.019 42.981-96 96-96s96 42.981 96 96z"
                          className=""
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <a
                    href="https://discord.gg/kchwUPtuut"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="footer-link1"
                  >
                    <div className="footer-container07">
                      <img
                        alt={props.image_alt1}
                        src={props.image_src1}
                        className="footer-image1"
                      />
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/_decentraloco"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="footer-link2"
                  >
                    <div className="footer-container08">
                      <svg
                        className="footer-icon4"
                        xmlns="http://www.w3.org/2000/svg"
                        shape-rendering="geometricPrecision"
                        text-rendering="geometricPrecision"
                        image-rendering="optimizeQuality"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        viewBox="0 0 512 462.799"
                      >
                        <path
                          fill="#fff"
                          fill-rule="nonzero"
                          d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"
                        />
                      </svg>
                    </div>
                  </a>
                  <a
                    href="https://t.me/+axIEQIRtOhE1YWY0"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="footer-link3"
                  >
                    <div className="footer-container09">
                      <svg viewBox="0 0 1024 1024" className="footer-icon6">
                        <path
                          d="M512 0c-282.8 0-512 229.2-512 512s229.2 512 512 512 512-229.2 512-512-229.2-512-512-512zM763.6 351l-84 395.8c-5.8 28.2-22.8 34.8-46.4 21.8l-128-94.6-61.4 59.8c-7.2 7-12.8 12.8-25.6 12.8-16.6 0-13.8-6.2-19.4-22l-43.6-143.2-126.6-39.4c-27.4-8.4-27.6-27.2 6.2-40.6l493.2-190.4c22.4-10.2 44.2 5.4 35.6 40z"
                          className=""
                        ></path>
                      </svg>
                    </div>
                  </a>
                  <a
                    href="https://www.youtube.com/@Decentraloco"
                    target="_blank"
                    rel="noreferrer noopener"
                    className="footer-link2"
                  >
                    <div className="footer-container08">
                      <svg
                        className="footer-icon6"
                        xmlns="http://www.w3.org/2000/svg"
                        shape-rendering="geometricPrecision"
                        text-rendering="geometricPrecision"
                        image-rendering="optimizeQuality"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        viewBox="0 0 512 360.726"
                      >
                        <path
                          fill="#fff"
                          d="M456.035 10.769c22.031 5.926 39.377 23.386 45.265 45.56C512 96.516 512 180.363 512 180.363s0 83.846-10.7 124.037c-5.888 22.17-23.234 39.631-45.265 45.559-39.928 10.767-200.034 10.767-200.034 10.767s-160.107 0-200.035-10.767C33.937 344.031 16.587 326.57 10.7 304.4 0 264.209 0 180.363 0 180.363S0 96.516 10.7 56.329c5.887-22.174 23.237-39.634 45.266-45.56C95.894 0 256.001 0 256.001 0s160.106 0 200.034 10.769zm-252.398 245.72l133.818-76.122-133.818-76.131v152.253z"
                        />
                      </svg>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-separator1"></div>
        <div className="footer-container10">
          <div className="footer-text12">
            {/* Building the Future of Blockchain, Gaming & AI */}
            <br /> © {new Date().getFullYear()} DecentraLoco
            {/* , All Rights */}
            {/* Reserved.  */}
          </div>
          {/* <Link to="/privacy-policy" className="footer-navlink09">
            Privacy Policy 
          </Link> */}
        </div>
      </div>
    </footer>
  );
};

Footer.defaultProps = {
  image_alt1: "image",
  text5: "Company",
  text12: "Get Ecosystem Updates to Your Inbox",
  textinput_placeholder: "Email",
  text4: "Releases",
  rootClassName: "",
  text10: "Values",
  text21: "Pricing",
  text7: "Careers",
  text6: "About",
  text16: "Features",
  text3: "Tutorials",
  image_alt: "logo",
  text13: "Follow Us",
  image_src1: "/playground_assets/discord-2-48-200h.png",
  text8: "Contact",
  text11: "hello@teleporthq.io",
  text: "Home",
  text1: "Features",
  text15: "Product",
  text31: "Tutorials",
  text14: "Next",
  image_src: "/playground_assets/logo-1500h.png",
};

Footer.propTypes = {
  image_alt1: PropTypes.string,
  text5: PropTypes.string,
  text12: PropTypes.string,
  textinput_placeholder: PropTypes.string,
  text4: PropTypes.string,
  rootClassName: PropTypes.string,
  text10: PropTypes.string,
  text21: PropTypes.string,
  text7: PropTypes.string,
  text6: PropTypes.string,
  text16: PropTypes.string,
  text3: PropTypes.string,
  image_alt: PropTypes.string,
  text13: PropTypes.string,
  image_src1: PropTypes.string,
  text8: PropTypes.string,
  text11: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  text15: PropTypes.string,
  text31: PropTypes.string,
  text14: PropTypes.string,
  image_src: PropTypes.string,
};

export default Footer;
