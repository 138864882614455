import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Modal from "react-modal";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./candidate-step-1.css";

import { useAppState } from "../../utils/stateContext";
import { useForm } from "react-hook-form";
import roles from "../../data/roles.json";
import AddSkills from "./add-skills";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import CandidateProtectedSteps from "../../utils/CandidateProtectedSteps";

Modal.defaultStyles.content.overflow = "unset";

const customStyles = {
  overlay: {
    background: "rgba(0,0,0,0)",
  },
  content: {
    border: 0,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "rgba(255, 255, 255, 0)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const CandidateStep1 = (props) => {
  const [roleSkills, setRoleSkills] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const { appState, setAppState } = useAppState();
  const navigate = useNavigate();
  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm({ defaultValues: appState.candidate });

  const [skillsList, setSkillsList] = useState(appState.candidate.skills || []);

  function closeModal() {
    setIsOpen(false);
  }
  function handleSelectChange(event) {
    setSelectedOption(event.target.value);
    const roleSkills = roles.find((role) => role.value === event.target.value);
    setSkillsList([]);
    setRoleSkills(roleSkills.options);
    setIsOpen(true);
  }

  const saveData = (data) => {
    setValue("skills", skillsList);
    setAppState({ ...appState, candidate: { ...data } });
    navigate("/candidate-step-2");
  };
  return (
    <CandidateProtectedRoute>
      <CandidateProtectedSteps>
        <div className="candidate-step1-container">
          <Helmet>
            <title>Candidate-step-1 - Decentraloco</title>
            <meta
              property="og:title"
              content="Candidate-step-1 - Decentraloco"
            />
          </Helmet>
          <NavbarLogIn rootClassName="navbar-log-in-root-class-name5"></NavbarLogIn>
          <form onSubmit={handleSubmit(saveData)}>
            <div className="candidate-step1-container01">
              <div className="candidate-step1-container02">
                <span className="candidate-step1-text">Step 1 of 6</span>
                <div className="candidate-step1-container03">
                  <div className="candidate-step1-container04"></div>
                </div>
              </div>
              <div className="candidate-step1-container05">
                <span className="candidate-step1-text01">
                  First, Tell us about you ?
                </span>
                <span className="candidate-step1-text02">
                  Let’s start with the basics, tell us about who you are
                </span>
              </div>

              <div className="candidate-step1-container06">
                <span className="candidate-step1-text03">
                  What your Current role?
                </span>
                <input
                  type="hidden"
                  value="candidate"
                  {...register("userType")}
                />
                <select
                  {...register("role", { required: true })}
                  id="role-select"
                  className={
                    "candidate-step1-select input " +
                    (errors.role ? "error-border" : "")
                  }
                  defaultValue=""
                  value={selectedOption}
                  onChange={(e) => handleSelectChange(e)}
                >
                  <option value="" disabled hidden>
                    Your current role
                  </option>
                  {roles.map((role) => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </select>
                {errors.role && (
                  <p className="error-text">
                    *Required, please choose when are you available
                  </p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  margin: "6px",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "616px",
                }}
              >
                <span className="candidate-step1-text04">
                  What are your skills?
                </span>
                <div className="candidate-step2-container0_7">
                  {skillsList.length > 0 &&
                    skillsList.map((skill, key) => (
                      <button
                        key={key}
                        type="button"
                        className="add-skills-button button"
                        style={{ border: "2px solid #6741ffff", color: "#fff" }}
                      >
                        <span className="add-skills-text1">{skill}</span>
                      </button>
                    ))}
                  {skillsList.length > 0 && (
                    <button
                      onClick={() => setIsOpen(true)}
                      style={{
                        color: "#fff",
                        fontFamily: "Space Grotesk",
                        marginLeft: "20px",
                        textDecoration: "underline",
                      }}
                    >
                      edit
                    </button>
                  )}
                </div>
                <input type="hidden" {...register("skills")} />
                {errors.skills && (
                  <p className="error-text">
                    *Required, please choose some skills
                  </p>
                )}
              </div>
              <div className="candidate-step1-container08">
                <span className="candidate-step1-text05">
                  How many years of work experience do you have?
                </span>
                <input
                  {...register("experience", {
                    required: true,
                    min: 0,
                    max: 51,
                  })}
                  type="number"
                  placeholder="years of experience"
                  className={
                    "candidate-step1-textinput teleport-show input " +
                    (errors.experience ? "error-border" : "")
                  }
                />
                {errors.experience && (
                  <p className="error-text">
                    *Required, value must be between 0 - 51
                  </p>
                )}
              </div>
              <div className="candidate-step1-container09">
                <span className="candidate-step1-text06">
                  What is your Current Salary Yearly?
                </span>
                <select
                  defaultValue="false"
                  className="candidate-step2-select input"
                  {...register("currentSalary", { required: true })}
                >
                  <option value="false" disabled hidden>
                    Minimum $
                  </option>
                  <option value="10.000">0</option>
                  <option value="10.000">10.000</option>
                  <option value="20.000">20.000</option>
                  <option value="15.000">15.000</option>
                  <option value="25.000">25.000</option>
                  <option value="30.000">30.000</option>
                  <option value="35.000">35.000</option>
                  <option value="40.000">40.000</option>
                  <option value="45.000">45.000</option>
                  <option value="50.000">50.000</option>
                  <option value="55.000">55.000</option>
                  <option value="60.000">60.000</option>
                  <option value="65.000">65.000</option>
                  <option value="70.000">70.000</option>
                  <option value="75.000">75.000</option>
                  <option value="80.000">80.000</option>
                  <option value="85.000">85.000</option>
                  <option value="90.000">90.000</option>
                  <option value="95.000">95.000</option>
                  <option value="100.000">100.000</option>
                  <option value="105.000">105.000</option>
                  <option value="110.000">110.000</option>
                  <option value="115.000">115.000</option>
                  <option value="120.000">120.000</option>
                  <option value="125.000">125.000</option>
                  <option value="130.000">130.000</option>
                  <option value="135.000">135.000</option>
                  <option value="140.000">140.000</option>
                  <option value="145.000">145.000</option>
                  <option value="150.000">150.000</option>
                  <option value="More than 150,000">More than 150,000</option>
                </select>
                {errors.currentSalary && (
                  <p className="error-text">*Required</p>
                )}
              </div>
            </div>
            <div className="candidate-step1-container10">
              <Link
                to="/candidate-step-0"
                disabled
                className="candidate-step1-navlink button"
              >
                <span className="candidate-step1-text07">
                  <span>Back</span>
                  <br></br>
                </span>
              </Link>
              <input
                className="candidate-step1-navlink1 button"
                type="submit"
                value="Next"
              />
            </div>
            <Modal
              isOpen={modalIsOpen}
              ariaHideApp={false}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <AddSkills
                closeModal={closeModal}
                skills={roleSkills}
                skillsList={skillsList}
                setSkillsList={setSkillsList}
                setIsOpen={setIsOpen}
                setValue={setValue}
              />
            </Modal>
          </form>
          <FooterV2 rootClassName="footer-v2-root-class-name4"></FooterV2>
        </div>
      </CandidateProtectedSteps>
    </CandidateProtectedRoute>
  );
};

export default CandidateStep1;
