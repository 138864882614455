import React from "react";
import { Link, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./candidate-step-4.css";
import { useFieldArray, useForm } from "react-hook-form";
import { useAppState } from "../../utils/stateContext";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import CandidateProtectedSteps from "../../utils/CandidateProtectedSteps";

const CandidateStep4 = (props) => {
  const { appState, setAppState } = useAppState();
  const navigate = useNavigate();
  const defaultValues = { projects: appState.candidate.projects };
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "projects",
  });
  const saveData = (data) => {
    setAppState((prevState) => ({
      ...prevState,
      candidate: {
        ...prevState.candidate,
        ...data,
      },
    }));
    navigate("/candidate-step-5");
  };
  return (
    <div className="candidate-step4-container">
      <Helmet>
        <title>Candidate-step-4 - Decentraloco</title>
        <meta property="og:title" content="Candidate-step-4 - Decentraloco" />
      </Helmet>
      <NavbarLogIn rootClassName="navbar-log-in-root-class-name8"></NavbarLogIn>
      <div className="candidate-step4-container01">
        <div className="candidate-step4-container02">
          <span className="candidate-step4-text">Step 4 of 6</span>
          <div className="candidate-step4-container03">
            <div className="candidate-step4-container04"></div>
          </div>
        </div>
        <div className="candidate-step4-container05">
          <span className="candidate-step4-text01">Add Your Projects</span>
          <span className="candidate-step4-text02">
            <span className="candidate-step4-text03">
              Show us some of the things you have been working on 
            </span>
            <br></br>
            <span>and building</span>
            <br></br>
          </span>
        </div>
        <form id="step-4-form">
          {fields.map(({ name, type, link, id }, index) => (
            <div key={id} style={{ display: "flex", flexDirection: "column" }}>
              <div
                className="candidate-step4-container06"
                style={{ borderTop: index > 0 ? "1px solid #666" : "" }}
              >
                {index > 0 && (
                  <p
                    style={{
                      color: "red",
                      cursor: "pointer",
                      fontSize: "0.8em",
                      marginTop: "15px",
                      textAlign: "right",
                      textDecoration: "underline",
                      width: "90%",
                    }}
                    onClick={() => remove(index)}
                  >
                    Remove project
                  </p>
                )}
                <div className="candidate-step4-container07">
                  <span className="candidate-step4-text07">Project name</span>
                  <input
                    {...register(`projects.${index}.name`, {
                      required: true,
                    })}
                    type="text"
                    name={`projects.${index}.name`}
                    placeholder="Project name"
                    className={
                      "candidate-step4-textinput input " +
                      (errors.projects?.[index]?.name && "error-border")
                    }
                    defaultValue={name}
                  />
                  {errors.projects?.[index]?.name && (
                    <p className="error-text">*Required</p>
                  )}
                </div>
                <div className="candidate-step4-container08">
                  <span className="candidate-step4-text08">
                    <span>Project type</span>
                    <br></br>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                  </span>
                  <input
                    {...register(`projects.${index}.type`, {
                      required: true,
                    })}
                    type="text"
                    name={`projects.${index}.type`}
                    placeholder="Project type  "
                    className={
                      "candidate-step4-textinput1 teleport-show input " +
                      (errors.projects?.[index]?.type && "error-border")
                    }
                    defaultValue={type}
                  />
                  {errors.projects?.[index]?.type && (
                    <p className="error-text">*Required</p>
                  )}
                </div>
              </div>
              <div className="candidate-step4-container09">
                <span className="candidate-step4-text13">Project link</span>
                <input
                  {...register(`projects.${index}.link`, {
                    required: true,
                  })}
                  type="text"
                  name={`projects.${index}.link`}
                  placeholder="Project link"
                  className={
                    "candidate-step4-textinput2 teleport-show input " +
                    (errors.projects?.[index]?.link && "error-border")
                  }
                  defaultValue={link}
                />
                {errors.projects?.[index]?.link && (
                  <p className="error-text">*Required</p>
                )}
              </div>
            </div>
          ))}
        </form>
      </div>
      <div className="candidate-step4-container10">
        <button
          className="candidate-step4-button button"
          onClick={() => append({ name: "", type: "", link: "" })}
        >
          <svg viewBox="0 0 1024 1024" className="candidate-step4-icon">
            <path d="M981.333 512c0-129.579-52.565-246.997-137.472-331.861s-202.283-137.472-331.861-137.472-246.997 52.565-331.861 137.472-137.472 202.283-137.472 331.861 52.565 246.997 137.472 331.861 202.283 137.472 331.861 137.472 246.997-52.565 331.861-137.472 137.472-202.283 137.472-331.861zM896 512c0 106.069-42.923 201.984-112.469 271.531s-165.461 112.469-271.531 112.469-201.984-42.923-271.531-112.469-112.469-165.461-112.469-271.531 42.923-201.984 112.469-271.531 165.461-112.469 271.531-112.469 201.984 42.923 271.531 112.469 112.469 165.461 112.469 271.531zM341.333 554.667h128v128c0 23.552 19.115 42.667 42.667 42.667s42.667-19.115 42.667-42.667v-128h128c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-128v-128c0-23.552-19.115-42.667-42.667-42.667s-42.667 19.115-42.667 42.667v128h-128c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
          <span className="candidate-step4-text14">Add project</span>
        </button>
      </div>
      <div className="candidate-step4-container11">
        <Link
          to="/candidate-step-3"
          disabled
          className="candidate-step4-navlink button"
        >
          <span className="candidate-step4-text15">
            <span>Back</span>
            <br></br>
          </span>
        </Link>
        <Link
          className="candidate-step4-navlink1 button"
          form="step-4-form"
          onClick={handleSubmit(saveData)}
        >
          <span className="candidate-step4-text18">Next</span>
        </Link>
      </div>
      <FooterV2 rootClassName="footer-v2-root-class-name7"></FooterV2>
    </div>
  );
};

export default CandidateStep4;
