import React from "react";
import { Link } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import "./privacy-policy.css";

const PrivacyPolicy = (props) => {
  return (
    <div className="privacy-policy-container">
      <Helmet>
        <title>Privacy Policy Decentraloco</title>
        <meta property="og:title" content="Privacy Policy Decentraloco" />
      </Helmet>
      <nav className="privacy-policy-navbar">
        <header
          data-thq="thq-navbar"
          className="privacy-policy-navbar-interactive"
        >
          <Link to="/" className="privacy-policy-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="privacy-policy-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="privacy-policy-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="privacy-policy-nav"
            >
              <Link to="/" className="privacy-policy-navlink01 button">
                Home
              </Link>
              <Link
                to="/contact-us"
                className="privacy-policy-navlink02 button"
              >
                Values
              </Link>
              <Link to="/blogs" className="privacy-policy-navlink03 button">
                Blog
              </Link>
              <Link to="/f-a-qs" className="privacy-policy-navlink04 button">
                FAQ&apos;s
              </Link>
            </nav>
          </div>
          <div
            data-thq="thq-navbar-btn-group"
            className="privacy-policy-btn-group"
          >
            <Link
              to="/apply-as-a-candidate"
              className="privacy-policy-login button"
            >
              Apply to Jobs
            </Link>
            <Link
              to="/build-your-team"
              className="privacy-policy-login1 button"
            >
              Hire Talent
            </Link>
            <Link to="/log-in" className="privacy-policy-register button">
              Log In
            </Link>
          </div>
          <div
            data-thq="thq-burger-menu"
            className="privacy-policy-burger-menu"
          >
            <svg viewBox="0 0 1024 1024" className="privacy-policy-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="privacy-policy-mobile-menu"
          >
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="privacy-policy-nav1"
            >
              <div className="privacy-policy-container01">
                <Link to="/" className="privacy-policy-navlink05">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="privacy-policy-finbest1"
                  />
                </Link>
                <div
                  data-thq="thq-close-menu"
                  className="privacy-policy-menu-close"
                >
                  <svg viewBox="0 0 1024 1024" className="privacy-policy-icon2">
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="privacy-policy-nav2"
              >
                <Link to="/" className="privacy-policy-navlink06">
                  Home
                </Link>
                <Link to="/contact-us" className="privacy-policy-navlink07">
                  Values
                </Link>
                <Link to="/blogs" className="privacy-policy-navlink08">
                  Blog
                </Link>
                <Link to="/f-a-qs" className="privacy-policy-navlink09">
                  FAQ&apos;s
                </Link>
              </nav>
              <div className="privacy-policy-container02">
                <Link
                  to="/build-your-team"
                  className="privacy-policy-login2 button"
                >
                  Hire Talent
                </Link>
                <Link to="/log-in" className="privacy-policy-register1 button">
                  Log In
                </Link>
                <Link
                  to="/apply-as-a-candidate"
                  className="privacy-policy-login3 button"
                >
                  Apply as a Talent
                </Link>
              </div>
            </div>
          </div>
        </header>
        <div id="mobile-menu" className="privacy-policy-mobile">
          <div className="privacy-policy-top">
            <img
              alt="image"
              src="https://play.teleporthq.io/static/svg/default-img.svg"
              className="privacy-policy-image"
            />
            <svg
              id="close-mobile-menu"
              viewBox="0 0 1024 1024"
              className="privacy-policy-icon4"
            >
              <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
            </svg>
          </div>
          <div className="privacy-policy-links">
            <span className="privacy-policy-text">Features</span>
            <span className="privacy-policy-text001">How it works</span>
            <span className="privacy-policy-text002">Prices</span>
            <span className="privacy-policy-text003">Contact</span>
            <div className="privacy-policy-buttons">
              <div className="privacy-policy-btn">
                <span className="privacy-policy-text004">Log in</span>
              </div>
              <div className="privacy-policy-btn1">
                <span className="privacy-policy-text005">Sign up</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <DangerousHTML
            html={`<script>
/*
Mobile menu - Code Embed
*/

// Mobile menu
const mobileMenu = document.querySelector("#mobile-menu")

// Buttons
const closeButton = document.querySelector("#close-mobile-menu")
const openButton = document.querySelector("#open-mobile-menu")

// On openButton click, set the mobileMenu position left to -100vw
openButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(0%)"
})

// On closeButton click, set the mobileMenu position to 0vw
closeButton.addEventListener("click", function() {
    mobileMenu.style.transform = "translateX(100%)"
})
</script>`}
          ></DangerousHTML>
        </div>
      </nav>
      <div className="privacy-policy-banner">
        <h1 className="privacy-policy-text006">privacy policy</h1>
        <span className="privacy-policy-text007">
          Before signing up for DecentraLoco, please have a look at our  privacy
          policy
        </span>
      </div>
      <div className="privacy-policy-separator"></div>
      <div className="privacy-policy-container03">
        <div className="privacy-policy-card">
          <span className="privacy-policy-text008">Personal Data</span>
          <span className="privacy-policy-text009">
            <span>
              Personal data, or personal information, means any information
              about an individual from which that person can be identified. We
              collect different information depending on your relationship with
              us.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              This Transparency Policy provides information on the sources we
              use to obtain personal details and any relevant and proportionate
              way in which we may process your personal data, or personal
              information in line with the legitimate interest of our business.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </span>
        </div>
        <div className="privacy-policy-card01">
          <span className="privacy-policy-text018">
            Which information do we hold?
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="privacy-policy-text019">
            <span>
              <span>
                The information gathered differs from person to person; the
                important personal data we aim to collect, process or store is:
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text023"></br>
            <span>
              <span>
                Individual Candidates &amp; Prospects:
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text027"></br>
            <br></br>
            <span>
              <span>
                Which information:
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text032"></br>
            <span>
              <span>
                • We DO collect, process or store personal data such as: Name;
                Curriculum Vitae; Employer &amp; previous employers, right to
                work documentation when required, Video INterviews, Date of
                birth, Contact details, Telephone number, Email address, Work
                experience, Qualifications, Other personal data obtained or
                received by us in connection with your possible and actual work
                placements, Personal data associated with managing queries and
                complaints
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text036"></br>
            <span>
              <span>
                • We DO NOT collect, process or store sensitive personal data
                such as your physical or mental health or condition, political
                opinions, religious or similar beliefs
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text040"></br>
            <br></br>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </span>
        </div>
        <div className="privacy-policy-card02">
          <span className="privacy-policy-text048">
            Why is this information collected?
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="privacy-policy-text049">
            <span>
              <span>
                • This information is used to determine whether Individual
                Candidates &amp; Prospects are relevant for specific job
                opportunities, which would benefit Decentraloco Ltd’s business
                interests.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text053"></br>
            <br></br>
            <span>
              <span>
                Individual Corporate Client Contacts:
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text058"></br>
            <br></br>
            <span>
              <span>
                Which information:
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text063"></br>
            <br></br>
            <span>
              <span>
                • We DO collect, process or store personal data such as Name;
                Employer; Contact details, Telephone number, Email address, and
                other personal data obtained or received by us in connection
                with your possible and actual work vacancies and new
                constructive business relationships; Personal data associated
                with managing queries and complaints
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text068"></br>
            <br></br>
            <span>
              <span>
                • We DO NOT collect, process or store sensitive personal data
                such as your physical or mental health or condition, political
                opinions, religious or similar beliefs
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text073"></br>
            <br></br>
            <br></br>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </span>
        </div>
        <div className="privacy-policy-card03">
          <span className="privacy-policy-text082">
            <span>
              <span>
                • This information is used to determine whether Individual
                Corporate Client Contacts require Decentraloco Ltd’s services,
                which would be in mutual benefit to Decentraloco Ltd’s business
                interests.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text086"></br>
            <span>
              <span>
                Lawful Basis for Processing
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text090"></br>
            <span>
              <span>
                Information we collect from you and about you and how our use
                complies with the law
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text094"></br>
            <span>
              <span>
                Consent: This means that you have given us your permission
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text098"></br>
            <span>
              Legitimate interests: Defined as an important part of our business
              model and enables us to carry out our business successfully -
              provided that there is no detriment to the individual
            </span>
            <br></br>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </span>
        </div>
        <div className="privacy-policy-container04">
          <div className="privacy-policy-container05">
            <span className="privacy-policy-text107">
              Information: Personal data or Personal Information
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="privacy-policy-text108">
              Activity: How we use your information
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="privacy-policy-text109">
              Legal Basis: How we comply with the Law
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
          <div className="privacy-policy-container06">
            <span className="privacy-policy-text110">
              Information: Personal data or Personal Information
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="privacy-policy-text111">
              Activity: How we use your information
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="privacy-policy-text112">
              With your consent (where applicable) otherwise for the legitimate
              interest of promoting our business, provided that you have not
              asked us to stop sending you marketing. Name, contact details,
              telephone number, email address, curriculum vitae, qualifications,
              employment &amp; experience history Manually profiling candidates,
              using the personal information to determine relevant vacancies.
              With your consent (where applicable) otherwise for the legitimate
              interest of promoting our business, provided that you have not
              asked us to stop sending you marketing.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
          <div className="privacy-policy-container07">
            <span className="privacy-policy-text113">
              Name, contact details, telephone number, email address, curriculum
              vitae, qualifications, employment &amp; experience history
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="privacy-policy-text114">
              Manually profiling candidates, using the personal information to
              determine relevant vacancies.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="privacy-policy-text115">
              With your consent (where applicable) otherwise for the legitimate
              interest of promoting our business, provided that you have not
              asked us to stop sending you marketing.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
          <div className="privacy-policy-container08">
            <span className="privacy-policy-text116">
              Name, contact details, telephone number, email address, curriculum
              vitae, qualifications, employment &amp; experience history
            </span>
            <span className="privacy-policy-text117">
              Sending Personal data or Personal Information to clients in order
              to apply for jobs
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="privacy-policy-text118">
              Consent
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
          <div className="privacy-policy-container09">
            <span className="privacy-policy-text119">
              Name, contact details, telephone number, email address, curriculum
              vitae, qualifications, employment &amp; experience history
            </span>
            <span className="privacy-policy-text120">
              To provide you with occasional marketing information
            </span>
            <span className="privacy-policy-text121">
              With your consent (where applicable) otherwise for the legitimate
              interest of promoting our business, provided that you have not
              asked us to stop sending you marketing.
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
          </div>
        </div>
        <div className="privacy-policy-card04">
          <span className="privacy-policy-text122">
            <span>
              <span>
                When &amp; How Personal Data is Collected, Processed or Stored/
                Retained
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text126"></br>
            <span>
              <span>
                Lawful Collection of Personal Data
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br></br>
            <br></br>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </span>
        </div>
        <div className="privacy-policy-card05">
          <span className="privacy-policy-text138">
            Personal data is only collected where:
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="privacy-policy-text139">
            <span>
              • The data is collected for a lawful purpose directly related to
              the legitimate business interest and function or activity of
              Decentraloco Ltd.
            </span>
            <br className="privacy-policy-text141"></br>
            <br></br>
            <span>
              <span>
                • The collection of data is necessary for or directly related to
                that purpose
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text146"></br>
            <br></br>
            <span>
              <span>
                • The data is adequate but not excessive in relation to that
                purpose
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text151"></br>
            <span>Personal Data Collection</span>
            <br></br>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br></br>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </span>
        </div>
        <div className="privacy-policy-card06">
          <span className="privacy-policy-text164">
            We may collect personal data about you when:
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="privacy-policy-text165">
            <span>
              • When you contact us directly over the telephone, email, complete
              a Decentraloco Ltd registration form or provide any other
              information in connection with an application.
            </span>
            <br className="privacy-policy-text167"></br>
            <br></br>
            <span>
              • You apply directly to any Decentraloco Ltd vacancies via a
              variety of job boards that we may use.
            </span>
            <br className="privacy-policy-text170"></br>
            <br></br>
            <span>
              <span>
                • In some cases, we will collect information about you from
                third parties such as job boards (e.g. Reed and other similar
                platforms)
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text175"></br>
            <br></br>
            <span>
              <span>
                • In some cases, we will collect information about you from
                third party social media platforms such as LinkedIn.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text180"></br>
            <br></br>
            <span>• We receive any reference about you.</span>
            <br className="privacy-policy-text183"></br>
            <br></br>
            <span>
              • We receive any complaint or other information from or about you
              in the workplace.
            </span>
            <br className="privacy-policy-text186"></br>
            <br></br>
            <span>
              <span>
                • You provide us with any additional information about you
                Provision of services.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text191"></br>
            <br></br>
            <span>
              <span>
                • Please note that it is mandatory for you to provide us with
                such personal data in order for us to be able to provide you
                with our services.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text196"></br>
            <br></br>
            <span>
              <span>
                • Your personal data may be used in connection with:
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text201"></br>
            <span>
              <span>
                 - Your actual, possible or potential work placement.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text205"></br>
            <span>
              <span>
                 - Proactively approaching relevant firms and professional
                networks.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text209"></br>
            <span>
              <span>
                 - Career guidance or management.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text213"></br>
            <span>
              <span>
                 - Our assessment of your ongoing performance and prospects.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text217"></br>
            <span>
              <span>
                 - Our identification of your training needs.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text221"></br>
            <span>
               - Our management and resolution of any complaint, inquiry or
              investigation in which you are involved.
            </span>
            <br className="privacy-policy-text223"></br>
            <span>
              <span>
                 - Any insurance claim or proposal that requires disclosure of
                your personal or sensitive information.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text227"></br>
            <span>
              <span>
                 - For research, development, business systems and
                infrastructure testing, and other business purposes to assist us
                in providing our services to you.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text231"></br>
            <span>
              <span>
                 - Providing our services to our clients.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br></br>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br></br>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </span>
        </div>
        <div className="privacy-policy-card07">
          <span className="privacy-policy-text246">
            Retention of Candidate Data:
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="privacy-policy-text247">
            <span>
              <span>
                • We have the right to keep an individual’s personal details
                until we are instructed that they no longer wish us to do so. We
                have a legitimate interest in keeping individuals’ information
                on file as many candidates can be considered passive if not
                actively looking – this enables us to continue providing an
                effective and efficient recruitment service.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text251"></br>
            <br></br>
            <span>
              <span>
                • We have on numerous previous occasions contacted individuals
                after periods of lapsed contact when potentially suitable
                positions have arisen in their stated job search.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text256"></br>
            <br></br>
            <span>
              <span>
                • In the event that an individual asks for their contact details
                to be removed, we will do so immediately. Consultants must flag
                any such occurrences up to the directors who will the action the
                request accordingly.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text261"></br>
            <br></br>
            <span>
              <span>
                • Until such time that you wish to exercise your right to
                restrict our processing of your data we will continue to do so
                under the lawful basis of our legitimate interest to provide you
                with information on relevant opportunities, updates and industry
                news.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text266"></br>
            <br></br>
            <span>
              <span>
                Management of personal data
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text271"></br>
            <br></br>
            <span>
              <span>
                • At Decentraloco Ltd, we train our staff to respect the
                confidentiality of customer information and the privacy of
                individuals. Decentraloco Ltd regard breaches of your privacy
                very seriously and any breach will result in disciplinary action
                being taken, dependent upon severity. How do we store &amp;
                protect personal data?
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text276"></br>
            <br></br>
            <span>
              <span>
                • Safeguarding the privacy of your personal data is important to
                us, whether you interact with us personally, by phone, mail,
                over the internet or other electronic medium.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text281"></br>
            <br></br>
            <span>
              <span>
                • We hold personal data in a combination of secure cloud-based
                applications; web-based servers; Client Relationship Management
                database systems, secure computer storage facilities, and take
                such steps as are reasonable in the circumstances to protect the
                personal data we hold from misuse, interference and loss,
                unauthorised access, modification or disclosure. We may need to
                maintain records for a significant period of time. However, when
                we consider data is no longer needed for the fulfilment of the
                purpose (including any directly related purpose) for which the
                data is or is to be used, we will remove any details that will
                identify you or we will securely destroy the records.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text286"></br>
            <br></br>
            <span>
              <span>
                How do we keep personal data accurate &amp; up to date?
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text291"></br>
            <br></br>
            <span>
              <span>
                • Decentraloco Ltd take reasonable steps to ensure that the
                personal information it holds and discloses is accurate up to
                date and complete. We recognise that personal data changes
                frequently with changes of address and other personal
                circumstances. We generally update your customer data over the
                telephone or video call. Please advise your consultant when your
                personal details change.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text296"></br>
            <br></br>
            <span>
              • You can delete your personal information or unsubscribe for any
              form of contact by contacting us directly or usining the
              unsubscribe links in our literature and emails.
            </span>
            <br></br>
            <br className="privacy-policy-text300"></br>
          </span>
        </div>
        <div className="privacy-policy-card08">
          <span className="privacy-policy-text301">
            Your Rights:
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="privacy-policy-text302">
            <span>
              <span>
                You have the following rights:
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text306"></br>
            <br></br>
            <span>
              <span>
                • To be told what we are doing with your personal information.
                We do this by providing you with this transparency notice;
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text311"></br>
            <br></br>
            <span>
              <span>
                • To correct or update the personal information we hold about
                you: you can do this by emailing – info@decenytraloco.xyz or
                logging into your portal.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text316"></br>
            <br></br>
            <span>
              <span>
                • To object to the processing of your personal information;
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text321"></br>
            <br></br>
            <span>
              <span>
                • To request a copy of the personal information we hold about
                you;
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text326"></br>
            <br></br>
            <span>
              <span>
                • To ask us to delete the information that we hold about you
                where there is no good reason for us continuing to process it;
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text331"></br>
            <br></br>
            <span>
              <span>
                • To ask us to stop processing your personal information where
                we are relying on a legitimate interest and there is something
                about your particular situation which makes you want to object
                to processing on this ground;
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text336"></br>
            <br></br>
            <span>
              <span>
                • To ask us to restrict how we use your personal information for
                a period of time if you claim that it is inaccurate, and we want
                to verify the position or in some limited other circumstances;
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text341"></br>
            <br></br>
            <span>
              <span>
                • To ask us to send your personal information to another
                organisation in a computer-readable format;
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text346"></br>
            <br></br>
            <span>
              <span>
                • To complain to the Information Commissioner&apos;s office if
                you are unhappy with our use of your personal data: you can do
                this at https://ico.org.uk/concerns. Do contact us straight away
                if you consider that we are not handling your personal
                information properly, so we can try and sort the problem out.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text351"></br>
            <br></br>
            <span>
              • If we delete your personal information or restrict our use of
              it, we will not be able to provide our services to you. • If you
              want to exercise any of your rights, please contact -
              info@decentraloco.xyz. We may need to request specific information
              from you to help us confirm your identity and ensure your right to
              access the information (or to exercise any of your other rights).
            </span>
            <br></br>
            <br></br>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </span>
        </div>
        <div className="privacy-policy-card09">
          <span className="privacy-policy-text359">
            Enquiries &amp; Complaints:
          </span>
          <span className="privacy-policy-text360">
            <span>
              <span>
                • You can make enquiries, requests to access/delete or correct
                your information, or complain about alleged breaches to, or
                otherwise contact us on:
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <br className="privacy-policy-text364"></br>
            <br></br>
            <span>Email: james@decentraloco.xyz.</span>
            <br className="privacy-policy-text367"></br>
            <br></br>
            <span>
              <span>
                • We aim to acknowledge receipt of all complaints within 10
                working days and aim to resolve all complaints within 40 days.
                This may not be possible in all circumstances depending on the
                contents of the complaint. In this situation, we will respond to
                your complaint in a reasonable time.
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </span>
        </div>
        <div className="privacy-policy-card10">
          <span className="privacy-policy-text372">Feedback:</span>
          <span className="privacy-policy-text373">
            <span>
              • Please also contact us via the contact details above to provide
              any feedback, we are always interested to hear of our candidate’s
              and client’s views on our service as we are are continually
              working to build safer data-led services.
            </span>
            <span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
          </span>
        </div>
      </div>
      <Footer rootClassName="footer-root-class-name3"></Footer>
    </div>
  );
};

export default PrivacyPolicy;
