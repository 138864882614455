import React from "react";
import { Link } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import PropTypes from "prop-types";

import "./navbar-log-in.css";

const NavbarLogIn = (props) => {
  return (
    <nav className={`navbar-log-in-navbar ${props.rootClassName} `}>
      <div className="navbar-log-in-desktop">
        <div className="navbar-log-in-branding">
          <Link to="/" className="navbar-log-in-navlink">
            <img
              alt={props.Branding_alt}
              src={props.Branding_src}
              className="navbar-log-in-finbest"
            />
          </Link>
        </div>
      </div>
      <div id="mobile-menu" className="navbar-log-in-mobile">
        <div className="navbar-log-in-top">
          <img
            alt={props.image_alt}
            src={props.image_src}
            className="navbar-log-in-image"
          />
          <svg
            id="close-mobile-menu"
            viewBox="0 0 1024 1024"
            className="navbar-log-in-icon"
          >
            <path
              d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
              className=""
            ></path>
          </svg>
        </div>
        <div className="navbar-log-in-links">
          <span className="navbar-log-in-text">{props.text1}</span>
          <span className="navbar-log-in-text1">{props.text11}</span>
          <span className="navbar-log-in-text2">{props.text12}</span>
          <span className="navbar-log-in-text3">{props.text13}</span>
          <div className="navbar-log-in-buttons">
            <div className="navbar-log-in-btn">
              <span className="navbar-log-in-text4">{props.text131}</span>
            </div>
            <div className="navbar-log-in-btn1">
              <span className="navbar-log-in-text5">{props.text1311}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <DangerousHTML
          html={`<script>
/*
Mobile menu - Code Embed
*/

window.onload = () => {
  // Mobile menu
  const mobileMenu = document.querySelector("#mobile-menu")
  
  // Buttons
  const closeButton = document.querySelector("#close-mobile-menu")
  const openButton = document.querySelector("#open-mobile-menu")
  
  // On openButton click, set the mobileMenu position left to -100vw
  openButton.addEventListener("click", function() {
      mobileMenu.style.transform = "translateX(0%)"
  })
  
  // On closeButton click, set the mobileMenu position to 0vw
  closeButton.addEventListener("click", function() {
      mobileMenu.style.transform = "translateX(100%)"
  })
}
</script>`}
          className=""
        ></DangerousHTML>
      </div>
    </nav>
  );
};

NavbarLogIn.defaultProps = {
  text131: "Log in",
  Branding_alt: "pastedImage",
  text13: "Contact",
  text12: "Prices",
  text1311: "Sign up",
  rootClassName: "",
  image_alt: "image",
  text11: "How it not works",
  Branding_src: "/playground_assets/logo-200h.png",
  text1: "Features",
  image_src: "https://play.teleporthq.io/static/svg/default-img.svg",
};

NavbarLogIn.propTypes = {
  text131: PropTypes.string,
  Branding_alt: PropTypes.string,
  text13: PropTypes.string,
  text12: PropTypes.string,
  text1311: PropTypes.string,
  rootClassName: PropTypes.string,
  image_alt: PropTypes.string,
  text11: PropTypes.string,
  Branding_src: PropTypes.string,
  text1: PropTypes.string,
  image_src: PropTypes.string,
};

export default NavbarLogIn;
