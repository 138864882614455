import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./client-step-2.css";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import ClientProtectedSteps from "../../utils/ClientProtectedSteps";

const ClientStep2 = (props) => {
  return (
    <CandidateProtectedRoute>
      <ClientProtectedSteps>
        <div className="client-step2-container">
          <Helmet>
            <title>Client-step-2 - Decentraloco</title>
            <meta property="og:title" content="Client-step-2 - Decentraloco" />
          </Helmet>
          <NavbarLogIn rootClassName="navbar-log-in-root-class-name10"></NavbarLogIn>
          <div className="client-step2-container01">
            <div className="client-step2-container02">
              <span className="client-step2-text">Step 2 of 5</span>
              <div className="client-step2-container03">
                <div className="client-step2-container04"></div>
              </div>
            </div>
            <div className="client-step2-container05">
              <span className="client-step2-text01">
                First, Tell us about your project ?
              </span>
              <span className="client-step2-text02">
                Let’s start with the basics, tell us about who you are
              </span>
            </div>
            <div className="client-step2-container06">
              <span className="client-step2-text03">Your hiring needs ?</span>
              <div className="client-step2-container07">
                <button type="button" className="client-step2-button button">
                  <span className="client-step2-text04">Full time</span>
                </button>
                <button type="button" className="client-step2-button1 button">
                  <span className="client-step2-text05">Part time</span>
                </button>
                <button type="button" className="client-step2-button2 button">
                  <span className="client-step2-text06">Depends</span>
                </button>
              </div>
            </div>
            <div className="client-step2-container08">
              <span className="client-step2-text07">Company size</span>
              <div className="client-step2-container09">
                <button type="button" className="client-step2-button3 button">
                  <span className="client-step2-text08">1/10</span>
                </button>
                <button type="button" className="client-step2-button4 button">
                  <span className="client-step2-text09">11/100</span>
                </button>
                <button type="button" className="client-step2-button5 button">
                  <span className="client-step2-text10">101/1K</span>
                </button>
                <button type="button" className="client-step2-button6 button">
                  <span className="client-step2-text11">+1K</span>
                </button>
              </div>
            </div>
          </div>
          <div className="client-step2-container10">
            <Link
              to="/client-step-3"
              type="reset"
              disabled
              className="client-step2-button7 button"
            >
              Skip this step
            </Link>
            <Link to="/client-step-3" className="client-step2-navlink button">
              <span className="client-step2-text12">Next</span>
            </Link>
          </div>
          <FooterV2 rootClassName="footer-v2-root-class-name9"></FooterV2>
        </div>
      </ClientProtectedSteps>
    </CandidateProtectedRoute>
  );
};

export default ClientStep2;
