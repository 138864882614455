import React from "react";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";

import "./jobs-board.css";

const JobsBoard = (props) => {
  const { jobsList, user, applyToJob, openPreview } = props;
  const firstCol = [jobsList?.[0], jobsList?.[3]]
  const secondCol = [jobsList?.[1], jobsList?.[4]]
  const thirdCol = [jobsList?.[2], jobsList?.[5]]

  return (
    <section className="jobs-board-section">
      <header className="jobs-board-header">
        <h2 className="jobs-board-heading section-heading">
          <span>
            Available
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="jobs-board-text01">Jobs</span>
        </h2>
      </header>
      <main className="jobs-board-cards">
        <div className="jobs-board-container">
          {
            firstCol?.length > 0 && firstCol.map((job, indx) => (
              <div className={`jobs-board-container01`} key={indx} onClick={() => openPreview(job)} >
                <img
                  alt={props.image_alt}
                  src={job?.companyLogo  || "/playground_assets/DECENTRA-LOGO-1.jpg"}
                  className="jobs-board-image"
                />
                {/* <svg viewBox="0 0 1024 1024" className="jobs-board-icon">
                  <path d="M512 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM768 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM256 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26z"></path>
                </svg> */}
                <span className="jobs-board-text02">
                  { job?.jobTitle }
                </span>
                <div className="jobs-board-text03" style={{ minHeight: '107px' }}>
                  { job?.shortDescription?.substring(0, 255) + (job?.shortDescription?.length > 255 ? '  ...' : '') }
                </div>
                <div className="jobs-board-container02">
                  <button className="jobs-board-button button">{job?.workplaceType || job?.workspaceType}</button>
                  <button className="jobs-board-button button">{job?.salaryRange}</button>
                </div>
                <div className="jobs-board-container03">
                { job?.applies?.map(ap => ap.user_uid).includes(user?.userUID) ?
                  <button disable={true} className="jobs-board-navlink">
                    <div className="jobs-board-sign-up-btn button disabled">
                      <span className="jobs-board-sign-up">Your application has been received</span>
                    </div>
                  </button>
                  :
                  <button onClick={(ev) => {ev.stopPropagation(); applyToJob(job)}} className="jobs-board-navlink">
                    <div className="jobs-board-sign-up-btn button">
                      <span className="jobs-board-sign-up">{props.SignUp}</span>
                    </div>
                  </button>
                }
                </div>
              </div>
            ))
          }
          {/* <div className="jobs-board-container01">
            <img
              alt={props.image_alt}
              src="/playground_assets/DECENTRA-LOGO-1.jpg"
              className="jobs-board-image"
            />
            <svg viewBox="0 0 1024 1024" className="jobs-board-icon">
              <path d="M512 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM768 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM256 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26z"></path>
            </svg>
            <span className="jobs-board-text02">
              Senior Web3 Front-End Engineer
            </span>
            <div className="jobs-board-text03">
              Up to $200k <br />
              We are seeking a talented and experienced Senior Web3 Front-End
              Engineer to join a dynamic team in the Web3 space. As a Senior
              Web3 Front-End Engineer, you will play...
            </div>
            <div className="jobs-board-container02">
              <button className="jobs-board-button button">Remote</button>
            </div>
            <div className="jobs-board-container03">
              <Link to="/candidate-step-0" className="jobs-board-navlink">
                <div className="jobs-board-sign-up-btn button">
                  <span className="jobs-board-sign-up">{props.SignUp}</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="jobs-board-container04">
            <img
              alt={props.image_alt1}
              src="/playground_assets/DECENTRA-LOGO-1.jpg"
              className="jobs-board-image1"
            />
            <svg viewBox="0 0 1024 1024" className="jobs-board-icon02">
              <path d="M512 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM768 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM256 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26z"></path>
            </svg>
            <span className="jobs-board-text04">Senior Solidity Developer</span>
            <span className="jobs-board-text05">
              Up to $300k
              <br />
              We are seeking a highly skilled and motivated Solidity Developer
              to join an establised team at a leading DeFi protocol. As a key
              member of the engineering team, you will be responsible...
            </span>
            <div className="jobs-board-container05">
              <button className="jobs-board-button03 button">Remote</button>
            </div>
            <div className="jobs-board-container06">
              <Link to="/candidate-step-0" className="jobs-board-navlink1">
                <div className="jobs-board-sign-up-btn1 button">
                  <span className="jobs-board-sign-up1">{props.SignUp1}</span>
                </div>
              </Link>
            </div>
          </div> */}
        </div>
        <div className="jobs-board-container07">

        {
            secondCol?.length > 0 && secondCol.map((job, indx) => (
              <div className={`jobs-board-container01`} key={indx} onClick={() => openPreview(job)}>
                <img
                  alt={props.image_alt}
                  src={job?.companyLogo  || "/playground_assets/DECENTRA-LOGO-1.jpg"}
                  className="jobs-board-image"
                />
                {/* <svg viewBox="0 0 1024 1024" className="jobs-board-icon">disabled
                  <path d="M512 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM768 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM256 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26z"></path>
                </svg> */}
                <span className="jobs-board-text02">
                  { job?.jobTitle }
                </span>
                <div className="jobs-board-text03" style={{ minHeight: '107px' }}>
                  { job?.shortDescription?.substring(0, 255) + (job?.shortDescription?.length > 255 ? '  ...' : '') }
                </div>
                <div className="jobs-board-container02">
                  <button className="jobs-board-button button">{job?.workplaceType || job?.workspaceType}</button>
                  <button className="jobs-board-button button">{job?.salaryRange}</button>
                </div>
                <div className="jobs-board-container03">
                  { job?.applies?.map(ap => ap.user_uid).includes(user?.userUID) ?
                    <button disabled className="jobs-board-navlink">
                      <div className="jobs-board-sign-up-btn button disabled">
                        <span className="jobs-board-sign-up">Your application has been received</span>
                      </div>
                    </button>
                    :
                    <button onClick={(ev) => {ev.stopPropagation(); applyToJob(job)}} className="jobs-board-navlink">
                      <div className="jobs-board-sign-up-btn button">
                        <span className="jobs-board-sign-up">{props.SignUp}</span>
                      </div>
                    </button>
                  }
                </div>
              </div>
            ))
          }
        </div>
        <div className="jobs-board-container14">
        {
            thirdCol?.length > 0 && thirdCol.map((job, indx) => (
              <div className={`jobs-board-container01`} key={indx} onClick={() => openPreview(job)}>
                <img
                  alt={props.image_alt}
                  src={job?.companyLogo  || "/playground_assets/DECENTRA-LOGO-1.jpg"}
                  className="jobs-board-image"
                />
                {/* <svg viewBox="0 0 1024 1024" className="jobs-board-icon">
                  <path d="M512 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM768 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26zM256 426q34 0 60 26t26 60-26 60-60 26-60-26-26-60 26-60 60-26z"></path>
                </svg> */}
                <span className="jobs-board-text02">
                  { job?.jobTitle }
                </span>
                <div className="jobs-board-text03" style={{ minHeight: '107px' }}>
                  { job?.shortDescription?.substring(0, 255) + (job?.shortDescription?.length > 255 ? '  ...' : '') }
                </div>
                <div className="jobs-board-container02">
                  <button className="jobs-board-button button">{job?.workplaceType || job?.workspaceType}</button>
                  <button className="jobs-board-button button">{job?.salaryRange}</button>
                </div>
                <div className="jobs-board-container03">
                  { job?.applies?.map(ap => ap.user_uid).includes(user?.userUID) ?
                    <button disabled className="jobs-board-navlink">
                      <div className="jobs-board-sign-up-btn button disabled">
                        <span className="jobs-board-sign-up">Your application has been received</span>
                      </div>
                    </button>
                    :
                    <button onClick={(ev) => {ev.stopPropagation(); applyToJob(job)}} className="jobs-board-navlink">
                      <div className="jobs-board-sign-up-btn button">
                        <span className="jobs-board-sign-up">{props.SignUp}</span>
                      </div>
                    </button>
                  }
                </div>
              </div>
            ))
          }
        </div>
      </main>
    </section>
  );
};

JobsBoard.defaultProps = {
  text7:
    "Proin tempor posuere elit sit amet blandit. Nullam pretium libero tristique, pellentesque quam quis, aliquet magna. Pellentesque sagittis dictum malesuada. ",
  button4: "Full-time",
  text10: "Job title",
  button10: "Full-time",
  button6: "Full-time",
  SignUp: "Apply now",
  button12: "Full-time",
  text9:
    "Proin tempor posuere elit sit amet blandit. Nullam pretium libero tristique, pellentesque quam quis, aliquet magna. Pellentesque sagittis dictum malesuada. ",
  text2: "Job title",
  button13: "Full-time",
  image_alt1: "image",
  button5: "Full-time",
  text3:
    "Proin tempor posuere elit sit amet blandit. Nullam pretium libero tristique, pellentesque quam quis, aliquet magna. Pellentesque sagittis dictum malesuada. ",
  text1:
    "Proin tempor posuere elit sit amet blandit. Nullam pretium libero tristique, pellentesque quam quis, aliquet magna. Pellentesque sagittis dictum malesuada. ",
  button14: "Full-time",
  image_src5: "https://play.teleporthq.io/static/svg/default-img.svg",
  button2: "Full-time",
  SignUp1: "Apply now",
  button7: "Full-time",
  SignUp2: "Apply now",
  image_src1: "https://play.teleporthq.io/static/svg/default-img.svg",
  button8: "Full-time",
  button11: "Full-time",
  button: "Full-time",
  image_src4: "https://play.teleporthq.io/static/svg/default-img.svg",
  button15: "Full-time",
  image_alt5: "image",
  button1: "Full-time",
  button16: "Full-time",
  button9: "Full-time",
  image_src3: "https://play.teleporthq.io/static/svg/default-img.svg",
  text11:
    "Proin tempor posuere elit sit amet blandit. Nullam pretium libero tristique, pellentesque quam quis, aliquet magna. Pellentesque sagittis dictum malesuada. ",
  SignUp3: "Apply now",
  text6: "Job title",
  text4: "Job title",
  text5:
    "Proin tempor posuere elit sit amet blandit. Nullam pretium libero tristique, pellentesque quam quis, aliquet magna. Pellentesque sagittis dictum malesuada. ",
  text8: "Job title",
  image_alt3: "image",
  image_src: "https://play.teleporthq.io/static/svg/default-img.svg",
  image_alt4: "image",
  button3: "Full-time",
  image_src2: "https://play.teleporthq.io/static/svg/default-img.svg",
  SignUp5: "Apply now",
  image_alt: "image",
  text: "Job title",
  image_alt2: "image",
  SignUp4: "Apply now",
  button17: "Full-time",
};

JobsBoard.propTypes = {
  text7: PropTypes.string,
  button4: PropTypes.string,
  text10: PropTypes.string,
  button10: PropTypes.string,
  button6: PropTypes.string,
  SignUp: PropTypes.string,
  button12: PropTypes.string,
  text9: PropTypes.string,
  text2: PropTypes.string,
  button13: PropTypes.string,
  image_alt1: PropTypes.string,
  button5: PropTypes.string,
  text3: PropTypes.string,
  text1: PropTypes.string,
  button14: PropTypes.string,
  image_src5: PropTypes.string,
  button2: PropTypes.string,
  SignUp1: PropTypes.string,
  button7: PropTypes.string,
  SignUp2: PropTypes.string,
  image_src1: PropTypes.string,
  button8: PropTypes.string,
  button11: PropTypes.string,
  button: PropTypes.string,
  image_src4: PropTypes.string,
  button15: PropTypes.string,
  image_alt5: PropTypes.string,
  button1: PropTypes.string,
  button16: PropTypes.string,
  button9: PropTypes.string,
  image_src3: PropTypes.string,
  text11: PropTypes.string,
  SignUp3: PropTypes.string,
  text6: PropTypes.string,
  text4: PropTypes.string,
  text5: PropTypes.string,
  text8: PropTypes.string,
  image_alt3: PropTypes.string,
  image_src: PropTypes.string,
  image_alt4: PropTypes.string,
  button3: PropTypes.string,
  image_src2: PropTypes.string,
  SignUp5: PropTypes.string,
  image_alt: PropTypes.string,
  text: PropTypes.string,
  image_alt2: PropTypes.string,
  SignUp4: PropTypes.string,
  button17: PropTypes.string,
};

export default JobsBoard;
