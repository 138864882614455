// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyACqgtBY6Ho2HFq2t8O9htxAet0flvupqI",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: "james-king-eb8d2",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  storageBucket: "james-king-eb8d2.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: "1:685569557546:web:c51899219fc04d62b4ef53",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);

export const auth = getAuth(app);
export const db = getFirestore(app);

export const storage = getStorage(app);
