import React from "react";

import PropTypes from "prop-types";

import "./ourprocess.css";

const OurProcess = (props) => {
  return (
    <div className="ourprocess-container">
      <header className="ourprocess-header">
        <h2 className="ourprocess-heading">
          <span className="ourprocess-text">
            Our
            <span
              dangerouslySetInnerHTML={{
                __html: " ",
              }}
            />
          </span>
          <span className="ourprocess-text01">Process</span>
          <br></br>
        </h2>
      </header>
      <main className="ourprocess-cards">
        <div className="ourprocess-container01">
          <section className="ourprocess-card">
            <main className="ourprocess-content">
              <h1 className="ourprocess-header1">Job Post</h1>
              <div className="ourprocess-container02"></div>
              <p className="ourprocess-description">
                <span>
                  Collaborating in bringing the best, most relevant job spec to
                  the process whilst considering salary ranges and EDI
                  statements/policy.
                </span>
                <span></span>
              </p>
            </main>
          </section>
          <section className="ourprocess-card1">
            <main className="ourprocess-content1">
              <h1 className="ourprocess-header2">
                Outreach
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </h1>
              <div className="ourprocess-container03"></div>
              <p className="ourprocess-description1">
                <span>
                  We drive traffic to the job which consists of video, email,
                  discord, telegram, LinkedIn, and GitHub campaigns. 
                </span>
                <span></span>
              </p>
            </main>
          </section>
        </div>
        <div className="ourprocess-container04">
          <section className="ourprocess-card2">
            <main className="ourprocess-content2">
              <h1 className="ourprocess-header3">Screening</h1>
              <div className="ourprocess-container05"></div>
              <p className="ourprocess-description2">
                <span>
                  We conduct live Zoom screening calls where we scrutinise work
                  history, strengths & weaknesses, interests & objectives to
                  gain a full understanding of the candidate’s ability and
                  attitude. 
                </span>
                <span></span>
              </p>
            </main>
          </section>
          <section className="ourprocess-card3">
            <main className="ourprocess-content3">
              <h1 className="ourprocess-header4">
                Video Recording
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </h1>
              <div className="ourprocess-container06"></div>
              <p className="ourprocess-description3">
                <span>
                  We build in some of the questions from your interview process
                  to shoulder as much of the screening burden as possible
                  freeing time for you to interview only top-tier vetted
                  candidates. 
                </span>
                <br className="ourprocess-text10"></br>
                <br></br>
              </p>
            </main>
          </section>
        </div>
      </main>
      <main className="ourprocess-cards1">
        <div className="ourprocess-container07">
          <section className="ourprocess-card4">
            <main className="ourprocess-content4">
              <h1 className="ourprocess-header5">
                Profiles
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </h1>
              <div className="ourprocess-container08"></div>
              <p className="ourprocess-description4">
                <span>
                  We package up the resumes, notes, briefs, salary information,
                  availability, GitHub/portfolio/shipped-title links and video
                  recording. 
                </span>
                <br></br>
              </p>
            </main>
          </section>
          <section className="ourprocess-card5">
            <main className="ourprocess-content5">
              <h1 className="ourprocess-header6">Portal</h1>
              <div className="ourprocess-container09"></div>
              <p className="ourprocess-description5">
                Gives you access to all details via one central location where
                you can view videos, read resumes, make notes, request
                interviews, provide feedback, and move candidates to the next
                stages or reject. 
              </p>
            </main>
          </section>
        </div>
        <div className="ourprocess-container10">
          <section className="ourprocess-card6">
            <main className="ourprocess-content6">
              <h1 className="ourprocess-header7">Interview &amp; Prep</h1>
              <div className="ourprocess-container11"></div>
              <p className="ourprocess-description6">
                <span>
                  We consult on interview techniques, structure and strategy for
                  both candidates and the hiring teams when needed. A
                  well-considered interview process is the key to onboarding the
                  right candidates the first time.
                </span>
                <br className="ourprocess-text15"></br>
              </p>
            </main>
          </section>
          <section className="ourprocess-card7">
            <main className="ourprocess-content7">
              <h1 className="ourprocess-header8">
                Onboarding
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </h1>
              <div className="ourprocess-container12"></div>
              <p className="ourprocess-description7">
                <span>
                  Now you have selected your candidate/s we can help with Salary
                  & Rate negotiation, start dates, KYC's & Referencing and NDAs.
                </span>
                <br className="ourprocess-text17"></br>
                <br></br>
              </p>
            </main>
          </section>
        </div>
      </main>
    </div>
  );
};

OurProcess.defaultProps = {
  Header3: "Upload your resume",
  image_src32: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt11: "image",
  image_alt: "image",
  image_src4: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  Header2: "01",
  Header1: "Register account",
  Description2:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
  image_src: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  Description1:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
  image_alt12: "image",
  image_src2: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  image_src1: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt22: "image",
  Header5: "Apply for job",
  image_alt32: "image",
  Header21: "02",
  image_alt31: "image",
  image_src21: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  Header: "01",
  image_src11: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt5: "image",
  Description: "Register to join DecentraLoco Talent Network",
  Header31: "Upload your resume",
  image_src5: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  image_alt21: "image",
  image_alt1: "image",
  image_src22: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  image_src12: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_src3: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt4: "image",
  image_alt2: "image",
  Header4: "03",
  image_alt3: "image",
  image_src31: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  Description11:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
  text: "How to get started with",
  text1: "DecentraLoco",
};

OurProcess.propTypes = {
  Header3: PropTypes.string,
  image_src32: PropTypes.string,
  image_alt11: PropTypes.string,
  image_alt: PropTypes.string,
  image_src4: PropTypes.string,
  Header2: PropTypes.string,
  Header1: PropTypes.string,
  Description2: PropTypes.string,
  image_src: PropTypes.string,
  Description1: PropTypes.string,
  image_alt12: PropTypes.string,
  image_src2: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt22: PropTypes.string,
  Header5: PropTypes.string,
  image_alt32: PropTypes.string,
  Header21: PropTypes.string,
  image_alt31: PropTypes.string,
  image_src21: PropTypes.string,
  Header: PropTypes.string,
  image_src11: PropTypes.string,
  image_alt5: PropTypes.string,
  Description: PropTypes.string,
  Header31: PropTypes.string,
  image_src5: PropTypes.string,
  image_alt21: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src22: PropTypes.string,
  image_src12: PropTypes.string,
  image_src3: PropTypes.string,
  image_alt4: PropTypes.string,
  image_alt2: PropTypes.string,
  Header4: PropTypes.string,
  image_alt3: PropTypes.string,
  image_src31: PropTypes.string,
  Description11: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
};

export default OurProcess;
