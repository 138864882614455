import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { auth, db } from "../../database/firebase";
import { useUserState } from "../../utils/stateContext";

const AdminProtectedRoute = ({ children }) => {
  const { user } = useUserState();

  return user?.isAdmin ? (
    children
  ) : (
    <Navigate to={`/decentradmin/login`}></Navigate>
  );
};

export default AdminProtectedRoute;
