import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import { useAppState } from "../../utils/stateContext";
import "./client-step-1.css";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import ClientProtectedSteps from "../../utils/ClientProtectedSteps";

const industries = [
  "Accounting",
  "Artificial Intelligent",
  "AR/VR",
  "Blockchain",
  "Booking",
  "Communication",
  "Consultation",
  "Content creator platform",
  "Data science & Big data",
  "eCommerce",
  "Education",
  "Entertaiment",
  "Fintech",
  "Food service",
  "Gaming",
  "Health",
  "HR Management",
  "Marketing",
  "Media",
  "News",
  "Payment Transaction",
  "Team as a service",
  "Other...",
];

const ClientStep1 = (props) => {
  const navigate = useNavigate();
  const { appState, setAppState } = useAppState();
  const {
    formState: { errors },
    getValues,
    handleSubmit,
    register,
  } = useForm({
    defaultValues: appState.client,
  });

  // Functions
  const saveData = (data) => {
    setAppState({ ...appState, client: { ...data } });
    navigate("/client-step-4");
  };
  return (
    <div className="client-step1-container">
      <Helmet>
        <title>Client-step-1 - Decentraloco</title>
        <meta property="og:title" content="Client-step-1 - Decentraloco" />
      </Helmet>
      <NavbarLogIn rootClassName="navbar-log-in-root-class-name3"></NavbarLogIn>
      <div className="client-step1-container01">
        <div className="client-step1-container02">
          <span className="client-step1-text">
            <span className="client-step1-text01">Step 1 of 3</span>
            <br></br>
          </span>
          <div className="client-step1-container03">
            <div className="client-step1-container04"></div>
          </div>
        </div>
        <div className="client-step1-container05">
          <span className="client-step1-text03">
            First, Tell us about your project ?
          </span>
          <span className="client-step1-text04">
            Let’s start with the basics, tell us about your project
          </span>
        </div>
        <div className="client-step1-container06">
          <span className="client-step1-text05">Project name</span>
          <input
            {...register("projectName", {
              required: true,
              maxLength: 64,
              minLength: 3,
            })}
            type="text"
            placeholder="project name"
            value={appState.projectName}
            className={
              "client-step1-textinput teleport-show input " +
              (errors.firstName ? "error-border" : "")
            }
          />
          {errors.projectName && (
            <p className="error-text">*Required, must have 3-64 chars</p>
          )}
        </div>
        <div className="client-step1-container07">
          <span className="client-step1-text06">Choose industry</span>
          <select
            defaultValue={appState.industry}
            className={
              "candidate-step1-select input  " +
              (errors.country ? "error-border" : "")
            }
            {...register("industry", { required: true })}
          >
            <option value="" disabled hidden>
              -- Select an option --
            </option>
            {industries.map((item) => (
              <option
                value={item.toLocaleLowerCase().replaceAll(" ", "-")}
                key={item}
              >
                {item}
              </option>
            ))}
          </select>
          {errors.industry && (
            <p className="error-text">*Required, please choose an industry</p>
          )}
        </div>
        <div className="client-step1-container08">
          <span className="client-step1-text07">Tell us about you project</span>
          <textarea
            {...register("projectDetails", {
              required: true,
              minLength: 24,
            })}
            value={appState.projectDetails}
            placeholder="Tell us about you project"
            className="client-step1-textinput2 teleport-show input"
          />
          {errors.projectDetails && (
            <p className="error-text">*Required, must have minimum 24 chars</p>
          )}
        </div>
        <div className="client-step1-container09">
          <Link
            to="/client-step-0"
            disabled
            className="client-step1-navlink button"
          >
            <span className="client-step1-text08">
              <span>Back</span>
              <br></br>
            </span>
          </Link>
          <Link
            className="client-step1-navlink1 button"
            onClick={handleSubmit(saveData)}
          >
            <span className="client-step1-text11">Next</span>
          </Link>
        </div>
      </div>
      <FooterV2 rootClassName="footer-v2-root-class-name2"></FooterV2>
    </div>
  );
};

export default ClientStep1;
