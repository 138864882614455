import React from "react";

import PropTypes from "prop-types";

import "./nextsteps.css";
import { Link } from "react-router-dom";

const OurNextSteps = (props) => {
  return (
    <>
      <div className="pricing-pricing">
        <div className="pricing-container03">
          <div className="pricing-container04">
            <div className="pricing-container05">
              <div className="pricing-pricing-card">
                <div className="pricing-container06">
                  <span className="pricing-text012">Discovery Call</span>
                  {/* <span className="pricing-tagline">Teams as a Service</span> */}
                  <span className="pricing-text013">
                    <span>Fact-Find and Consultation</span>
                    <br></br>
                  </span>
                </div>
                <div className="pricing-separator1"></div>
                <div className="pricing-container07">
                  <span className="pricing-text016">
                    <span className="pricing-text017">✔</span>
                    <span>  Review Existing Process</span>
                  </span>
                  <span className="pricing-text019">
                    <span className="pricing-text020">✔</span>
                    <span>  Understand Objectives</span>
                  </span>
                  <span className="pricing-text022">
                    <span className="pricing-text023">✔</span>
                    <span>  Discover Solutions</span>
                  </span>
                  <span className="pricing-text025">
                    <span className="pricing-text026">✔</span>
                    <span>  Agree Terms</span>
                  </span>
                  <span className="pricing-text028">
                    <span className="pricing-text029">✔</span>
                    <span>  You're ready to go</span>
                  </span>
                </div>
                <div className="pricing-separator2"></div>
                {/* <div className="pricing-container08">
                  <span className="pricing-text031">Custom</span>
                  <span className="pricing-text032">
                    tailored to your needs
                  </span>
                </div>
                <Link
                  to="/contact-us-client"
                  className="pricing-navlink08 button"
                >
                  <span className="pricing-text033">Contact Us</span>
                </Link> */}
              </div>
              <div className="pricing-pricing-card1">
                <div className="pricing-container09">
                  <div className="pricing-container10">
                    <span className="pricing-text034">
                      Your Search
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    {/* <span className="pricing-text035">Most Popular</span> */}
                  </div>
                  {/* <span className="pricing-tagline">C-level Headhunting</span> */}
                  <span className="pricing-text036">
                    <span>Lets start your search</span>
                    <br></br>
                  </span>
                </div>
                <div className="pricing-separator3"></div>
                <div className="pricing-container11">
                  <span className="pricing-text039">
                    <span className="pricing-text040">✔</span>
                    <span>  Create Job Spec</span>
                  </span>
                  <span className="pricing-text042">
                    <span className="pricing-text043">✔</span>
                    <span>  Access Talent Network</span>
                  </span>
                  <span className="pricing-text045">
                    <span className="pricing-text046">✔</span>
                    <span>  Exclusive Outreach</span>
                  </span>
                  <span className="pricing-text048">
                    <span className="pricing-text049">✔</span>
                    <span>  Fill Pipeline</span>
                  </span>
                  <span className="pricing-text051">
                    <span className="pricing-text052">✔</span>
                    <span>  Review Candidates</span>
                  </span>
                </div>
                <div className="pricing-separator4"></div>
              </div>
              <div className="pricing-pricing-card2">
                <div className="pricing-container13">
                  <span className="pricing-text057">Interviews</span>
                  {/* <span className="pricing-tagline">
                  Work alongside other Agencies
                </span> */}
                  <span className="pricing-text058">
                    <span>Start Interviewing your Candidates</span>
                    <br></br>
                  </span>
                </div>
                <div className="pricing-separator5"></div>
                <div className="pricing-container14">
                  <span className="pricing-text061">
                    <span className="pricing-text062">✔</span>
                    <span>  Create Interview Stages</span>
                  </span>
                  <span className="pricing-text064">
                    <span className="pricing-text065">✔</span>
                    <span>  Decide On Assignments</span>
                  </span>
                  <span className="pricing-text067">
                    <span className="pricing-text068">✔</span>
                    <span>  Review Shortlist</span>
                  </span>
                  <span className="pricing-text070">
                    <span className="pricing-text071">✔</span>
                    <span>  Start Interviewing</span>
                  </span>
                  <span className="pricing-text073">
                    <span className="pricing-text074">✔</span>
                    <span>  Select your hires</span>
                  </span>
                </div>
                <div className="pricing-separator6"></div>
              </div>
            </div>
            <Link
              to="/client-step-5meeting"
              className="build-your-team-navlink8"
            >
              <span className="build-your-team-text18">
                Book a Discovery Call
              </span>
            </Link>
            {/* <div className="pricing-container16">
              <span className="pricing-text079">
                <span>
                  © 
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <Link to="/build-your-team" className="pricing-navlink11">
                  Create an account
                </Link>
                <span>
                  {" "}
                  or
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <Link to="/contact-us" className="pricing-navlink12">
                  Contact Us
                </Link>
                <span> - No credit card required</span>
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

OurNextSteps.defaultProps = {
  Header3: "Upload your resume",
  image_src32: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt11: "image",
  image_alt: "image",
  image_src4: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  Header2: "01",
  Header1: "Register account",
  Description2:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
  image_src: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  Description1:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
  image_alt12: "image",
  image_src2: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  image_src1: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt22: "image",
  Header5: "Apply for job",
  image_alt32: "image",
  Header21: "02",
  image_alt31: "image",
  image_src21: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  Header: "01",
  image_src11: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt5: "image",
  Description: "Register to join DecentraLoco Talent Network",
  Header31: "Upload your resume",
  image_src5: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  image_alt21: "image",
  image_alt1: "image",
  image_src22: "2bcf07e9-3b20-4d0d-999c-93236d744837",
  image_src12: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_src3: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  image_alt4: "image",
  image_alt2: "image",
  Header4: "03",
  image_alt3: "image",
  image_src31: "eebc8e7c-093d-4a3c-b393-4a6f657197c6",
  Description11:
    "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium",
  text: "How to get started with",
  text1: "DecentraLoco",
};

OurNextSteps.propTypes = {
  Header3: PropTypes.string,
  image_src32: PropTypes.string,
  image_alt11: PropTypes.string,
  image_alt: PropTypes.string,
  image_src4: PropTypes.string,
  Header2: PropTypes.string,
  Header1: PropTypes.string,
  Description2: PropTypes.string,
  image_src: PropTypes.string,
  Description1: PropTypes.string,
  image_alt12: PropTypes.string,
  image_src2: PropTypes.string,
  image_src1: PropTypes.string,
  image_alt22: PropTypes.string,
  Header5: PropTypes.string,
  image_alt32: PropTypes.string,
  Header21: PropTypes.string,
  image_alt31: PropTypes.string,
  image_src21: PropTypes.string,
  Header: PropTypes.string,
  image_src11: PropTypes.string,
  image_alt5: PropTypes.string,
  Description: PropTypes.string,
  Header31: PropTypes.string,
  image_src5: PropTypes.string,
  image_alt21: PropTypes.string,
  image_alt1: PropTypes.string,
  image_src22: PropTypes.string,
  image_src12: PropTypes.string,
  image_src3: PropTypes.string,
  image_alt4: PropTypes.string,
  image_alt2: PropTypes.string,
  Header4: PropTypes.string,
  image_alt3: PropTypes.string,
  image_src31: PropTypes.string,
  Description11: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
};

export default OurNextSteps;
