import React from "react";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

import NavbarLogIn from "../../components/navbar-log-in";
import FooterV2 from "../../components/footer-v2";
import "./client-step-3.css";
import CandidateProtectedRoute from "../../utils/CandidateProtectedRoute";
import ClientProtectedSteps from "../../utils/ClientProtectedSteps";

const ClientStep3 = (props) => {
  return (
    <CandidateProtectedRoute>
      <ClientProtectedSteps>
        <div className="client-step3-container">
          <Helmet>
            <title>Client-step-3 - Decentraloco</title>
            <meta property="og:title" content="Client-step-3 - Decentraloco" />
          </Helmet>
          <NavbarLogIn rootClassName="navbar-log-in-root-class-name4"></NavbarLogIn>
          <div className="client-step3-container01">
            <div className="client-step3-container02">
              <span className="client-step3-text">Step 3 of 5</span>
              <div className="client-step3-container03">
                <div className="client-step3-container04"></div>
              </div>
            </div>
            <div className="client-step3-container05">
              <span className="client-step3-text01">Your hiring needs</span>
              <span className="client-step3-text02">
                Let’s start with the basics, tell us about who you are
              </span>
            </div>
            <div className="client-step3-container06">
              <span className="client-step3-text03">Your hiring needs ?</span>
              <div className="client-step3-container07">
                <button type="button" className="client-step3-button button">
                  <span className="client-step3-text04">Full time</span>
                </button>
                <button type="button" className="client-step3-button01 button">
                  <span className="client-step3-text05">Part time</span>
                </button>
                <button type="button" className="client-step3-button02 button">
                  <span className="client-step3-text06">Depends</span>
                </button>
              </div>
            </div>
            <div className="client-step3-container08">
              <span className="client-step3-text07">
                First, Tell us about your project ?
              </span>
              <div className="client-step3-container09">
                <button type="button" className="client-step3-button03 button">
                  <span className="client-step3-text08">Junior</span>
                </button>
                <button type="button" className="client-step3-button04 button">
                  <span className="client-step3-text09">Mid-senior</span>
                </button>
                <button type="button" className="client-step3-button05 button">
                  <span className="client-step3-text10">Senior</span>
                </button>
              </div>
            </div>
            <div className="client-step3-container10">
              <span className="client-step3-text11">
                Are you available for Part-time ?
              </span>
              <div className="client-step3-container11">
                <button type="button" className="client-step3-button06 button">
                  <span className="client-step3-text12">Yes</span>
                </button>
                <button type="button" className="client-step3-button07 button">
                  <span className="client-step3-text13">No</span>
                </button>
                <button type="button" className="client-step3-button08 button">
                  <span className="client-step3-text14">Depends</span>
                </button>
              </div>
            </div>
          </div>
          <div className="client-step3-container12">
            <Link
              type="reset"
              disabled
              className="client-step3-button09 button"
              to="/client-step-4"
            >
              Skip this step
            </Link>
            <Link to="/client-step-4" className="client-step3-navlink button">
              <span className="client-step3-text15">Next</span>
            </Link>
          </div>
          <FooterV2 rootClassName="footer-v2-root-class-name3"></FooterV2>
        </div>
      </ClientProtectedSteps>
    </CandidateProtectedRoute>
  );
};

export default ClientStep3;
