import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo-text.png";
import "../css/login.css";
import Spinner from "../../components/Spinner";

import { useUserState } from "../../utils/stateContext";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../database/firebase";

const LoginDashboard = ({ history }) => {
  const [errorCredentials, setErrorCredentials] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userLoaded, setUserLoaded] = useState(false);
  // const auth = getAuth();

  const { login, user, setUser } = useUserState();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm({});
  const navigate = useNavigate();
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    if (user && userLoaded && user?.isAdmin) {
      console.log("userLoadedAdmin", user.isAdmin);
      navigate("/decentradmin/dashboard");
    }
  }, [user, userLoaded]);
  useEffect(() => {
    user?.isAdmin && navigate("/decentradmin/dashboard");
  }, [user]);

  const loginUser = async ({ email, password }) => {
    setIsLoading(true);
    setErrorCredentials(false);
    await login(email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const userRef = doc(db, "users", user.uid);
        const queryAdmins = await getDocs(collection(db, "admins"));
        const admins = queryAdmins.docs
          .map((doc) => doc.data())
          .map((u) => u.email);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          setUser(docSnap.data());
          setUser((prevState) => ({
            ...prevState,
            userUID: user.uid,
            isAdmin: admins?.includes(user?.email),
          }));
        }
        setUserLoaded(true);
      })
      .then(() => {
        setSaved(true);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setErrorCredentials(true);
        setIsLoading(false);
      });
  };

  return (
    <div style={{ minHeight: window.innerHeight }}>
      <div className="authincation p-meddle">
        <div className="container">
          <div className="row justify-content-center h-100 align-items-center">
            <div className="col-md-6">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form">
                      <div className="text-center mb-4">
                        <Link to="/">
                          <img
                            src={logo}
                            style={{ width: "50%", margin: "0 auto" }}
                          />
                        </Link>
                      </div>
                      <h4 className="text-center mt-4 mb-4 text-white">
                        Sign in your account
                      </h4>
                      <form>
                        <div className="form-group">
                          <label className="mb-1 text-white">Email</label>
                          <input
                            {...register("email", {
                              required: true,
                              pattern: /^\S+@\S+$/i,
                            })}
                            type="email"
                            className="form-control"
                            placeholder="hello@example.com"
                          />
                          {errors.email && (
                            <p className="error-text">
                              *Required, must be a valid email
                            </p>
                          )}
                        </div>
                        <div className="form-group mb-4">
                          <label className="mb-1 text-white">Password</label>
                          <input
                            {...register("password", { required: true })}
                            type="password"
                            className="form-control"
                            placeholder="Password"
                          />
                          {errors.password && (
                            <p className="error-text">*Required</p>
                          )}
                        </div>

                        <div className="form-row d-flex justify-content-between mb-2">
                          {/* <div className="form-group">
                            <div className="custom-control custom-checkbox ml-1">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="basic_checkbox_1"
                              />
                              <label
                                className="custom-control-label text-white"
                                htmlFor="basic_checkbox_1"
                              >
                                Remember my preference
                              </label>
                            </div>
                          </div>
                          <div className="form-group">
                            <Link
                              className="text-white"
                              to="/page-forgot-password"
                            >
                              Forgot Password?
                            </Link>
                          </div> */}
                        </div>
                        {errorCredentials && (
                          <p className="error-text">
                            User credentials are invalid
                          </p>
                        )}
                        <div className="text-center mt-4">
                          <button
                            disabled={isLoading}
                            aria-disabled={isLoading}
                            onClick={handleSubmit(loginUser)}
                            className="btn bg-white text-primary btn-block mb-4"
                            style={{ position: isLoading ? "relative" : "" }}
                          >
                            {isLoading ? (
                              <span
                                style={{
                                  position: "absolute",
                                  top: 0 + "px",
                                  left: "43%",
                                }}
                              >
                                <Spinner></Spinner>
                              </span>
                            ) : (
                              "Log In "
                            )}
                          </button>
                        </div>
                      </form>
                      {/* <div className="new-account mt-4">
                        <p className="text-white text-center">
                          Don't have an account?
                          <Link className="text-white mx-4" to="/dashboard/register">
                            Sign up
                          </Link>
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginDashboard;
