import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import DangerousHTML from "dangerous-html/react";
import { Helmet } from "react-helmet";

import Footer from "../../components/footer";
import "./contact-us-client.css";
import { useUserState } from "../../utils/stateContext";
import { signOut } from "firebase/auth";
import { auth } from "../../database/firebase";
import SupportForm from "../../components/SupportForm";
import OurValues from "../../components/OurValues";

const ContactUsClient = (props) => {
  const navigate = useNavigate();
  const { user, setUser } = useUserState();
  const [show, setShow] = useState(false);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
        setShow(false);
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="contact-us-client-container">
      <Helmet>
        <title>Values Decentraloco</title>
        <meta property="og:title" content="Values Decentraloco" />
      </Helmet>
      <nav className="contact-us-client-navbar">
        <header
          data-thq="thq-navbar"
          className="contact-us-client-navbar-interactive"
        >
          <Link to="/" className="contact-us-client-navlink">
            <img
              alt="pastedImage"
              src="/playground_assets/logo-200h.png"
              className="contact-us-client-finbest"
            />
          </Link>
          <div
            data-thq="thq-navbar-nav"
            data-role="Nav"
            className="contact-us-client-desktop-menu"
          >
            <nav
              data-thq="thq-navbar-nav-links"
              data-role="Nav"
              className="contact-us-client-nav"
            >
              <Link
                to="/build-your-team"
                className="contact-us-client-navlink1 button"
              >
                Client&apos;s Home
              </Link>
              <Link
                to="/contact-us-client"
                className="contact-us-client-navlink2 button"
              >
                Values
              </Link>
              <Link
                to="/our-process"
                className="contact-us-client-navlink4 button"
              >
                Our Process
              </Link>
              <Link to="/pricing" className="contact-us-client-navlink3 button">
                Pricing
              </Link>
              <Link
                to="/next-steps"
                className="build-your-team-navlink3 button"
              >
                Next Steps
              </Link>
            </nav>
          </div>
          <div
            data-thq="thq-navbar-btn-group"
            className="contact-us-client-btn-group"
          >
            <Link
              to="/build-your-team"
              className="contact-us-client-login button"
            >
              Hire Talent
            </Link>
            {user ? (
              <Link
                className="build-your-team-register button"
                onClick={() => setShow(!show)}
              >
                {`${user.firstName} ${user.lastName}`}
              </Link>
            ) : (
              <Link to="/log-in" className="build-your-team-register button">
                Log In
              </Link>
            )}
            {show && (
              <div
                onClick={() => handleLogout()}
                style={{
                  backgroundColor: "#1E1C24",
                  borderRadius: "8px",
                  color: "#fff",
                  cursor: "pointer",
                  fontFamily: "Space Grotesk",
                  marginTop: "1px",
                  padding: "15px 10px",
                  position: "absolute",
                  right: 0,
                  textAlign: "center",
                  top: "48px",
                  width: "125px",
                }}
              >
                <span>Log out</span>
              </div>
            )}
          </div>
          <div
            data-thq="thq-burger-menu"
            className="contact-us-client-burger-menu"
          >
            <svg viewBox="0 0 1024 1024" className="contact-us-client-icon">
              <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
            </svg>
          </div>
          <div
            data-thq="thq-mobile-menu"
            className="contact-us-client-mobile-menu"
          >
            <div
              data-thq="thq-mobile-menu-nav"
              data-role="Nav"
              className="contact-us-client-nav1"
            >
              <div className="contact-us-client-container01">
                <Link to="/" className="contact-us-client-navlink4">
                  <img
                    alt="pastedImage"
                    src="/playground_assets/logo-200h.png"
                    className="contact-us-client-finbest1"
                  />
                </Link>
                <div
                  data-thq="thq-close-menu"
                  className="contact-us-client-menu-close"
                >
                  <svg
                    viewBox="0 0 1024 1024"
                    className="contact-us-client-icon02"
                  >
                    <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                  </svg>
                </div>
              </div>
              <nav
                data-thq="thq-mobile-menu-nav-links"
                data-role="Nav"
                className="contact-us-client-nav2"
              >
                <Link
                  to="/build-your-team"
                  className="contact-us-client-navlink5"
                >
                  Client&apos;s Home
                </Link>
                <Link
                  to="/contact-us-client"
                  className="contact-us-client-navlink6"
                >
                  Values
                </Link>
                <Link to="/our-process" className="build-your-team-navlink6">
                  Our Process
                </Link>
                <Link to="/pricing" className="contact-us-client-navlink7">
                  Pricing
                </Link>
                <Link
                  to="/next-steps"
                  className="build-your-team-navlink3 button"
                >
                  Next Steps
                </Link>
              </nav>
              <div className="contact-us-client-container02">
                {user ? (
                  <Link
                    className="home-register button"
                    onClick={() => setShow(!show)}
                  >
                    {`${user.firstName} ${user.lastName}`}
                  </Link>
                ) : (
                  <Link to="/log-in" className="home-register button">
                    Log In
                  </Link>
                )}
                {show && (
                  <div
                    onClick={() => handleLogout()}
                    style={{
                      backgroundColor: "#1E1C24",
                      borderRadius: "8px",
                      color: "#fff",
                      cursor: "pointer",
                      fontFamily: "Space Grotesk",
                      marginTop: "1px",
                      padding: "15px 10px",
                      position: "absolute",
                      right: 0,
                      textAlign: "center",
                      top: "48px",
                      width: "125px",
                    }}
                  >
                    <span>Log out</span>
                  </div>
                )}
                <Link
                  to="/build-your-team"
                  className="contact-us-client-login1 button"
                >
                  Hire Talent
                </Link>
              </div>
            </div>
          </div>
        </header>
      </nav>
      <OurValues />
      {/* <div className="contact-us-client-hero">
        <div className="contact-us-client-container03">
          <h2 className="contact-us-client-text06">
            <span>
              Contact
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className="contact-us-client-text08">Us</span>
          </h2>
          <div className="contact-us-client-container04">
            <div className="contact-us-client-container05">
              <svg viewBox="0 0 1024 1024" className="contact-us-client-icon06">
                <path d="M938.667 426.667c0-117.803-47.787-224.555-124.971-301.696s-183.893-124.971-301.696-124.971-224.555 47.787-301.696 124.971-124.971 183.893-124.971 301.696c0 24.277 2.261 48.128 6.4 71.509 11.691 66.048 38.357 128.171 71.765 184.32 116.565 195.883 324.821 334.336 324.821 334.336 14.123 9.259 32.64 9.771 47.317 0 0 0 208.299-138.453 324.821-334.336 33.408-56.149 60.075-118.272 71.765-184.32 4.181-23.381 6.443-47.232 6.443-71.509zM853.333 426.667c0 18.944-1.749 37.845-5.077 56.661-9.429 53.333-31.445 105.728-61.099 155.563-83.285 139.947-220.416 249.643-275.157 290.091-54.784-40.448-191.872-150.144-275.157-290.091-29.653-49.835-51.669-102.229-61.099-155.563-3.328-18.816-5.077-37.717-5.077-56.661 0-94.251 38.144-179.541 99.968-241.365s147.115-99.968 241.365-99.968 179.541 38.144 241.365 99.968 99.968 147.115 99.968 241.365zM682.667 426.667c0-47.104-19.157-89.856-50.005-120.661s-73.557-50.005-120.661-50.005-89.856 19.157-120.661 50.005-50.005 73.557-50.005 120.661 19.157 89.856 50.005 120.661 73.557 50.005 120.661 50.005 89.856-19.157 120.661-50.005 50.005-73.557 50.005-120.661zM597.333 426.667c0 23.595-9.515 44.843-25.003 60.331s-36.736 25.003-60.331 25.003-44.843-9.515-60.331-25.003-25.003-36.736-25.003-60.331 9.515-44.843 25.003-60.331 36.736-25.003 60.331-25.003 44.843 9.515 60.331 25.003 25.003 36.736 25.003 60.331z"></path>
              </svg>
            </div>
            <div className="contact-us-client-container06">
              <span className="contact-us-client-text09">
                <span>
                  Visite
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="contact-us-client-text11">Us</span>
              </span>
              <span className="contact-us-client-text12">
                Visit our UK office
              </span>
              <span className="contact-us-client-text13">
                7 - 9 North Parade Buildings, Bath BA1 1NS
              </span>
            </div>
          </div>
          <div className="contact-us-client-container07">
            <div className="contact-us-client-container08">
              <svg viewBox="0 0 1024 1024" className="contact-us-client-icon08">
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
            </div>
            <div className="contact-us-client-container09">
              <span className="contact-us-client-text14">
                <span>
                  Contact
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className="contact-us-client-text16">Us</span>
              </span>
              <span className="contact-us-client-text17">
                Chat to our friendly team
              </span>
              <span className="contact-us-client-text18">
                info@decentraloco.xyz
              </span>
            </div>
          </div>
          <div className="contact-us-client-icon-group">
            <a
              href="https://www.linkedin.com/company/decentraloco/"
              target="_blank"
              rel="noreferrer noopener"
              className="contact-us-client-link"
            >
              <div className="contact-us-client-container10">
                <svg
                  viewBox="0 0 1024 1024"
                  className="contact-us-client-icon10"
                >
                  <path d="M384 384h177.106v90.782h2.532c24.64-44.194 84.958-90.782 174.842-90.782 186.946 0 221.52 116.376 221.52 267.734v308.266h-184.61v-273.278c0-65.184-1.334-149.026-96.028-149.026-96.148 0-110.82 70.986-110.82 144.292v278.012h-184.542v-576z"></path>
                  <path d="M64 384h192v576h-192v-576z"></path>
                  <path d="M256 224c0 53.019-42.981 96-96 96s-96-42.981-96-96c0-53.019 42.981-96 96-96s96 42.981 96 96z"></path>
                </svg>
              </div>
            </a>
            <a
              href="https://discord.gg/kchwUPtuut"
              target="_blank"
              rel="noreferrer noopener"
              className="contact-us-client-link1"
            >
              <div className="contact-us-client-container11">
                <img
                  alt="image"
                  src="/playground_assets/discord-2-48-200h.png"
                  className="contact-us-client-image1"
                />
              </div>
            </a>
            <a
              href="https://twitter.com/_decentraloco"
              target="_blank"
              rel="noreferrer noopener"
              className="contact-us-client-link2"
            >
              <div className="contact-us-client-container12">
                <svg
                  viewBox="0 0 950.8571428571428 1024"
                  className="contact-us-client-icon14"
                >
                  <path d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"></path>
                </svg>
              </div>
            </a>
            <a
              href="https://t.me/+axIEQIRtOhE1YWY0"
              target="_blank"
              rel="noreferrer noopener"
              className="contact-us-client-link3"
            >
              <div className="contact-us-client-container13">
                <svg
                  viewBox="0 0 1024 1024"
                  className="contact-us-client-icon16"
                >
                  <path d="M512 0c-282.8 0-512 229.2-512 512s229.2 512 512 512 512-229.2 512-512-229.2-512-512-512zM763.6 351l-84 395.8c-5.8 28.2-22.8 34.8-46.4 21.8l-128-94.6-61.4 59.8c-7.2 7-12.8 12.8-25.6 12.8-16.6 0-13.8-6.2-19.4-22l-43.6-143.2-126.6-39.4c-27.4-8.4-27.6-27.2 6.2-40.6l493.2-190.4c22.4-10.2 44.2 5.4 35.6 40z"></path>
                </svg>
              </div>
            </a>
          </div>
        </div>
        <img
          src="/playground_assets/art-work%2005-1500h.png"
          loading="lazy"
          className="contact-us-client-image2"
        />
      </div> */}
      <div className="contact-us-client-separator"></div>
      <SupportForm />
      <Footer></Footer>
    </div>
  );
};

export default ContactUsClient;
