import { Link } from "react-router-dom";

const JobCardComponent = ({
  job,
  openPreview,
  applied,
  signedIn,
  applyToJob,
}) => {
  return (
    <div className="jobs-container05" onClick={() => openPreview(job)}>
      <div className="jobs-container06">
        <div className="jobs-container07">
          <img
            alt="image"
            src={
              job?.companyLogo
                ? job.companyLogo
                : "https://play.teleporthq.io/static/svg/default-img.svg"
            }
            className="jobs-image01"
          />
          <span className="jobs-text24">{job?.jobTitle}</span>
        </div>

        {/* <svg viewBox="0 0 1024 1024" className="jobs-icon06">
                <path d="M726 768v-554h-428v554l214-94zM726 128q34 0 59 26t25 60v682l-298-128-298 128v-682q0-34 25-60t59-26h428z"></path>
            </svg> */}

        {applied ? (
          <button disabled className="jobs-navlink08">
            <div className="jobs-sign-up-btn button disabled">
              <span className="jobs-sign-up">
                Your application has been received
              </span>
            </div>
          </button>
        ) : (
          <button
            onClick={(ev) => {
              ev.stopPropagation();
              applyToJob(job);
            }}
            className="jobs-navlink08"
          >
            <div className="jobs-sign-up-btn button">
              <span className="jobs-sign-up">Apply now</span>
            </div>
          </button>
        )}
      </div>
      <div className="jobs-container08">
        {job?.jobType && (
          <button className="jobs-button button">{job.jobType}</button>
        )}
        {job?.workplaceType && (
          <button className="jobs-button01 button">{job.workplaceType}</button>
        )}
        {job?.salaryRange && (
          <button className="jobs-button02 button">{job.salaryRange}</button>
        )}
      </div>
      {job?.shortDescription && (
        <span className="jobs-text25">{job.shortDescription}</span>
      )}
    </div>
  );
};

export default JobCardComponent;
